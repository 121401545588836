// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useContext, useState } from 'react';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  DrawerCloseButton,
  Button,
  Image,
  Flex,
  Text,
  useBreakpointValue,
  SlideDirection,
  InputLeftElement,
  InputGroup,
  Input,
} from '@chakra-ui/react';
import { useMutation, useQuery } from 'react-query';
import { SearchIcon } from '@chakra-ui/icons';
import { AssetProps } from 'contentful-management/dist/typings/export-types';
import { AccountsStateContext } from 'context/Accounts/AccountsContext';
import {
  AssetResponse,
  CategoryMgmtEntry,
  GeneralMgmtEntry,
  MenuItemMgmtEntry,
  MenuMgmtEntry,
  RequestErrorType,
  UpdateEntryOptions,
} from 'services/api/types';
import { AssetsAPI, EntriesAPI } from 'services/api';
import { defaultLocale } from 'shared/functions';
import { Link } from 'contentful-management/dist/typings/common-types';
import { StatusLabel } from 'shared/components';
import { useInfiniteScrollData } from 'hooks/useInfiniteScrollData';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  updateKey: string;
  entry: CategoryMgmtEntry | MenuItemMgmtEntry | MenuMgmtEntry | GeneralMgmtEntry;
  onSuccess: () => void;
};

export const AddAssetModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  updateKey,
  entry,
  onSuccess,
}) => {
  const { state: accountsState } = useContext(AccountsStateContext);
  const { selectedAccount } = accountsState;
  const [selected, setSelected] = useState<AssetProps>();
  const [search, setSearch] = useState<string>('');
  const drawerSize = useBreakpointValue({ base: 'sm', md: 'lg' });
  const drawerPlacement: SlideDirection | undefined = useBreakpointValue({
    base: 'bottom',
    md: 'right',
  });

  const { data } = useQuery(
    `media-${selectedAccount ? selectedAccount.account_name : ''}`,
    () =>
      AssetsAPI.filterAssets({
        title: selectedAccount?.id || '',
      }),
    {
      enabled: !!selectedAccount,
      staleTime: 5 * 60 * 1000,
    },
  );

  const { items } = data || { items: [] };
  const filteredItemsBySearch = (items || []).filter((item) => {
    const lowerCaseSearch = search.toLowerCase();
    const itemName = item.fields.title[defaultLocale].replace(`${selectedAccount?.id}_`, '');
    return itemName.toLowerCase().includes(lowerCaseSearch);
  });
  const { ref: footerRef, data: dataByPage } = useInfiniteScrollData(filteredItemsBySearch);

  const { mutate: mutateUpdateEntry, isLoading: isUpdating } = useMutation(
    (options: UpdateEntryOptions) => EntriesAPI.updateEntry(entry.sys.id, options),
    {
      onSuccess: (res) => {
        console.log(`🚀 => mutateInsertEntries:res`, res);
        onSuccess();
      },
      onError: (error: RequestErrorType) => {
        console.log(`🚀 => error`, error);
      },
    },
  );

  const onClickAsset = (asset: AssetResponse) => {
    setSelected(asset);
  };

  const onClickInsertAssets = () => {
    if (!selected) return;

    const updatedEntry = { ...entry };

    const selectedAssetAsLink: Link<'Asset'> = {
      sys: {
        type: 'Link',
        linkType: 'Asset',
        id: selected.sys.id,
      },
    };

    if (updatedEntry) {
      // add as new
      updatedEntry.fields[updateKey] = {
        [defaultLocale]: selectedAssetAsLink,
      };
      console.log(`🚀 => onClickInsertAssets => updatedEntry`, updatedEntry);
      mutateUpdateEntry(updatedEntry.fields);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearch(value);
  };

  return (
    <Drawer
      isFullHeight={true}
      placement={drawerPlacement}
      isOpen={isOpen}
      onClose={onClose}
      size={drawerSize}
    >
      <DrawerOverlay />
      <DrawerContent zIndex="overlay">
        <DrawerHeader
          fontSize="xl"
          fontWeight="bold"
          borderBottom="1px solid"
          borderColor="gray.300"
        >
          Add Images
        </DrawerHeader>
        <DrawerCloseButton />

        <Flex paddingY={8} paddingX={8}>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <SearchIcon color="gray.300" />
            </InputLeftElement>
            <Input
              backgroundColor="gray.50"
              type="text"
              placeholder="Search items"
              onChange={onChange}
              value={search}
            />
          </InputGroup>
        </Flex>

        <DrawerBody overflowY="auto" paddingBottom="8">
          <Flex flex={1} justifyContent="flex-start" flexWrap="wrap">
            {(dataByPage as AssetProps[]).map((asset) => {
              const isSelected = selected && selected.sys.id === asset.sys.id;
              return (
                <Flex
                  onClick={() => onClickAsset(asset)}
                  key={asset.sys.id}
                  flexDirection="column"
                  marginBottom="8"
                  marginRight={{ base: 0, sm: '8' }}
                  border="3px solid"
                  _hover={{
                    cursor: 'pointer',
                    borderColor: 'blue.200',
                  }}
                  borderColor={isSelected ? 'blue.200' : 'transparent'}
                  borderRadius="md"
                  backgroundColor={isSelected ? 'gray.50' : 'white'}
                  boxShadow="lg"
                  width={{ base: '100%', sm: '240px' }}
                >
                  <Image
                    src={asset.fields.file[defaultLocale].url}
                    borderRadius="sm"
                    width={{ base: '100%', sm: '240px' }}
                    height="100%"
                    objectFit="cover"
                  />
                  <Flex
                    background="white"
                    padding="4"
                    borderRadius="md"
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Text
                      marginRight="4"
                      maxWidth="100px"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                      overflow="hidden"
                      flex={1}
                    >
                      {asset.fields.title[defaultLocale].replace(`${selectedAccount?.id}_`, '')}
                    </Text>
                    <StatusLabel sys={asset.sys} />
                  </Flex>
                </Flex>
              );
            })}
            <Flex height={10} width={10} marginTop="8" ref={footerRef} />
          </Flex>
        </DrawerBody>

        <DrawerFooter borderTop="1px solid" borderColor="gray.300">
          <Button variant="ghost" marginRight="4" onClick={onClose}>
            Close
          </Button>
          <Button
            isLoading={isUpdating}
            onClick={onClickInsertAssets}
            variant="add"
            isDisabled={items.length === 0 || selected === 'undefined'}
          >
            {items.length === 0 || selected === 'undefined' ? 'Select Image' : `Insert Image`}
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
