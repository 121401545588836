import React, { useReducer } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Flex, Heading, Text, Button } from '@chakra-ui/react';
import { AuthBackground, FormInput, Logo } from 'shared/components';
import { Validations } from 'shared/functions';
import { AccountsAPI, UserAPI } from 'services/api';
import { CreateAccountOptions, UpdateUserOptions } from 'services/api/types';
import { RequestError } from 'services/api/helpers';
import { FirebaseAuth } from 'services/firebase';
import { useAuthStateContext } from 'context/Auth/AuthStateContext';
import { useAccountsStateContext } from 'context/Accounts/AccountsContext';

type FormState = {
  firstName: string;
  lastName: string;
  accountName: string;
  loading: boolean;
  errors: FormValidationError;
};

type FormValidationError = {
  firstName?: string;
  lastName?: string;
  accountName?: string;
  general?: string;
};

type FORM_ACTIONS = { type: 'update'; payload: Partial<FormState> };

const initialState: FormState = {
  firstName: '',
  lastName: '',
  accountName: '',
  loading: false,
  errors: {},
};

const reducer = (state: FormState, action: FORM_ACTIONS) => {
  switch (action.type) {
    case 'update': {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};

export const UserDetails: React.FC = () => {
  const queryClient = useQueryClient();
  const { state: authState, dispatch: authDispatch } = useAuthStateContext();
  const { dispatch: accountsDispatch } = useAccountsStateContext();
  const { user } = authState;
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    ...{ firstName: user?.firstName || '', lastName: user?.lastName || '' },
  });

  const { firstName, lastName, accountName, loading, errors } = state;

  const { mutateAsync: updateUser } = useMutation(
    (options: UpdateUserOptions) => UserAPI.updateUser(options),
    {
      onSuccess: () => {
        queryClient.refetchQueries('user');
      },
      onError: (err: Error) => {
        console.log(`🚀 => err`, err);
      },
    },
  );

  const { mutateAsync: mutateCreateAccount, isLoading: createAccountLoading } = useMutation(
    (createOptions: CreateAccountOptions) => AccountsAPI.createAccount(createOptions),
    {
      onSuccess: () => {
        queryClient.refetchQueries('teams');
      },
    },
  );

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    dispatch({
      type: 'update',
      payload: {
        [name]: value,
        errors: {
          ...state.errors,
          [name]: undefined,
        },
      },
    });
  };

  const onClickContinue = async () => {
    console.log('state :>> ', state);

    dispatch({
      type: 'update',
      payload: {
        loading: true,
        errors: {},
      },
    });

    const required = { firstName, lastName, accountName };
    const newErrors = Validations.validateForm(required);
    if (Object.keys(newErrors).length > 0) {
      dispatch({
        type: 'update',
        payload: {
          loading: false,
          errors: newErrors,
        },
      });

      return;
    }

    try {
      if (!user || !user.firstName || !user.lastName) {
        await updateUser({ first_name: firstName, last_name: lastName });
      }

      // Create account with account name
      await mutateCreateAccount({ account_name: accountName });
    } catch (error) {
      if (error instanceof RequestError) {
        dispatch({
          type: 'update',
          payload: {
            loading: false,
            errors: {
              general: error.message,
            },
          },
        });
      } else {
        console.log(error);
      }
    }
  };

  return (
    <AuthBackground>
      <Flex
        maxWidth="500px"
        flexDirection="column"
        flex="1"
        justifyContent="flex-start"
        borderWidth="thin"
        borderRadius="10px"
        padding={['6', '8']}
        overflowY="auto"
        backgroundColor="white"
        margin={[0, '4']}
      >
        <Flex marginBottom="8">
          <Logo />
        </Flex>
        <Text letterSpacing="tighter" fontWeight="thin" fontSize="sm">
          ACCOUNT DETAILS
        </Text>
        <Heading fontSize="3xl" fontWeight="black" mb="4">
          Enter your details
        </Heading>
        <FormInput
          label="First Name"
          name="firstName"
          value={firstName}
          onChange={onChange}
          error={errors.firstName}
          placeholder="Enter First Name"
          disabled={!!user?.firstName}
        />
        <FormInput
          label="Last Name"
          name="lastName"
          value={lastName}
          onChange={onChange}
          error={errors.lastName}
          placeholder="Enter Last Name"
          disabled={!!user?.lastName}
        />

        <FormInput
          label="Account Name"
          name="accountName"
          value={accountName}
          onChange={onChange}
          error={errors.accountName}
          placeholder="Enter Account Name"
        />

        {errors.general && (
          <Text textAlign="center" color="red.500" fontWeight="semibold">
            {errors.general}
          </Text>
        )}

        <Button
          onClick={onClickContinue}
          isDisabled={loading || createAccountLoading}
          isLoading={loading || createAccountLoading}
          marginTop="8"
          width="100%"
          variant="primary"
        >
          Continue
        </Button>

        <Flex flexDirection="column" marginTop="auto" alignSelf="center">
          <Text fontWeight="semibold" textAlign="center">{`Logged in as: ${user?.email}`}</Text>
          <Button
            variant="link"
            alignSelf="center"
            onClick={async () => {
              await FirebaseAuth.signOut(authDispatch);
              accountsDispatch({ type: 'RESET_STATE' });
              queryClient.clear();
            }}
          >
            Logout
          </Button>
        </Flex>
      </Flex>
    </AuthBackground>
  );
};
