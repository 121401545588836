import { get, post } from './helpers';
import queryString from 'querystring';
import { AssetFilters, AssetsResponse, AssetResponse, CreateAssetOptions } from './types';

export const filterAssets = async (queryObj: AssetFilters): Promise<AssetsResponse> => {
  const query = queryString.stringify(queryObj);
  const response = await get<AssetsResponse>(`/assets?${query}`);

  return response;
};

export const getSingleAsset = async (assetId: string): Promise<AssetResponse> => {
  const response = await get<AssetResponse>(`/assets/${assetId}/single}`);

  return response;
};

export const createAsset = async (options: CreateAssetOptions): Promise<AssetResponse> => {
  const response = await post<AssetResponse>(`/assets`, options);

  return response;
};

export const processAsset = async (assetId: string): Promise<AssetResponse> => {
  const response = await post<AssetResponse>(`/assets/${assetId}/process`, undefined);

  return response;
};

export const publishAsset = async (assetId: string): Promise<AssetResponse> => {
  const response = await post<AssetResponse>(`/assets/${assetId}/publish`, undefined);

  return response;
};

export const unpublishAsset = async (assetId: string): Promise<AssetResponse> => {
  const response = await post<AssetResponse>(`/assets/${assetId}/unpublish`, undefined);

  return response;
};

export const archiveAsset = async (assetId: string): Promise<AssetResponse> => {
  const response = await post<AssetResponse>(`/assets/${assetId}/archive`, undefined);

  return response;
};

export const unarchiveAsset = async (assetId: string): Promise<AssetResponse> => {
  const response = await post<AssetResponse>(`/assets/${assetId}/unarchive`, undefined);

  return response;
};

export const deleteAsset = async (assetId: string): Promise<AssetResponse> => {
  const response = await post<AssetResponse>(`/assets/${assetId}/delete`, undefined);

  return response;
};
