import React from 'react';
import { Flex } from '@chakra-ui/react';
import { LoadingIndicator, Logo } from 'shared/components';

export const PageLoading: React.FC = () => {
  return (
    <Flex
      flex="1"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      css={{
        ['> svg']: {
          marginBottom: '32px',
        },
      }}
    >
      <Logo height={100} width={247} />
      <LoadingIndicator />
    </Flex>
  );
};
