import React, { useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import { Flex, Heading, Link, Text, Button, Divider, Center } from '@chakra-ui/react';
import {
  AuthBackground,
  ContinueWithGoogleBtn,
  FormInput,
  Logo,
  LoadingIndicator,
  PasswordValidations,
} from 'shared/components';
import { Validations } from 'shared/functions';
import { FirebaseAuth, firebase } from 'services/firebase';

type FormState = {
  email: string;
  password: string;
  confirmPassword: string;
  loading: boolean;
  errors: FormValidationError;
};

type FormValidationError = {
  email?: string;
  password?: string;
  confirmPassword?: string;
  general?: string;
};

type FORM_ACTIONS =
  | { type: 'update'; payload: Partial<FormState> }
  | { type: 'loading'; payload: boolean };

const initialState: FormState = {
  email: '',
  password: '',
  confirmPassword: '',
  loading: false,
  errors: {},
};

const reducer = (state: FormState, action: FORM_ACTIONS) => {
  switch (action.type) {
    case 'update': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'loading': {
      return {
        ...state,
        loading: action.payload,
        ...(action.payload === true && {
          errors: {},
        }),
      };
    }

    default:
      return state;
  }
};

export const CreateAccount: React.FC = () => {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    dispatch({
      type: 'update',
      payload: {
        [name]: value,
        errors: {
          ...state.errors,
          [name]: undefined,
        },
      },
    });
  };

  const onClickLogin = () => {
    history.push('/login');
  };

  const onClickCreateAccount = async () => {
    console.log('state :>> ', state);

    dispatch({
      type: 'loading',
      payload: true,
    });

    const required = { email, password, confirmPassword };
    const newErrors = Validations.validateForm(required);
    if (Object.keys(newErrors).length > 0) {
      dispatch({
        type: 'update',
        payload: {
          loading: false,
          errors: newErrors,
        },
      });

      return;
    }

    const passwordMatch = state.password === state.confirmPassword;
    if (!passwordMatch) {
      dispatch({
        type: 'update',
        payload: {
          loading: false,
          errors: { confirmPassword: 'Passwords do not match' },
        },
      });

      return;
    }

    try {
      await FirebaseAuth.createFirebaseUserWithEmail({ email, password });
      dispatch({
        type: 'loading',
        payload: false,
      });
      // Navigation/Redirect happens from AuthLayout
    } catch (error) {
      const authError = error as firebase.auth.Error;
      dispatch({
        type: 'update',
        payload: {
          loading: false,
          errors: {
            general: authError.message,
          },
        },
      });
    }
  };

  const onClickContinueWithGoogle = async () => {
    try {
      dispatch({
        type: 'loading',
        payload: true,
      });
      await FirebaseAuth.signInWithGoogle();
    } catch (error) {
      const authError = error as firebase.auth.Error;
      if (authError.code === 'auth/popup-closed-by-user') {
        dispatch({
          type: 'loading',
          payload: false,
        });
        return;
      }
      dispatch({
        type: 'update',
        payload: {
          loading: false,
          errors: {
            general: authError.message,
          },
        },
      });
    }
  };

  const { email, password, confirmPassword, loading, errors } = state;

  return (
    <AuthBackground>
      <Flex
        maxWidth="500px"
        flexDirection="column"
        flex="1"
        justifyContent="flex-start"
        borderRadius="10px"
        padding={['6', '8']}
        overflowY="auto"
        backgroundColor="white"
        margin={[0, '4']}
        position="relative"
      >
        {loading && (
          <Center
            position="absolute"
            height="100%"
            width="100%"
            top="0"
            left="0"
            backgroundColor="blackAlpha.400"
            zIndex="overlay"
            borderRadius="10px"
          >
            <LoadingIndicator />
          </Center>
        )}

        <Flex marginBottom="8">
          <Logo />
        </Flex>
        <Text letterSpacing="tighter" fontWeight="thin" fontSize="sm">
          CREATE ACCOUNT
        </Text>
        <Heading fontSize="3xl" fontWeight="black" mb="4">
          Get Started
        </Heading>

        <ContinueWithGoogleBtn onClick={onClickContinueWithGoogle} />
        <Flex marginY="4" alignItems="center" justifyContent="center">
          <Divider orientation="horizontal" maxWidth="160px" />
          <Text marginX="4">or</Text>
          <Divider orientation="horizontal" maxWidth="160px" />
        </Flex>

        <FormInput
          label="Email Address"
          name="email"
          value={email}
          onChange={onChange}
          error={errors.email}
          placeholder="Enter your email address"
          type="email"
        />
        <FormInput
          label="Password"
          name="password"
          value={password}
          onChange={onChange}
          error={errors.password}
          placeholder="Enter Password"
          type="password"
        />

        <PasswordValidations password={password} />
        <FormInput
          label="Confirm Password"
          name="confirmPassword"
          value={confirmPassword}
          onChange={onChange}
          error={errors.confirmPassword}
          placeholder="Confirm Password"
          type="password"
        />

        {errors.general && (
          <Text textAlign="center" color="red.500" fontWeight="semibold">
            {errors.general}
          </Text>
        )}

        <Text
          alignSelf="center"
          maxWidth="300px"
          textAlign="center"
          color="gray.500"
          fontWeight="light"
          fontSize="xs"
        >
          By creating an account you confirm that you accept the{' '}
          <Link
            target="_blank"
            textDecoration="underline"
            href="https://visual.menu/terms-of-service"
          >
            Terms of Service
          </Link>
          .
        </Text>
        <Button
          onClick={onClickCreateAccount}
          isDisabled={loading}
          marginTop="8"
          width="100%"
          variant="primary"
        >
          Create Account
        </Button>

        <Text alignSelf="center" marginTop="4" color="gray.400" fontSize="sm">
          Have an account?{' '}
          <Link onClick={onClickLogin} color="blue.600">
            Login
          </Link>
        </Text>
      </Flex>
    </AuthBackground>
  );
};
