import React from 'react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { IconButton } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import customTheme from 'styles/theme';

type Props = {
  onClick?: () => void;
};

export const BackButton: React.FC<Props> = ({ onClick }) => {
  const history = useHistory();

  return (
    <IconButton
      aria-label="Back"
      icon={<ArrowBackIcon height="6" width="6" />}
      variant="ghost"
      onClick={() => (onClick ? onClick() : history.goBack())}
      _hover={{
        border: `1px solid ${customTheme.colors.gray[300]}`,
      }}
    />
  );
};
