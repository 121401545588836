import React, { useCallback, useContext, useEffect, useReducer, useState } from 'react';
import { Flex, Text, Heading, Box, Button, FormLabel, useDisclosure } from '@chakra-ui/react';
import { DropResult, ResponderProvided } from 'react-beautiful-dnd';
import slugify from 'slugify';
import { useQuery, useQueryClient } from 'react-query';
import {
  AssetItem,
  FormInput,
  SelectItems,
  LoadingIndicator,
  DraggableItem,
  DraggableList,
} from 'shared/components';
import {
  DefaultLocaleField,
  GeneralMgmtEntry,
  MenuMgmtEntry,
  UpdateEntryOptions,
  DefaultLocaleLinkEntriesField,
  EntryNotFound,
  EntryResponse,
} from 'services/api/types';
import { AssetsAPI, EntriesAPI } from 'services/api';
import { defaultLocale, ItemActions } from 'shared/functions';
import { useContentQueries } from 'hooks/useContentQueries';
import { FormContainer } from './FormContainer';
import { AccountsStateContext } from 'context/Accounts/AccountsContext';
import { AddEntriesModal } from './AddEntriesModal';
import { AddAssetModal } from './AddAssetModal';
import { InfoBox } from './InfoBox';
import { Link } from 'contentful-management/dist/typings/common-types';
import { ListEntryItem } from './ListEntryItem';
import { getStatusFromEntitySys } from 'shared/functions/EntityStatus';
import { ArchivedBanner } from './ArchivedBanner';
import { useHasChanges } from 'hooks/useHasChanges';
import { AssetProps } from 'contentful-management/dist/typings/export-types';
import { ViewQRCodeModal } from './ViewQRCodeModal';
import { useAutosave } from 'hooks/useAutosave';

type FormState = {
  name?: DefaultLocaleField;
  slug?: DefaultLocaleField;
  website?: DefaultLocaleField;
  menus?: DefaultLocaleLinkEntriesField;
  orderedMenuEntries?: Array<EntryResponse | EntryNotFound>;
  errors: FormValidationError;
};

type FormValidationError = {
  name?: string;
  website?: string;
  general?: string;
};

type FORM_ACTIONS = { type: 'update'; payload: Partial<FormState> };

const initialState: FormState = {
  name: {
    [defaultLocale]: '',
  },
  slug: {
    [defaultLocale]: '',
  },
  website: {
    [defaultLocale]: '',
  },
  menus: {
    [defaultLocale]: [],
  },
  orderedMenuEntries: [],
  errors: {},
};

const reducer = (state: FormState, action: FORM_ACTIONS) => {
  switch (action.type) {
    case 'update': {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};

type FormProps = {
  general: GeneralMgmtEntry;
};

const mapGeneralToState = (general: GeneralMgmtEntry): FormState => {
  return {
    ...initialState,
    name: general.fields.name || initialState.name,
    slug: general.fields.slug || initialState.slug,
    website: general.fields.website || initialState.website,
    menus: general.fields.menus || initialState.menus,
  };
};

export const GeneralForm: React.FC<FormProps> = ({ general }) => {
  const queryClient = useQueryClient();
  const { state: accountsState } = useContext(AccountsStateContext);

  const { selectedAccount } = accountsState;
  const mappedGeneral = mapGeneralToState(general);
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    ...mappedGeneral,
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [addAssetToField, setAddAssetToField] = useState<string | undefined>();
  const [viewQRCode, setViewQRCode] = useState<boolean>(false);

  const linkedEntriesIds: string[] = [];
  if (general.fields.menus && general.fields.menus[defaultLocale]) {
    linkedEntriesIds.push(...general.fields.menus[defaultLocale].map((i) => i.sys.id));
  }

  const linkedAssetsIds: string[] = [];
  if (general) {
    if (general.fields.headerImage) {
      linkedAssetsIds.push(general.fields.headerImage[defaultLocale].sys.id);
    }
    if (general.fields.logo) {
      linkedAssetsIds.push(general.fields.logo[defaultLocale].sys.id);
    }
  }

  const { name, slug, website, menus, orderedMenuEntries, errors } = state;

  const itemStatus = getStatusFromEntitySys(general?.sys);
  const formIsDisabled = itemStatus === 'ARCHIVED';

  const { hasChanges } = useHasChanges({ ...mappedGeneral, orderedMenuEntries }, state);

  const { isLoading: loadingMenus } = useQuery(
    ['entries', linkedEntriesIds],
    () =>
      EntriesAPI.filterEntries('menu', {
        accountId: selectedAccount?.id,
        ids: linkedEntriesIds.join(','),
      }),
    {
      enabled: linkedEntriesIds.length > 0,
      onSuccess: (res) => {
        // Reorder linkedEntries to state items order

        const entriesNotFound = linkedEntriesIds.filter((entryId) => {
          return res.findIndex((item) => item.sys.id === entryId) < 0;
        });

        const orderedRes = ItemActions.sortItems(
          [...res, ...entriesNotFound.map((id) => ({ sys: { id } }))],
          linkedEntriesIds,
        );

        dispatch({
          type: 'update',
          payload: {
            orderedMenuEntries: orderedRes,
          },
        });
      },
    },
  );

  const { data: linkedAssets } = useQuery(
    ['assets', linkedAssetsIds],
    () =>
      AssetsAPI.filterAssets({
        title: selectedAccount?.id,
        ids: linkedAssetsIds.join(','),
      }),
    {
      enabled: linkedAssetsIds.length > 0,
    },
  );

  useEffect(() => {
    if (general) {
      dispatch({
        type: 'update',
        payload: {
          ...mapGeneralToState(general),
          ...((!general.fields.menus || general.fields.menus[defaultLocale].length === 0) && {
            orderedMenuEntries: [],
          }),
        },
      });
    }
  }, [general]);

  const { mutateSave, saveLoading } = useContentQueries('general', (res) => {
    console.log(`🚀 => {mutateSave} => res`, res);
    onActionSuccess();
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    dispatch({
      type: 'update',
      payload: {
        [name]: {
          [defaultLocale]: value,
        },
        ...(name === 'name' && {
          slug: { [defaultLocale]: slugify(value.toLowerCase()) },
        }),
        errors: {
          ...state.errors,
          [name]: undefined,
        },
      },
    });
  };

  const onClickSaveChanges = useCallback(async () => {
    const { name, slug, website, menus } = state;

    try {
      const updateOptions: UpdateEntryOptions = {
        ...general.fields,
        ...(name && name[defaultLocale] && { name }),
        ...(slug && slug[defaultLocale] && { slug }),
        ...(website && website[defaultLocale] && { website }),
        menus,
      };
      console.log(`🚀 => onClickSaveChanges => updateOptions`, updateOptions);
      mutateSave({ entryId: general.sys.id, options: updateOptions });
    } catch (error) {
      console.log('error :>> ', error);
    }
  }, [state, mutateSave]);

  useAutosave({ hasChanges, handleSave: onClickSaveChanges });

  const onSuccessInsertEntries = () => {
    onActionSuccess();
    onClose();
  };
  const onSuccessInsertAsset = () => {
    onActionSuccess();
    setAddAssetToField(undefined);
  };

  const onClickSelectEntries = () => {
    // not checking if hasChanges here like all the other forms
    // as changing the name may not be intentional and should be more confirmed by user
    onOpen();
  };

  const onClickRemoveLink = (id: string) => {
    if (general) {
      const newItems = general.fields.menus[defaultLocale].slice();
      const foundIndex = newItems.findIndex((i) => i.sys.id === id);
      if (foundIndex > -1) {
        newItems.splice(foundIndex, 1);

        const updateOptions: UpdateEntryOptions = {
          ...general.fields,
          menus: {
            [defaultLocale]: newItems,
          },
        };
        console.log(`🚀 => onClickRemoveLink => updateOptions`, updateOptions);
        mutateSave({ entryId: general.sys.id, options: updateOptions });
      }
    }
  };

  const onClickSelectAsset = (type?: string) => {
    setAddAssetToField(type);
  };

  const onClickRemoveAsset = (fieldKey: 'logo' | 'headerImage') => {
    if (general) {
      const updateOptions: UpdateEntryOptions = {
        ...general.fields,
        [fieldKey]: undefined,
      };
      console.log(`🚀 => onClickSaveChanges => updateOptions`, updateOptions);
      mutateSave({ entryId: general.sys.id, options: updateOptions });
    }
  };

  const { items: assets = [] } = linkedAssets || {};

  const renderLogoSelect = () => {
    if (general && general.fields.logo) {
      let logoAsset: EntryNotFound | AssetProps;
      logoAsset = assets.find(
        (asset) => asset.sys.id === general.fields.logo[defaultLocale].sys.id,
      ) as AssetProps;

      if (!logoAsset) {
        logoAsset = {
          sys: {
            id: general.fields.logo[defaultLocale].sys.id,
          },
        };
      }

      return (
        <AssetItem
          asset={logoAsset}
          isDisabled={formIsDisabled || saveLoading}
          onClickRemove={() => onClickRemoveAsset('logo')}
          type="rectangle"
        />
      );
    }

    return <SelectItems title="Select Logo" onClick={onClickSelectAsset} type="logo" />;
  };

  const renderHeaderImage = () => {
    if (general && general.fields.headerImage) {
      let headerAsset: EntryNotFound | AssetProps;
      headerAsset = assets.find(
        (asset) => asset.sys.id === general.fields.headerImage[defaultLocale].sys.id,
      ) as AssetProps;

      if (!headerAsset) {
        headerAsset = {
          sys: {
            id: general.fields.headerImage[defaultLocale].sys.id,
          },
        };
      }

      return (
        <AssetItem
          asset={headerAsset}
          isDisabled={formIsDisabled || saveLoading}
          onClickRemove={() => onClickRemoveAsset('headerImage')}
          type="rectangle"
        />
      );
    }

    return (
      <SelectItems
        disabled={formIsDisabled}
        title="Select Header"
        onClick={onClickSelectAsset}
        type="headerImage"
      />
    );
  };

  const onActionSuccess = () => {
    queryClient.refetchQueries(['entryById', general.sys.id]);
    queryClient.refetchQueries([
      'general',
      `${selectedAccount ? selectedAccount.account_name : ''}`,
    ]);
  };

  const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    console.log(`🚀 => onDragEnd => result`, result, provided);
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }

    if (menus && orderedMenuEntries) {
      const reorderedEntries = ItemActions.reorderItems<MenuMgmtEntry | EntryNotFound>(
        orderedMenuEntries,
        source.index,
        destination.index,
      );

      const reorderedMenus = ItemActions.reorderItems<Link<'Entry'>>(
        menus[defaultLocale],
        source.index,
        destination.index,
      );
      console.log(`🚀 => onDragEnd => items`, reorderedEntries);

      dispatch({
        type: 'update',
        payload: {
          orderedMenuEntries: reorderedEntries,
          menus: {
            [defaultLocale]: reorderedMenus,
          },
        },
      });
    }
  };

  const toggleViewQRCode = () => {
    setViewQRCode((prev) => !prev);
  };

  return (
    <Flex flexDirection="column" marginTop="4" alignItems="flex-start">
      <Flex alignItems="center" justifyContent="space-between" width="100%">
        <Box>
          <Text>INFO</Text>

          <Heading wordBreak="break-all" fontSize={{ base: 'xl', sm: '2xl' }}>
            {name && name[defaultLocale].length > 0 ? name[defaultLocale] : 'Untitled'}
          </Heading>
        </Box>

        <Button
          marginLeft="4"
          onClick={onClickSaveChanges}
          variant="primary"
          isLoading={saveLoading}
          isDisabled={formIsDisabled}
        >
          Save Changes
        </Button>
      </Flex>

      <Flex
        width="100%"
        alignItems="flex-start"
        flexWrap="wrap"
        flexDirection={{ base: 'column', md: 'row' }}
      >
        {general && (
          <InfoBox hasChanges={hasChanges} item={general} onActionSuccess={onActionSuccess} />
        )}

        <FormContainer>
          {formIsDisabled && <ArchivedBanner />}
          <Flex
            flexDirection="column"
            flex="0"
            borderLeft="3px solid black"
            borderColor="blue.100"
            paddingLeft="4"
            marginBottom="4"
          >
            <FormInput
              label="Store Name"
              name="name"
              value={name ? name[defaultLocale] : ''}
              onChange={onChange}
              error={errors.name}
              placeholder="Enter your Store name"
              disabled={formIsDisabled}
            />
            {slug && (
              <>
                <Text wordBreak="break-all" color="gray.800" fontWeight="semibold">
                  {`https://visual.menu/${slug[defaultLocale]}`}
                </Text>
                {general && general.fields.slug && (
                  <Button
                    marginTop="2"
                    onClick={toggleViewQRCode}
                    variant="outline"
                    backgroundColor="white"
                  >
                    View URL as QR Code
                  </Button>
                )}
              </>
            )}
          </Flex>
          <Flex
            borderLeft="3px solid black"
            borderColor="blue.100"
            paddingLeft="4"
            marginBottom="4"
          >
            <FormInput
              label="Store Website"
              name="website"
              value={website ? website[defaultLocale] : ''}
              onChange={onChange}
              error={errors.website}
              placeholder="https://my-store.com"
              required={false}
              disabled={formIsDisabled}
            />
          </Flex>

          <Flex
            flexDirection={{ base: 'column', sm: 'row' }}
            flexWrap="wrap"
            borderLeft="3px solid black"
            borderColor="blue.100"
            paddingLeft="4"
            marginBottom="4"
          >
            <Flex flexDirection="column" flex="1" marginRight={{ base: '0', sm: '2' }}>
              <FormLabel>Logo (required)</FormLabel>
              {renderLogoSelect()}
            </Flex>

            <Flex
              flexDirection="column"
              flex="1"
              marginLeft={{ base: '0', sm: '2' }}
              marginTop={{ base: '4', sm: '0' }}
            >
              <FormLabel>Header Image (required)</FormLabel>

              {renderHeaderImage()}
            </Flex>
          </Flex>

          <Flex
            flexDirection="column"
            borderLeft="3px solid black"
            borderColor="blue.100"
            paddingLeft="4"
          >
            <FormLabel>Menus (required)</FormLabel>

            <DraggableList isDropDisabled={false} droppableId="category" onDragEnd={onDragEnd}>
              {loadingMenus ? (
                <Flex marginBottom="6" justifyContent="center">
                  <LoadingIndicator />
                </Flex>
              ) : (
                <>
                  {orderedMenuEntries &&
                    (orderedMenuEntries as MenuMgmtEntry[]).map((item, index) => {
                      const { sys } = item;
                      return (
                        <DraggableItem
                          key={`${sys.id}-${index}`}
                          draggableId={item.sys.id}
                          index={index}
                          isDragDisabled={formIsDisabled}
                        >
                          <ListEntryItem
                            entry={item}
                            contentTypeId="menu"
                            onClickRemove={onClickRemoveLink}
                            isDisabled={saveLoading}
                          />
                        </DraggableItem>
                      );
                    })}
                </>
              )}
            </DraggableList>

            <SelectItems
              disabled={formIsDisabled}
              title="Select Menus"
              onClick={onClickSelectEntries}
            />
          </Flex>
        </FormContainer>
      </Flex>

      {isOpen && general && (
        <AddEntriesModal
          entry={general}
          isOpen={isOpen}
          onClose={onClose}
          contentTypeId="menu"
          entryTypeId="general"
          onSuccess={onSuccessInsertEntries}
          filterOutList={orderedMenuEntries}
        />
      )}

      {addAssetToField && general && (
        <AddAssetModal
          entry={general}
          isOpen={!!addAssetToField}
          onClose={() => setAddAssetToField(undefined)}
          updateKey={addAssetToField}
          onSuccess={onSuccessInsertAsset}
        />
      )}

      {viewQRCode && general && (
        <ViewQRCodeModal
          general={general}
          isOpen={viewQRCode}
          onClose={() => setViewQRCode(false)}
        />
      )}
    </Flex>
  );
};
