import React from 'react';
import { Text, Flex, IconButton } from '@chakra-ui/react';
import { ContentType, EntryResponse } from 'services/api/types';
import { ItemActions } from 'shared/functions';
import { StatusLabel } from 'shared/components';
import { DeleteIcon } from '@chakra-ui/icons';

type EntryItemProps = {
  entry: EntryResponse;
  onClickRemove: (entryId: string) => void;
  contentTypeId: ContentType;
  isDisabled: boolean;
};

export const ListEntryItem: React.FC<EntryItemProps> = ({
  entry,
  contentTypeId,
  onClickRemove,
  isDisabled,
}) => {
  const { sys } = entry;
  return (
    <Flex
      border="1px solid"
      borderColor={'gray.200'}
      borderRadius="lg"
      flexDirection="column"
      borderLeftRadius="0"
      borderLeftColor="transparent"
      backgroundColor={'white'}
      boxShadow={'sm'}
      flex={1}
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        borderBottom="1px solid"
        borderColor="gray.200"
        paddingX="4"
        paddingY="2"
      >
        <Text>{ItemActions.getLabelFromContentTypeId(contentTypeId)}</Text>
        <Flex alignItems="center">
          <StatusLabel sys={sys} />
          <IconButton
            variant="outline"
            aria-label="Remove"
            icon={<DeleteIcon />}
            marginLeft="8"
            onClick={() => onClickRemove(sys.id)}
            isDisabled={isDisabled}
          />
        </Flex>
      </Flex>
      <Flex padding="4">
        <Text fontWeight="semibold">{ItemActions.getEntryTitleByType(contentTypeId, entry)}</Text>
      </Flex>
    </Flex>
  );
};
