import React from 'react';
import { AssetProps } from 'contentful-management/dist/typings/export-types';
import { Flex, IconButton, Image, Text } from '@chakra-ui/react';
import { StatusLabel } from '../StatusLabel/StatusLabel';
import { DeleteIcon } from '@chakra-ui/icons';
import { defaultLocale } from 'shared/functions';
import { EntryNotFound, isEntryNotFound } from 'services/api/types';

type Props = {
  asset: AssetProps | EntryNotFound;
  onClickRemove: () => void;
  isDisabled: boolean;
  type: 'box' | 'rectangle';
};

export const AssetItem: React.FC<Props> = ({ asset, onClickRemove, isDisabled, type }) => {
  const { sys } = asset;

  const renderAsset = () => {
    if (isEntryNotFound(asset)) {
      return (
        <Flex padding="4">
          <Text fontWeight="semibold">ASSET IS MISSING OR INACCESSIBLE</Text>
        </Flex>
      );
    }

    return (
      <Flex padding="4" alignItems="center">
        <Image
          borderRadius="12px"
          src={(asset as AssetProps).fields.file[defaultLocale].url}
          maxWidth={type === 'box' ? '120px' : '300px'}
          height="120px"
          objectFit="cover"
        />
      </Flex>
    );
  };

  return (
    <Flex
      border="1px solid"
      borderColor={'gray.200'}
      borderRadius="lg"
      flexDirection="column"
      marginBottom="6"
      backgroundColor={'white'}
      boxShadow={'sm'}
    >
      <Flex
        justifyContent="flex-end"
        alignItems="center"
        borderBottom="1px solid"
        borderColor="gray.200"
        paddingX="4"
        paddingY="2"
      >
        <Flex alignItems="center">
          <StatusLabel sys={sys} />
          <IconButton
            variant="outline"
            aria-label="Remove"
            icon={<DeleteIcon />}
            marginLeft="8"
            onClick={onClickRemove}
            isDisabled={isDisabled}
          />
        </Flex>
      </Flex>
      {renderAsset()}
    </Flex>
  );
};
