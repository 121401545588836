// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useContext, useState } from 'react';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  DrawerCloseButton,
  Button,
  useBreakpointValue,
  Flex,
  Heading,
  Input,
  InputLeftElement,
  InputGroup,
  SlideDirection,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { useMutation, useQuery } from 'react-query';
import { Link } from 'contentful-management/dist/typings/common-types';
import { AccountsStateContext } from 'context/Accounts/AccountsContext';
import {
  CategoryMgmtEntry,
  ContentType,
  EntriesResponse,
  EntryNotFound,
  EntryResponse,
  GeneralMgmtEntry,
  MenuItemMgmtEntry,
  MenuMgmtEntry,
  RequestErrorType,
  UpdateEntryOptions,
} from 'services/api/types';
import { EntriesAPI } from 'services/api';
import { AddEntryItem } from './AddEntryItem';
import { defaultLocale } from 'shared/functions';
import { useInfiniteScrollData } from 'hooks/useInfiniteScrollData';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  contentTypeId: ContentType;
  entryTypeId: ContentType;
  entry: CategoryMgmtEntry | MenuItemMgmtEntry | MenuMgmtEntry | GeneralMgmtEntry;
  onSuccess: () => void;
  filterOutList?: Array<
    CategoryMgmtEntry | MenuItemMgmtEntry | MenuMgmtEntry | GeneralMgmtEntry | EntryNotFound
  >;
};

export const AddEntriesModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  contentTypeId,
  entryTypeId,
  entry,
  onSuccess,
  filterOutList = [],
}) => {
  const { state: accountsState } = useContext(AccountsStateContext);
  const { selectedAccount } = accountsState;
  const [selectedEntries, setSelectedEntries] = useState<EntryResponse[]>([]);
  const drawerSize = useBreakpointValue({ base: 'sm', md: 'md' });
  const drawerPlacement: SlideDirection | undefined = useBreakpointValue({
    base: 'bottom',
    md: 'right',
  });
  const [search, setSearch] = useState('');

  const { data } = useQuery(
    `${contentTypeId}-${selectedAccount ? selectedAccount.account_name : ''}`,
    () =>
      EntriesAPI.filterEntries(contentTypeId, {
        accountId: selectedAccount?.id || '',
        archivedAt: false,
      }),
    {
      enabled: !!(selectedAccount && contentTypeId),
      select: (data) => {
        if (filterOutList.length > 0) {
          return data.filter((i) => !filterOutList.find((f) => i.sys.id === f.sys.id));
        }
        return data;
      },
    },
  );

  const filteredDataBySearch = (data || []).filter((item) => {
    const lowerCaseSearch = search.toLowerCase();

    switch (entryTypeId) {
      case 'category':
        return (item as MenuItemMgmtEntry).fields.title?.[defaultLocale]
          .toLowerCase()
          .includes(lowerCaseSearch);
      case 'menu':
        return (item as CategoryMgmtEntry).fields.title?.[defaultLocale]
          .toLowerCase()
          .includes(lowerCaseSearch);
      case 'general':
        return (item as MenuMgmtEntry).fields.title?.[defaultLocale]
          .toLowerCase()
          .includes(lowerCaseSearch);

      default:
        return false;
    }
  });

  const { ref: footerRef, data: dataByPage } = useInfiniteScrollData(filteredDataBySearch);

  const { mutate: mutateUpdateEntry, isLoading: isUpdating } = useMutation(
    (options: UpdateEntryOptions) => EntriesAPI.updateEntry(entry.sys.id, options),
    {
      onSuccess: (res) => {
        console.log(`🚀 => mutateInsertEntries:res`, res);
        onSuccess();
      },
      onError: (error: RequestErrorType) => {
        console.log(`🚀 => error`, error);
      },
    },
  );

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearch(value);
  };

  const onClickEntry = (entry: EntryResponse) => {
    setSelectedEntries((prev) => {
      const prevCopy = prev.slice();
      const foundEntryIndex = prevCopy.findIndex((i) => i.sys.id === entry.sys.id);
      if (foundEntryIndex > -1) {
        prevCopy.splice(foundEntryIndex, 1);
      } else {
        prevCopy.push(entry);
      }
      return prevCopy;
    });
  };

  const onClickInsertEntries = () => {
    let updatedEntry: CategoryMgmtEntry | MenuMgmtEntry | null = null;
    let updateKey: 'items' | 'categories' | 'menus' = 'items';
    switch (entryTypeId) {
      case 'category':
        updatedEntry = { ...(entry as CategoryMgmtEntry) };
        updateKey = 'items';
        break;
      case 'menu':
        updatedEntry = { ...(entry as MenuMgmtEntry) };
        updateKey = 'categories';
        break;
      case 'general':
        updatedEntry = { ...(entry as GeneralMgmtEntry) };
        updateKey = 'menus';
        break;
      default:
        break;
    }

    const selectedEntriesAsLinks: Link<'Entry'>[] = selectedEntries.map((e) => {
      return {
        sys: {
          type: 'Link',
          linkType: 'Entry',
          id: e.sys.id,
        },
      };
    });

    console.log(
      `🚀 => const selectedEntriesAsLinks:Link<'Entry'>=selectedEntries.map => selectedEntriesAsLinks`,
      selectedEntriesAsLinks,
    );

    if (updatedEntry) {
      if (updatedEntry.fields[updateKey] && updatedEntry.fields[updateKey][defaultLocale]) {
        const itemsCopy = updatedEntry.fields[updateKey][defaultLocale].slice();
        // Combine fields with previous
        itemsCopy.push(...selectedEntriesAsLinks);
        updatedEntry.fields[updateKey][defaultLocale] = itemsCopy;
      } else {
        // add as new
        updatedEntry.fields[updateKey] = {
          [defaultLocale]: selectedEntriesAsLinks,
        };
      }
      console.log(`🚀 => onClickInsertEntries => updatedEntry`, updatedEntry);
      mutateUpdateEntry(updatedEntry.fields);
    }
  };

  return (
    <Drawer
      isFullHeight={true}
      placement={drawerPlacement}
      isOpen={isOpen}
      onClose={onClose}
      size={drawerSize}
    >
      <DrawerOverlay />
      <DrawerContent zIndex="overlay">
        <DrawerHeader
          fontSize="xl"
          fontWeight="bold"
          borderBottom="1px solid"
          borderColor="gray.300"
        >
          Add Entries
        </DrawerHeader>
        <DrawerCloseButton />

        <Flex paddingY={8} paddingX={8}>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <SearchIcon color="gray.300" />
            </InputLeftElement>
            <Input
              backgroundColor="gray.50"
              type="text"
              placeholder="Search items"
              onChange={onChange}
              value={search}
            />
          </InputGroup>
        </Flex>
        {dataByPage.length > 0 ? (
          <>
            <DrawerBody overflowY="auto" paddingBottom="8">
              {(dataByPage as EntriesResponse).map((entry) => {
                const isSelected = selectedEntries.find((e) => e.sys.id === entry.sys.id);
                return (
                  <AddEntryItem
                    key={entry.sys.id}
                    isSelected={!!isSelected}
                    entry={entry}
                    onClickEntry={onClickEntry}
                    contentTypeId={contentTypeId}
                  />
                );
              })}
              <Flex height={10} width={10} marginTop="8" ref={footerRef} />
            </DrawerBody>
          </>
        ) : (
          <DrawerBody>
            <Heading size={'md'}>No items found.</Heading>
          </DrawerBody>
        )}

        <DrawerFooter borderTop="1px solid" borderColor="gray.300">
          <Button variant="ghost" marginRight="4" onClick={onClose}>
            Close
          </Button>
          <Button
            isLoading={isUpdating}
            onClick={onClickInsertEntries}
            variant="add"
            isDisabled={selectedEntries.length === 0}
          >
            {selectedEntries.length === 0
              ? 'Select Entries'
              : `Insert ${selectedEntries.length} Entries`}
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
