import React from 'react';
import { Box } from '@chakra-ui/react';
import { AlertBanner } from 'shared/components';

export const ArchivedBanner: React.FC = () => {
  return (
    <Box marginBottom="4">
      <AlertBanner
        status="warning"
        variant="left-accent"
        title="Archived"
        description="This entry is archived and cannot be modified. Please unarchive it to make any changes."
      />
    </Box>
  );
};
