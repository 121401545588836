import React, { useState } from 'react';
import { Button, ButtonGroup, chakra, Flex, useToast, Portal, Text } from '@chakra-ui/react';
import { EntriesResponse, RequestErrorType } from 'services/api/types';
import { useMutation } from 'react-query';
import { EntriesAPI } from 'services/api';
import { ItemActions } from 'shared/functions';
import { AlertDialog } from 'shared/components';
import { useIsSubscribed } from 'hooks/useIsSubscribed';
import { useHistory } from 'react-router-dom';
import { StarIcon } from '@chakra-ui/icons';

type Props = {
  data?: EntriesResponse;
  onCheckItem?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selected: string[];
  onActionSuccess: () => void;
  isStatic: boolean;
};

// INFO: Handling one item selected at a time for now
// Actions are dependent on Entry Status - some statuses clash with one another

export const ActionRow: React.FC<Props> = ({
  data,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onCheckItem,
  selected,
  onActionSuccess,
  isStatic = false,
}) => {
  const toast = useToast({ duration: 2000, position: 'bottom' });
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const history = useHistory();
  const isSubscribed = useIsSubscribed();

  const { mutate: mutateDelete, isLoading: deleteLoading } = useMutation(
    (entryId: string) => EntriesAPI.deleteEntry(entryId),
    {
      onSuccess: (data) => {
        console.log(`🚀 => onSuccess:`, data);
        if (deleteDialogOpen) {
          setDeleteDialogOpen(false);
        }
        toast({
          title: 'Deleted successfully',
          status: 'success',
        });
        onActionSuccess();
      },
      onError: (error: RequestErrorType) => {
        toast({
          title: error.message,
          status: 'error',
        });
      },
    },
  );
  const { mutate: mutatePublish, isLoading: publishLoading } = useMutation(
    (entryId: string) => EntriesAPI.publishEntry(entryId),
    {
      onSuccess: (data) => {
        console.log(`🚀 => onSuccess:`, data);
        toast({
          title: 'Published successfully',
          status: 'success',
        });
        onActionSuccess();
      },
      onError: (error: RequestErrorType) => {
        toast({
          title: error.message,
          status: 'error',
        });
      },
    },
  );
  const { mutate: mutateArchive, isLoading: archiveLoading } = useMutation(
    (entryId: string) => EntriesAPI.archiveEntry(entryId),
    {
      onSuccess: (data) => {
        console.log(`🚀 => onSuccess:`, data);
        toast({
          title: 'Archived successfully',
          status: 'success',
        });
        onActionSuccess();
      },
      onError: (error: RequestErrorType) => {
        toast({
          title: error.message,
          status: 'error',
        });
      },
    },
  );
  const { mutate: mutateUnarchive, isLoading: unarchiveLoading } = useMutation(
    (entryId: string) => EntriesAPI.unarchiveEntry(entryId),
    {
      onSuccess: (data) => {
        console.log(`🚀 => onSuccess:`, data);
        toast({
          title: 'Unarchived successfully',
          status: 'success',
        });
        onActionSuccess();
      },
      onError: (error: RequestErrorType) => {
        toast({
          title: error.message,
          status: 'error',
        });
      },
    },
  );
  const { mutate: mutateUnpublish, isLoading: unpublishLoading } = useMutation(
    (entryId: string) => EntriesAPI.unpublishEntry(entryId),
    {
      onSuccess: (data) => {
        console.log(`🚀 => onSuccess:`, data);
        toast({
          title: 'Unpublished successfully',
          status: 'success',
        });
        onActionSuccess();
      },
      onError: (error: RequestErrorType) => {
        toast({
          title: error.message,
          status: 'error',
        });
      },
    },
  );

  const onClickDelete = () => {
    setDeleteDialogOpen(true);
  };
  const onClickConfirmDelete = () => {
    mutateDelete(selected[0]);
  };

  const onClickPublish = () => {
    if (isSubscribed) {
      mutatePublish(selected[0]);
    } else {
      history.replace({ search: new URLSearchParams({ upgrade: 'true' }).toString() });
    }
  };
  const onClickUnpublish = () => {
    mutateUnpublish(selected[0]);
  };
  const onClickArchive = () => {
    mutateArchive(selected[0]);
  };
  const onClickUnarchive = () => {
    mutateUnarchive(selected[0]);
  };

  const showOptions = ItemActions.getOptionsFromSelected(selected, data || []);

  const renderButtonGroup = () => {
    if (selected.length === 0) {
      return null;
    }
    return (
      <ButtonGroup isAttached>
        {showOptions.includes('delete') && (
          <ActionButton variant="delete" onClick={onClickDelete} isLoading={deleteLoading}>
            Delete
          </ActionButton>
        )}
        {showOptions.includes('unpublish') && (
          <ActionButton variant="secondary" onClick={onClickUnpublish} isLoading={unpublishLoading}>
            Unpublish
          </ActionButton>
        )}
        {showOptions.includes('archive') && (
          <ActionButton variant="secondary" onClick={onClickArchive} isLoading={archiveLoading}>
            Archive
          </ActionButton>
        )}
        {showOptions.includes('unarchive') && (
          <ActionButton variant="secondary" onClick={onClickUnarchive} isLoading={unarchiveLoading}>
            Unarchive
          </ActionButton>
        )}
        {showOptions.includes('publish') && (
          <ActionButton
            leftIcon={isSubscribed ? null : <StarIcon />}
            variant="add"
            onClick={onClickPublish}
            isLoading={publishLoading}
          >
            Publish
          </ActionButton>
        )}
      </ButtonGroup>
    );
  };
  return (
    <>
      {isStatic ? (
        <StaticActionRowContainer>
          <Text
            fontWeight="bold"
            marginRight={{ base: '0', sm: '6' }}
            marginBottom={{ base: '2', sm: '0' }}
          >
            {`${selected.length}  ${selected.length === 1 ? 'entry' : 'entries'} selected:`}
          </Text>

          {renderButtonGroup()}
        </StaticActionRowContainer>
      ) : (
        <Flex flex="1" marginY="2" justifyContent="flex-end" alignItems="center">
          {renderButtonGroup()}
        </Flex>
      )}

      <AlertDialog
        isOpen={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        title="Confirm Delete"
        description="Are you sure you would like to delete this entry?"
        onAction={() => onClickConfirmDelete()}
        actionTitle="Delete"
        actionLoading={deleteLoading}
      />
    </>
  );
};

const StaticActionRowContainer: React.FC = ({ children }) => {
  return (
    <Portal>
      <Flex position="absolute" bottom="0" left="4" right="4" flex="1" justifyContent="center">
        <Flex
          justifyContent="center"
          alignItems="center"
          backgroundColor="gray.50"
          boxShadow="lg"
          padding="4"
          zIndex="overlay"
          borderRadius="lg"
          flexDirection={{ base: 'column', sm: 'row' }}
          marginY="4"
        >
          {children}
        </Flex>
      </Flex>
    </Portal>
  );
};
const ActionButton = chakra(Button, {
  baseStyle: {
    minHeight: 'auto',
    minWidth: 'auto',
  },
});
