import React, { MouseEventHandler } from 'react';
import { Checkbox, Flex } from '@chakra-ui/react';

type CardProps = {
  onClick: MouseEventHandler<HTMLDivElement>;
  id?: string;
  isChecked?: boolean;
  onCheck?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const Card: React.FC<CardProps> = ({ id, children, onClick, isChecked, onCheck }) => {
  return (
    <Flex
      flexDirection="row"
      alignItems="flex-start"
      marginBottom="4"
      borderRadius="xl"
      background={isChecked ? 'gray.300' : 'gray.50'}
    >
      <Flex
        padding="2"
        marginTop="4"
        borderRadius="md"
        background={isChecked ? 'gray.300' : 'gray.50'}
        border="1px solid transparent"
      >
        <Checkbox id={id} size="lg" isChecked={isChecked} onChange={onCheck} />
      </Flex>

      <Flex
        flexDirection="row"
        flex={1}
        justifyContent="space-between"
        backgroundColor="gray.50"
        borderRadius="xl"
        padding="4"
        onClick={onClick}
        border={`1px solid transparent`}
        boxShadow="md"
        _hover={{
          background: 'gray.200',
          cursor: 'pointer',
        }}
      >
        {children}
      </Flex>
    </Flex>
  );
};
