import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { PageLoading } from 'features';
import { authRoutes } from 'router';
import { useAccountsStateContext } from 'context/Accounts/AccountsContext';
import { useAuthStateContext } from 'context/Auth/AuthStateContext';

export const AuthLayout: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { state: authState } = useAuthStateContext();
  const { state: accountsState } = useAccountsStateContext();
  const { isFetching: authFetching, user } = authState;
  const { selectedAccount, isFetching: accountsFetching } = accountsState;

  useEffect(() => {
    const { pathname } = location;

    if (!authFetching && !accountsFetching) {
      if (user) {
        if (
          pathname !== '/account-details' &&
          (!user.firstName || !user.lastName || !selectedAccount)
        ) {
          history.replace('/account-details');
          return;
        }

        if (user.firstName && user.lastName && selectedAccount) {
          history.replace('/');
        }
      }

      if (!user) {
        if (pathname === '/account-details') {
          history.replace('/login');
          return;
        }
      }
    }
  }, [user, authFetching, location, selectedAccount, accountsFetching]);

  if (authFetching || accountsFetching) {
    return <PageLoading />;
  }

  return (
    <Switch>
      {authRoutes.map(({ path, name, component, exact }) => (
        <Route path={path} key={name} component={component} exact={exact} />
      ))}
      <Redirect to="/create-account" />
    </Switch>
  );
};
