import React, { useState } from 'react';
import queryString from 'query-string';
import {
  Flex,
  Text,
  Heading,
  Table,
  Th,
  Tr,
  Td,
  Thead,
  Tbody,
  Tfoot,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { useFetchAnalytics } from './useFetchAnalytics';
import dayjs from 'shared/functions/DayJS';
import { LoadingIndicator, Modal, Tag } from 'shared/components';
import { QueryAnalyticsData } from 'services/api/types';

const QUERY_DATE_FORMAT = 'YYYY-MM-DD';

type PageViewInterval = '7' | '30' | '90' | '365';
type PageViewIntervalLabel = 'Last 7 days' | 'Last Month' | 'Last 3 Months' | 'Last Year';

const PAGE_VIEW_INTERVALS: Record<PageViewInterval, PageViewIntervalLabel> = {
  '7': 'Last 7 days',
  '30': 'Last Month',
  '90': 'Last 3 Months',
  '365': 'Last Year',
};

export const Analytics: React.FC = () => {
  const [pageViewInterval, setPageViewInterval] = useState<PageViewInterval>('30');
  const startDate = dayjs().subtract(Number(pageViewInterval), 'days').format(QUERY_DATE_FORMAT);
  const endDate = dayjs().format(QUERY_DATE_FORMAT);
  const { menuAnalytics, menuItemAnalytics, isLoading, storeName } = useFetchAnalytics({
    startDate,
    endDate,
  });

  const onChangeInterval = (value: string) => {
    setPageViewInterval(value as PageViewInterval);
  };
  console.log(`🚀 => useFetchAnalytics:menus`, menuAnalytics);
  console.log(`🚀 => useFetchAnalytics:menuItems`, menuItemAnalytics);

  return (
    <Flex flexDirection={'column'}>
      <Heading size={'lg'} fontWeight="black" marginBottom={{ xs: 4, sm: 0 }}>
        ANALYTICS
      </Heading>
      <Text fontWeight={'light'} fontSize={'sm'}>
        Pageviews per menu or menu item
      </Text>

      <Flex flexDirection={'column'} maxWidth={'100%'}>
        <Flex
          marginTop={8}
          borderBottom={'1px solid grey'}
          paddingBottom="4"
          borderColor={'gray.300'}
          overflowX="auto"
          flex={1}
          flexDirection="row"
          alignItems={'center'}
          css={{
            ['> div']: {
              marginRight: '16px',
            },
          }}
        >
          {Object.keys(PAGE_VIEW_INTERVALS).map((interval) => {
            const intervalLabel = PAGE_VIEW_INTERVALS[interval as PageViewInterval];
            const isSelected = pageViewInterval === interval;
            return (
              <Tag
                key={interval}
                title={intervalLabel}
                isSelected={isSelected}
                onClick={() => onChangeInterval(interval)}
              />
            );
          })}
        </Flex>

        {isLoading ? (
          <Flex justifyContent="center" alignItems="center" flex="1" marginTop={8}>
            <LoadingIndicator size="1.5" />
          </Flex>
        ) : (
          <>
            {(!menuAnalytics || menuAnalytics.length === 0) &&
            (!menuItemAnalytics || menuItemAnalytics.length === 0) ? (
              <Text fontWeight={'semibold'} fontSize={'lg'} marginTop="8">
                No pageviews found yet. Please check back at a later time.
              </Text>
            ) : (
              <>
                <DataTable storeName={storeName} title="Menu" items={menuAnalytics} type="menu" />
                {menuAnalytics && menuAnalytics.length > 0 && (
                  <Flex width="100%" height="1px" backgroundColor={'gray.300'} marginTop={8} />
                )}
                <DataTable
                  storeName={storeName}
                  title="Menu Items"
                  items={menuItemAnalytics}
                  type="menuItem"
                />
              </>
            )}
          </>
        )}
      </Flex>
    </Flex>
  );
};

const mapMenuItems = ({
  storeName,
  items,
}: {
  items: QueryAnalyticsData[];
  storeName?: string;
}): { title: string; count: number }[] => {
  return items.map((item) => {
    const { item: url, count } = item;
    const parsedUrl = queryString.parseUrl(url);
    const itemName = parsedUrl.url.replace(`/${storeName}/menu/item/`, '');
    return {
      title: itemName,
      count,
    };
  });
};

type MappedCategoriesByMenu = {
  [menu: string]: {
    count: number;
    title: string;
  }[];
};
const mapMenuToLists = ({
  items,
  storeName,
}: {
  items: QueryAnalyticsData[];
  storeName: string;
}) => {
  const result: MappedCategoriesByMenu = {};
  items.forEach((item) => {
    const { item: url, count } = item;
    const parsedUrl = queryString.parseUrl(decodeURIComponent(url));

    const menuName = parsedUrl.url.replace(`/${storeName}/menu/`, '');
    const category = parsedUrl.query.category as string;
    if (category) {
      if (result[menuName]) {
        result[menuName].push({ title: category, count });
      } else {
        result[menuName] = [
          {
            title: category,
            count,
          },
        ];
      }
    }
  });
  return result;
};

type DataTableProps = {
  storeName?: string;
  title: string;
  items?: QueryAnalyticsData[];
  type: 'menu' | 'menuItem';
};
const DataTable: React.FC<DataTableProps> = ({ storeName, items, type }) => {
  let mappedMenus: MappedCategoriesByMenu = {};
  if (!items || items.length === 0) return <></>;

  if (type === 'menu') {
    if (items && storeName) {
      mappedMenus = mapMenuToLists({ items, storeName });
      console.log(`🚀 => mappedMenus`, mappedMenus);
    }

    return (
      <Flex
        flexDirection={['column', 'row']}
        flexWrap="wrap"
        width="100%"
        alignItems={'flex-start'}
      >
        {Object.keys(mappedMenus).map((menuName) => {
          const itemsByMenuName = mappedMenus[menuName];
          return (
            <AnalyticsTable key={menuName} title={menuName} items={itemsByMenuName} type={type} />
          );
        })}
      </Flex>
    );
  }

  // MENU ITEMS
  const mappedMenuItems = mapMenuItems({ items, storeName });
  return <AnalyticsTable title="MENU ITEMS" items={mappedMenuItems} type={type} />;
};

type AnalyticsTableProps = {
  title: string;
  items: {
    count: number;
    title: string;
  }[];
  type: 'menu' | 'menuItem';
};

const AnalyticsTable: React.FC<AnalyticsTableProps> = ({
  title = 'MENU',
  items = [],
  type = 'menu',
}) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const itemsToShow = items.slice(0, 5);

  return (
    <>
      <Table
        size="md"
        width={['100%', '365px']}
        marginTop={8}
        borderRadius={'md'}
        background={'white'}
        boxShadow={'lg'}
        marginRight="8"
      >
        <Thead>
          <Tr>
            <Th fontSize="lg" fontWeight="black">
              {title}
            </Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Thead>
          <Tr>
            <Th>{type === 'menu' ? 'Category' : 'Item Name'}</Th>
            <Th isNumeric>PAGEVIEWS</Th>
          </Tr>
        </Thead>
        <Tbody>
          {itemsToShow.map((item) => {
            return <TableRow item={item} key={item.title} />;
          })}
        </Tbody>
        <Tfoot>
          <Tr>
            {items.length > 5 && (
              <Td border={'none'}>
                <Button onClick={onToggle} variant="outline">
                  View All
                </Button>
              </Td>
            )}
          </Tr>
        </Tfoot>
      </Table>
      <Modal isOpen={isOpen} onClose={onClose} title={title}>
        <Table width={['100%']}>
          <Thead>
            <Tr>
              <Th>{type === 'menu' ? 'Category' : 'Item Name'}</Th>
              <Th isNumeric>PAGEVIEWS</Th>
            </Tr>
          </Thead>
          <Tbody>
            {items.map((item) => {
              return <TableRow item={item} key={item.title} />;
            })}
          </Tbody>
        </Table>
      </Modal>
    </>
  );
};

type TableRowProps = {
  item: {
    title: string;
    count: number;
  };
};
const TableRow: React.FC<TableRowProps> = ({ item }) => {
  const { title, count } = item;
  return (
    <Tr key={title}>
      <Td>{title}</Td>
      <Td isNumeric>{count.toLocaleString()}</Td>
    </Tr>
  );
};
