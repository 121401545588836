import React from 'react';

import { useCheckoutSuccess } from './useCheckoutSuccess';
import { PageLoading } from 'features/PageLoading/PageLoading';

export const SubscriptionStatus: React.FC = () => {
  useCheckoutSuccess();

  return <PageLoading />;
};
