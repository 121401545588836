import { get, patch, post } from './helpers';
import { TeamResponse, TeamsResponse, CreateTeamOptions, UpdateTeamOptions } from './types';

export const createTeam = async (data: CreateTeamOptions): Promise<TeamResponse> => {
  const response = await post<TeamResponse>(`/teams`, data);
  return response;
};

export const getUserTeams = async (): Promise<TeamsResponse> => {
  const response = await get<TeamsResponse>(`/teams`);
  return response;
};

export const updateTeam = async (
  accountId: string,
  data: UpdateTeamOptions,
): Promise<TeamResponse> => {
  const response = await patch<TeamResponse>(`/teams/${accountId}`, data);
  return response;
};
