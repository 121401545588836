import { useQueryClient } from 'react-query';
import queryString from 'query-string';
import { useEffect } from 'react';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useHistory } from 'react-router-dom';

export const useCheckoutSuccess = () => {
  const parsedLocation = queryString.parse(location.search);
  console.log(`🚀 => parsedLocation`, parsedLocation);
  const { success } = parsedLocation;
  const queryClient = useQueryClient();
  const { storedValue } = useLocalStorage('previousPath', '');
  const history = useHistory();

  useEffect(() => {
    if (success) {
      queryClient.refetchQueries('accounts');
    }
    history.push(storedValue as string);
  }, [success]);
};
