import React from 'react';
import { Validations } from 'shared/functions';
import { Box, Text } from '@chakra-ui/react';

type Props = {
  password: string;
};

export const PasswordValidations: React.FC<Props> = ({ password = '' }) => {
  if (password.length < 1) {
    return <Box />;
  }
  let passwordValidations: string[] | string = [];
  if (password.length > 0) {
    passwordValidations = Validations.validPassword(password, true);
  }

  return (
    <Box marginBottom="2">
      <Text color={passwordValidations.includes('min') ? 'red' : 'green'}>
        <ValidSign isInvalid={passwordValidations.includes('min')} />
        Must be at least 8 characters
      </Text>
      <Text color={passwordValidations.includes('lowercase') ? 'red' : 'green'}>
        <ValidSign isInvalid={passwordValidations.includes('lowercase')} />
        Must contain at least 1 lowercase letter
      </Text>
      <Text color={passwordValidations.includes('uppercase') ? 'red' : 'green'}>
        <ValidSign isInvalid={passwordValidations.includes('uppercase')} />
        Must contain at least 1 uppercase letter
      </Text>
      <Text color={passwordValidations.includes('digits') ? 'red' : 'green'}>
        <ValidSign isInvalid={passwordValidations.includes('digits')} />
        Must contain at least 1 number
      </Text>
    </Box>
  );
};

const ValidSign = ({ isInvalid }: { isInvalid: boolean }) => {
  return <span>{isInvalid ? '❌' : '✅'}</span>;
};
