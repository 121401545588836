import { EntityMetaSysProps } from 'contentful-management/dist/typings/common-types';
import { EntryNotFoundSys, isEntityMetaSysProps } from 'services/api/types';

export const ENTITY_STATUS: Record<string, Status> = {
  draft: 'DRAFT',
  changed: 'CHANGED',
  published: 'PUBLISHED',
  archived: 'ARCHIVED',
};
export type Status = 'DRAFT' | 'CHANGED' | 'PUBLISHED' | 'ARCHIVED';
export type BgAndColor = { bg: string; color: string };
export const getColorsFromStatus = (status: Status): BgAndColor => {
  switch (status) {
    case 'DRAFT':
      return {
        bg: 'orange.100',
        color: 'orange.700',
      };
    case 'ARCHIVED':
      return {
        bg: 'red.50',
        color: 'red.700',
      };
    case 'PUBLISHED':
      return {
        bg: 'green.100',
        color: 'green.700',
      };
    case 'CHANGED':
      return {
        bg: 'blue.50',
        color: 'blue.500',
      };
    default:
      return {
        bg: 'gray.100',
        color: 'gray.700',
      };
  }
};

export const getStatusFromEntitySys = (sys?: EntityMetaSysProps | EntryNotFoundSys): Status => {
  if (sys && isEntityMetaSysProps(sys)) {
    // https://www.contentful.com/developers/docs/tutorials/general/determine-entry-asset-state/

    if (sys.archivedAt) {
      return ENTITY_STATUS.archived;
    }

    if (!sys.publishedVersion) {
      return ENTITY_STATUS.draft;
    }

    if (sys.publishedVersion) {
      if (sys.version >= sys.publishedVersion + 2) {
        return ENTITY_STATUS.changed;
      }
      if (sys.version === sys.publishedVersion + 1) {
        return ENTITY_STATUS.published;
      }
    }
  }

  return ENTITY_STATUS.draft;
};
