import React from 'react';
import { Flex, IconButton } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { Logo } from 'shared/components';
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import customTheme from 'styles/theme';

type Props = {
  toggleSidebar: (forceHide: boolean) => void;
  showSidebar: boolean;
  isDesktop: boolean | null;
};

export const TopNavigation: React.FC<Props> = ({ isDesktop, showSidebar, toggleSidebar }) => {
  return (
    <Flex
      position="fixed"
      top="0"
      width="100%"
      zIndex="overlay"
      display={!isDesktop ? 'flex' : 'none'}
      backgroundColor="gray.50"
      boxShadow={`0px 1px 4px 0px ${customTheme.colors.gray[300]}`}
    >
      <Flex width="100%" justifyContent="space-between" alignItems="center" padding="4">
        <Flex justifyContent="center" alignItems="center">
          <NavLink to="/">
            <Logo width={100} height={40} />
          </NavLink>
        </Flex>
        <IconButton
          onClick={() => toggleSidebar(false)}
          variant="outline"
          aria-label="Toggle Menu"
          backgroundColor="gray.50"
          icon={showSidebar ? <CloseIcon /> : <HamburgerIcon />}
        />
      </Flex>
    </Flex>
  );
};
