import React from 'react';
import { Flex } from '@chakra-ui/react';
import Interior2 from 'assets/interior-2.jpg';

export const AuthBackground: React.FC = ({ children }) => {
  return (
    <Flex
      backgroundImage={Interior2}
      backgroundSize="cover"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      flexDirection="row"
      justifyContent={{ base: 'center', lg: 'flex-start' }}
      flex="1"
    >
      {children}
    </Flex>
  );
};
