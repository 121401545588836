import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { AssetProps } from 'contentful-management/dist/typings/export-types';
import { EntriesResponse } from '../services/api/types';

type Pagination = {
  currentPage: number;
  pageSize: number;
};

type Response = {
  pagination: Pagination;
  setPagination: React.Dispatch<React.SetStateAction<Pagination>>;
  ref?: (node?: Element | null | undefined) => void;
  data: EntriesResponse | AssetProps[];
  maxPages: number;
};

const defaultPageSize = 5;

export const useInfiniteScrollData = (
  data?: EntriesResponse | AssetProps[],
  disabled?: boolean,
): Response => {
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: defaultPageSize,
  });

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 1,
  });

  const { currentPage, pageSize } = pagination;

  const maxPages = data ? Math.ceil(data.length / defaultPageSize) : 1;
  const dataByPage = disabled ? data || [] : data ? data.slice(0, currentPage * pageSize) : [];

  const shouldLoadMore = !disabled && inView && Boolean(data) && currentPage < maxPages;

  useEffect(() => {
    if (shouldLoadMore) {
      setPagination((prev) => {
        return {
          ...prev,
          currentPage: (prev.currentPage += 1),
        };
      });
    }
  }, [shouldLoadMore]);

  return { pagination, setPagination, maxPages, ref, data: dataByPage };
};
