import React, { useCallback, useContext, useEffect, useReducer } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import {
  FormLabel,
  Flex,
  Text,
  Heading,
  Box,
  Textarea,
  useDisclosure,
  Button,
} from '@chakra-ui/react';
import { DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { Link } from 'contentful-management/dist/typings/common-types';
import {
  CategoryMgmtEntry,
  DefaultLocaleField,
  DefaultLocaleLinkEntriesField,
  EntryResponse,
  EntryNotFound,
  MenuMgmtEntry,
  UpdateEntryOptions,
} from 'services/api/types';
import { defaultLocale, ItemActions } from 'shared/functions';
import {
  FormInput,
  SelectItems,
  LoadingIndicator,
  DraggableItem,
  DraggableList,
} from 'shared/components';
import { useContentQueries } from 'hooks/useContentQueries';
import { FormContainer } from './FormContainer';
import { AccountsStateContext } from 'context/Accounts/AccountsContext';
import { AddEntriesModal } from './AddEntriesModal';
import { EntriesAPI } from 'services/api';
import { InfoBox } from './InfoBox';
import { ListEntryItem } from './ListEntryItem';
import { getStatusFromEntitySys } from 'shared/functions/EntityStatus';
import { ArchivedBanner } from './ArchivedBanner';
import { useHasChanges } from 'hooks/useHasChanges';
import { useAutosave } from 'hooks/useAutosave';

type FormState = {
  title?: DefaultLocaleField;
  description?: DefaultLocaleField;
  categories?: DefaultLocaleLinkEntriesField;
  orderedCategoryEntries?: Array<EntryResponse | EntryNotFound>;
  errors: FormValidationError;
};

type FormValidationError = {
  title?: string;
  description?: string;
  general?: string;
};

type FORM_ACTIONS = { type: 'update'; payload: Partial<FormState> };

const initialState: FormState = {
  title: {
    [defaultLocale]: '',
  },
  description: {
    [defaultLocale]: '',
  },
  categories: {
    [defaultLocale]: [],
  },
  orderedCategoryEntries: [],
  errors: {},
};

const reducer = (state: FormState, action: FORM_ACTIONS) => {
  switch (action.type) {
    case 'update': {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};

type FormProps = {
  menu: MenuMgmtEntry;
};

const mapMenuToState = (menu: MenuMgmtEntry) => {
  return {
    title: menu.fields.title || initialState.title,
    description: menu.fields.description || initialState.description,
    categories: menu.fields.categories || initialState.categories,
  };
};

export const MenuForm: React.FC<FormProps> = ({ menu }) => {
  const queryClient = useQueryClient();
  const { state: accountsState } = useContext(AccountsStateContext);
  const { selectedAccount } = accountsState;
  const mappedMenu = mapMenuToState(menu);
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    ...mappedMenu,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const linkedEntriesIds: string[] = [];
  if (menu.fields.categories && menu.fields.categories[defaultLocale]) {
    linkedEntriesIds.push(...menu.fields.categories[defaultLocale].map((i) => i.sys.id));
  }

  const { title, description, categories, orderedCategoryEntries, errors } = state;

  const { isLoading: loadingCategories } = useQuery(
    ['entries', linkedEntriesIds],
    () =>
      EntriesAPI.filterEntries('category', {
        accountId: selectedAccount?.id,
        ids: linkedEntriesIds.join(','),
      }),
    {
      enabled: linkedEntriesIds.length > 0,
      onSuccess: (res) => {
        // Reorder linkedEntries to state items order
        const entriesNotFound = linkedEntriesIds.filter((entryId) => {
          return res.findIndex((item) => item.sys.id === entryId) < 0;
        });

        const orderedRes = ItemActions.sortItems(
          [...res, ...entriesNotFound.map((id) => ({ sys: { id } }))],
          linkedEntriesIds,
        );

        dispatch({
          type: 'update',
          payload: {
            orderedCategoryEntries: orderedRes,
          },
        });
      },
    },
  );

  const { hasChanges } = useHasChanges({ ...mappedMenu, orderedCategoryEntries }, state);

  useEffect(() => {
    if (menu) {
      dispatch({
        type: 'update',
        payload: {
          ...mapMenuToState(menu),
          ...((!menu.fields.categories || menu.fields.categories[defaultLocale].length === 0) && {
            orderedCategoryEntries: [],
          }),
        },
      });
    }
  }, [menu]);

  const { mutateSave, saveLoading } = useContentQueries('menu', (res) => {
    console.log(`🚀 => const{mutateSave}=useContentQueries => res`, res);
    onActionSuccess();
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    dispatch({
      type: 'update',
      payload: {
        [name]: {
          [defaultLocale]: value,
        },
        errors: {
          ...state.errors,
          [name]: undefined,
        },
      },
    });
  };

  const onClickSaveChanges = useCallback(async () => {
    const { title, description, categories } = state;

    const updateOptions: UpdateEntryOptions = {
      ...menu.fields,
      title,
      description,
      categories,
    };
    console.log(`🚀 => onClickSaveChanges => updateOptions`, updateOptions);
    mutateSave({ entryId: menu.sys.id, options: updateOptions });
  }, [state, mutateSave]);

  useAutosave({ hasChanges, handleSave: onClickSaveChanges });

  const onSuccessInsertEntries = () => {
    onActionSuccess();
    onClose();
  };

  const onClickSelectEntries = () => {
    if (hasChanges) {
      onClickSaveChanges();
    }
    onOpen();
  };

  const onClickRemoveLink = (id: string) => {
    if (menu.fields.categories) {
      const { title, description } = state;
      const newItems = menu.fields.categories[defaultLocale].slice();
      const foundIndex = newItems.findIndex((i) => i.sys.id === id);
      if (foundIndex > -1) {
        newItems.splice(foundIndex, 1);

        const updateOptions: UpdateEntryOptions = {
          ...menu.fields,
          ...(hasChanges && {
            title,
            description,
          }),
          categories: {
            [defaultLocale]: newItems,
          },
        };
        console.log(`🚀 => onClickRemoveLink => updateOptions`, updateOptions);
        mutateSave({ entryId: menu.sys.id, options: updateOptions });
      }
    }
  };

  const onActionSuccess = () => {
    queryClient.refetchQueries(['entryById', menu.sys.id]);
    queryClient.refetchQueries([`menu`, `${selectedAccount ? selectedAccount.account_name : ''}`]);
  };

  const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    console.log(`🚀 => onDragEnd => result`, result, provided);
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }

    if (categories && orderedCategoryEntries) {
      const reorderedEntries = ItemActions.reorderItems<CategoryMgmtEntry | EntryNotFound>(
        orderedCategoryEntries || [],
        source.index,
        destination.index,
      );

      const reorderedCategories = ItemActions.reorderItems<Link<'Entry'>>(
        categories[defaultLocale],
        source.index,
        destination.index,
      );
      console.log(`🚀 => onDragEnd => items`, reorderedEntries);

      dispatch({
        type: 'update',
        payload: {
          orderedCategoryEntries: reorderedEntries,
          categories: {
            [defaultLocale]: reorderedCategories,
          },
        },
      });
    }
  };

  const itemStatus = getStatusFromEntitySys(menu.sys);
  const formIsDisabled = itemStatus === 'ARCHIVED';

  return (
    <Flex flex="1" flexDirection="column" marginTop="4" alignItems="flex-start">
      <Flex alignItems="center" justifyContent="space-between" width="100%">
        <Box>
          <Text>MENU</Text>

          <Heading wordBreak="break-all" fontSize={{ base: 'xl', sm: '2xl' }}>
            {title && title[defaultLocale].length > 0 ? title[defaultLocale] : 'Untitled'}
          </Heading>
        </Box>

        <Button
          marginLeft="4"
          onClick={onClickSaveChanges}
          variant="primary"
          isLoading={saveLoading}
          isDisabled={formIsDisabled}
        >
          {'Save Changes'}
        </Button>
      </Flex>
      <Flex
        width="100%"
        alignItems="flex-start"
        flexWrap="wrap"
        flexDirection={{ base: 'column', md: 'row' }}
      >
        <InfoBox hasChanges={hasChanges} item={menu} onActionSuccess={onActionSuccess} />
        <FormContainer>
          {formIsDisabled && <ArchivedBanner />}
          <Flex
            borderLeft="3px solid black"
            borderColor="blue.100"
            paddingLeft="4"
            marginBottom="4"
          >
            <FormInput
              label="Menu Title (required)"
              name="title"
              id="menuTitle"
              value={title ? title[defaultLocale] : ''}
              onChange={onChange}
              error={errors.title}
              placeholder="Menu title eg. Dinner or Brunch or Drinks"
              disabled={formIsDisabled}
            />
          </Flex>
          <Flex
            flexDirection="column"
            borderLeft="3px solid black"
            borderColor="blue.100"
            paddingLeft="4"
            marginBottom="4"
          >
            <FormLabel>Menu Description</FormLabel>
            <Textarea
              label="Menu Description"
              name="description"
              value={description ? description[defaultLocale] : ''}
              onChange={onChange}
              error={errors.description}
              placeholder="Enter description of menu"
              required={false}
              borderColor="gray.200"
              isDisabled={formIsDisabled}
            />
          </Flex>

          <Flex
            flexDirection="column"
            borderLeft="3px solid black"
            borderColor="blue.100"
            paddingLeft="4"
          >
            <FormLabel>Categories (required)</FormLabel>

            <DraggableList isDropDisabled={false} droppableId="category" onDragEnd={onDragEnd}>
              {loadingCategories ? (
                <Flex marginBottom="6" justifyContent="center">
                  <LoadingIndicator />
                </Flex>
              ) : (
                <>
                  {orderedCategoryEntries &&
                    (orderedCategoryEntries as CategoryMgmtEntry[]).map((item, index) => {
                      const { sys } = item;
                      return (
                        <DraggableItem
                          key={`${sys.id}-${index}`}
                          draggableId={item.sys.id}
                          index={index}
                          isDragDisabled={formIsDisabled}
                        >
                          <ListEntryItem
                            entry={item}
                            contentTypeId="category"
                            onClickRemove={onClickRemoveLink}
                            isDisabled={saveLoading}
                          />
                        </DraggableItem>
                      );
                    })}
                </>
              )}
            </DraggableList>
            <SelectItems
              disabled={formIsDisabled}
              title="Select Items"
              onClick={onClickSelectEntries}
            />
          </Flex>
        </FormContainer>
      </Flex>
      {isOpen && (
        <AddEntriesModal
          entry={menu}
          isOpen={isOpen}
          onClose={onClose}
          contentTypeId="category"
          entryTypeId="menu"
          onSuccess={onSuccessInsertEntries}
          filterOutList={orderedCategoryEntries}
        />
      )}
    </Flex>
  );
};
