import { get, post, put } from './helpers';
import queryString from 'querystring';
import {
  ContentType,
  EntryFilters,
  EntriesResponse,
  EntryResponse,
  CreateEntryOptions,
  UpdateEntryOptions,
} from './types';

export const filterAllEntries = async (queryObj: EntryFilters): Promise<EntriesResponse> => {
  const query = queryString.stringify(queryObj);
  const response = await get<EntriesResponse>(`/entries/?${query}`);

  return response;
};

export const filterEntries = async (
  contentType: ContentType,
  queryObj: EntryFilters,
): Promise<EntriesResponse> => {
  const query = queryString.stringify(queryObj);
  const response = await get<EntriesResponse>(`/entries/${contentType}?${query}`);

  return response;
};

export const getSingleEntry = async (entryId: string): Promise<EntryResponse> => {
  const response = await get<EntryResponse>(`/entries/${entryId}/single`);

  return response;
};

export const createEntry = async (
  contentType: ContentType,
  options: CreateEntryOptions,
): Promise<EntryResponse> => {
  const response = await post<EntryResponse>(`/entries/${contentType}`, options);

  return response;
};

export const updateEntry = async (
  entryId: string,
  options: UpdateEntryOptions,
): Promise<EntryResponse> => {
  const response = await put<EntryResponse>(`/entries/${entryId}`, options);

  return response;
};

export const publishEntry = async (entryId: string): Promise<EntryResponse> => {
  const response = await post<EntryResponse>(`/entries/${entryId}/publish`, undefined);

  return response;
};

export const unpublishEntry = async (entryId: string): Promise<EntryResponse> => {
  const response = await post<EntryResponse>(`/entries/${entryId}/unpublish`, undefined);

  return response;
};

export const archiveEntry = async (entryId: string): Promise<EntryResponse> => {
  const response = await post<EntryResponse>(`/entries/${entryId}/archive`, undefined);

  return response;
};

export const unarchiveEntry = async (entryId: string): Promise<EntryResponse> => {
  const response = await post<EntryResponse>(`/entries/${entryId}/unarchive`, undefined);

  return response;
};

export const deleteEntry = async (entryId: string): Promise<EntryResponse> => {
  const response = await post<EntryResponse>(`/entries/${entryId}/delete`, undefined);

  return response;
};
