import axios from 'axios';
import { firebase } from 'services/firebase';
import { setupInterceptorsTo } from './interceptors';
import { API_URL } from './config';
import { ApiPatchOptions, ApiPostOptions, RequestErrorType } from './types';
const { auth } = firebase;
setupInterceptorsTo(axios);

export class RequestError implements RequestErrorType {
  status: number;
  message: string;
  name: string;
  constructor(status: number, message: string) {
    this.status = status;
    this.message = message;
    this.name = 'Request Error';
  }
}

export const getToken = (): Promise<string> | string => {
  const currentUser = auth().currentUser;
  if (currentUser) {
    return currentUser.getIdToken();
  }
  return '';
};

const handleRequestError = (error: unknown) => {
  if (axios.isAxiosError(error)) {
    if (error.response) {
      const { data } = error.response;
      throw new RequestError(data.statusCode, data.message);
    } else if (error.request) {
      console.log('error.request :>> ', error.request);
      throw new RequestError(500, 'Request Failed!');
    }
  }
  throw new RequestError(500, 'Something went wrong!');
};

export const post = async <T>(
  url: string,
  data: ApiPostOptions | FormData,
  headers?: Record<string, string>,
): Promise<T> => {
  const token = await getToken();
  try {
    const response = await axios.post<T>(url, data, {
      baseURL: API_URL,
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
        ...(headers || {}),
      },
    });

    return response.data;
  } catch (error) {
    return handleRequestError(error);
  }
};

export const get = async <T>(url: string): Promise<T> => {
  const token = await getToken();
  // console.log('token :>> ', token);
  try {
    const response = await axios.get<T>(url, {
      baseURL: API_URL,
      headers: {
        Authorization: token,
      },
    });

    return response.data;
  } catch (error) {
    return handleRequestError(error);
  }
};

export const del = async <T>(url: string, data?: ApiPostOptions): Promise<T> => {
  const token = await getToken();
  try {
    const response = await axios.delete<T>(url, {
      baseURL: API_URL,
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data,
    });

    return response.data;
  } catch (error) {
    return handleRequestError(error);
  }
};

export const patch = async <T>(url: string, data: ApiPatchOptions): Promise<T> => {
  const token = await getToken();
  try {
    const response = await axios.patch<T>(url, data, {
      baseURL: API_URL,
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });

    return response.data;
  } catch (error) {
    return handleRequestError(error);
  }
};

export const put = async <T>(url: string, data: ApiPatchOptions): Promise<T> => {
  const token = await getToken();
  try {
    const response = await axios.put<T>(url, data, {
      baseURL: API_URL,
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });

    return response.data;
  } catch (error) {
    return handleRequestError(error);
  }
};
