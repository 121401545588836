import { Account } from 'services/api/types';
import {
  FetchingAccountsAction,
  FetchingAccountsFailAction,
  FetchingAccountsSuccessAction,
  ResetAccountsStateAction,
  SetSelectedAccountAction,
} from './types';

export const fetchingAccountsAction = (): FetchingAccountsAction => {
  return {
    type: 'FETCHING_ACCOUNTS',
  };
};

export const setSelectedAccountAction = (selectedAccount: Account): SetSelectedAccountAction => {
  return {
    type: 'SET_SELECTED_ACCOUNT',
    selectedAccount: selectedAccount,
  };
};

export const fetchingAccountsSuccessAction = (): FetchingAccountsSuccessAction => {
  return {
    type: 'FETCHING_ACCOUNTS_SUCCESS',
    error: undefined,
  };
};

export const fetchingAccountsFailAction = (error: Error): FetchingAccountsFailAction => {
  return {
    type: 'FETCHING_ACCOUNTS_FAIL',
    error,
  };
};

export const resetAccountsStateAction = (): ResetAccountsStateAction => {
  return {
    type: 'RESET_STATE',
  };
};
