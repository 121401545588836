import { useAccountsStateContext } from 'context/Accounts/AccountsContext';
import { useMemo } from 'react';
import { SubscriptionUtils } from 'shared/functions';

export const useIsSubscribed = (): boolean => {
  const { state: accountsState } = useAccountsStateContext();
  const { selectedAccount, isFetching: accountsFetching } = accountsState;

  const isSubscribed = useMemo(() => {
    if (!accountsFetching) {
      if (selectedAccount) {
        const { subscriptions } = selectedAccount;
        return SubscriptionUtils.isValidSubscription(subscriptions);
      }
    }
    return false;
  }, [accountsFetching, selectedAccount]);

  return isSubscribed;
};
