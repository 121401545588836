import React, { createContext, useReducer, useEffect, useContext, useState } from 'react';
import { useQuery } from 'react-query';
import {
  fetchingAccountsAction,
  fetchingAccountsFailAction,
  fetchingAccountsSuccessAction,
  resetAccountsStateAction,
  setSelectedAccountAction,
} from './AccountActions';
import { AccountsAPI, TeamsAPI } from 'services/api';
import { AccountsActionTypes, AccountsState } from './types';
import { AuthStateContext } from 'context/Auth/AuthStateContext';

const initialState: AccountsState = {
  selectedAccount: undefined,
  isFetching: true,
  error: undefined,
};

const reducer = (state: AccountsState = initialState, action: AccountsActionTypes) => {
  console.log('Accounts: ', action);

  switch (action.type) {
    case 'FETCHING_ACCOUNTS':
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };

    case 'FETCHING_ACCOUNTS_SUCCESS':
      return {
        ...state,
        isFetching: false,
        error: undefined,
      };
    case 'SET_SELECTED_ACCOUNT':
      return {
        ...state,
        selectedAccount: action.selectedAccount,
      };

    case 'FETCHING_ACCOUNTS_FAIL':
      return {
        ...state,
        accounts: undefined,
        isFetching: false,
        error: action.error,
      };

    case 'RESET_STATE':
      return { ...initialState, isFetching: false };

    default:
      return state;
  }
};

const defaultDispatch: React.Dispatch<AccountsActionTypes> = () => initialState;

export const AccountsStateContext = createContext({
  state: initialState,
  dispatch: defaultDispatch,
});

export const AccountsStateProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { state: authState } = useContext(AuthStateContext);
  const [selectedAccountId, setSelectedAccountId] = useState<string>();
  console.log(`🚀 => selectedAccountId`, selectedAccountId);
  console.log(`🚀 => AccountsState`, state);

  const { data: teams, isLoading: fetchingTeams } = useQuery(
    'teams',
    () => TeamsAPI.getUserTeams(),
    {
      enabled: !!authState.user,
      retry: false,
      onSuccess: (res) => {
        console.log(`🚀 => res`, res);
        if (!selectedAccountId && res.length > 0) {
          setSelectedAccountId(res[0].account_id);
        } else {
          dispatch(fetchingAccountsFailAction(new Error('No teams found')));
        }
      },
      onError: (error: Error) => {
        console.log(error);
        dispatch(fetchingAccountsFailAction(error));
      },
    },
  );

  const { isLoading } = useQuery(
    'accounts',
    () => AccountsAPI.getAccount(selectedAccountId || ''),
    {
      enabled: !!authState.user && !!selectedAccountId && !!(teams && teams.length > 0),
      retry: false,
      onSuccess: (res) => {
        if (!state.selectedAccount && res) {
          dispatch(setSelectedAccountAction(res));
        }
        dispatch(fetchingAccountsSuccessAction());
      },
      onError: (error: Error) => {
        dispatch(fetchingAccountsFailAction(error));
      },
    },
  );

  useEffect(() => {
    if (isLoading || fetchingTeams) {
      dispatch(fetchingAccountsAction());
    }
  }, [isLoading, fetchingTeams]);

  useEffect(() => {
    const { user, isFetching } = authState;
    if (!user && !isFetching) {
      dispatch(resetAccountsStateAction());
      setSelectedAccountId(undefined);
    }
  }, [authState]);

  return (
    <AccountsStateContext.Provider value={{ state, dispatch }}>
      {children}
    </AccountsStateContext.Provider>
  );
};

export const useAccountsStateContext = (): {
  state: AccountsState;
  dispatch: React.Dispatch<AccountsActionTypes>;
} => {
  return useContext(AccountsStateContext);
};

export const AccountsStateConsumer = AccountsStateContext.Consumer;
