/* eslint-disable @typescript-eslint/no-explicit-any */
import { post } from './helpers';
import { CreateUploadOptions } from './types';

export const uploadAsset = async (options: CreateUploadOptions): Promise<any> => {
  const formData = new FormData();

  formData.append('file', options.file);

  const response = await post<any>(`/uploads`, formData, {
    'Content-Type': 'multipart/form-data',
  });

  return response;
};
