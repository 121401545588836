import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';

const config = {
  apiKey: 'AIzaSyDfAKVKFiRM9YjzP103oGAEYhl74Ha8JX8',
  authDomain: 'visual-menu-app.firebaseapp.com',
  projectId: 'visual-menu-app',
  storageBucket: 'visual-menu-app.appspot.com',
  messagingSenderId: '326126000140',
  appId: '1:326126000140:web:44f965b2329be1793ef629',
  measurementId: 'G-L8VJ9JPL93',
};

firebase.initializeApp(config);

firebase.auth();
if (process.env.NODE_ENV === 'production') {
  firebase.analytics();
}

export default firebase;
