export type StorageObj = {
  [key: string]: string;
};

export type StorageValue = StorageObj | string | number | null;
export type LocalStorageResponse = {
  storedValue: StorageValue;
  setValue: (value: StorageValue) => void;
};

/**
 * Access item directly, in cases where state is not updated in time.
 */
export const getItem = (key: string, initialValue: StorageValue): StorageValue => {
  try {
    // Get from local storage by key
    const item = window.localStorage.getItem(key);
    // Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : initialValue;
  } catch (error) {
    // If error also return initialValue
    console.log(error);
    return initialValue;
  }
};

/**
 * Remove Item from localStorage given key. Clears from state as well.
 */
export const removeItem = (key: string): void => {
  window.localStorage.removeItem(key);
};
