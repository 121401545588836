import React, { useContext } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Flex } from '@chakra-ui/react';
import { CategoryMgmtEntry, RequestErrorType } from 'services/api/types';
import { EntriesAPI } from 'services/api';
import { CategoryForm } from './components/CategoryForm';
import { BackButton } from 'shared/components';
import { AccountsStateContext } from 'context/Accounts/AccountsContext';

type Params = {
  categoryId: string;
};

type LocationState = {
  category?: CategoryMgmtEntry;
};

export const Category: React.FC = () => {
  const history = useHistory();
  const { state: accountsState } = useContext(AccountsStateContext);
  const { selectedAccount } = accountsState;
  const { state: locationState = {} } = useLocation<LocationState>();
  const { categoryId = '' } = useParams<Params>();

  const { data: category, isLoading } = useQuery(
    ['entryById', categoryId],
    () => EntriesAPI.getSingleEntry(categoryId),
    {
      initialData: locationState.category,
      enabled: !!selectedAccount,
      retry: 0,
      onError: (error: RequestErrorType) => {
        if (error.status && error.status === 404) {
          history.push(`/store/content/${selectedAccount?.id}/category`);
        }
      },
    },
  );

  return (
    <Flex flex="1" flexDirection="column" maxWidth="100%" paddingX={{ base: '4', sm: 0 }}>
      <BackButton />
      {!isLoading && <CategoryForm category={category as CategoryMgmtEntry} />}
    </Flex>
  );
};
