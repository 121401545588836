import React, { useContext } from 'react';
import {
  Flex,
  Heading,
  Button,
  useDisclosure,
  SimpleGrid,
  Image,
  Text,
  Checkbox,
  chakra,
} from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { AssetProps } from 'contentful-management/dist/typings/export-types';
import { AccountsStateContext } from 'context/Accounts/AccountsContext';
import { AssetsAPI } from 'services/api';
import { defaultLocale } from 'shared/functions';
import { LoadingIndicator, StatusLabel } from 'shared/components';
import { useSelectItems } from 'hooks/useSelectItems';
import { UploadAssetModal } from './UploadAssetModal';
import { AssetsActionRow } from './AssetsActionRow';

import { ReactComponent as EmptyListSVG } from 'assets/empty-list.svg';
import { useInfiniteScrollData } from 'hooks/useInfiniteScrollData';

const ChakraEmptyListSVG = chakra(EmptyListSVG);

export const Media: React.FC = () => {
  const { state: accountsState } = useContext(AccountsStateContext);
  const { selectedAccount } = accountsState;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    data: allMedia,
    refetch: refetchAssets,
    isLoading,
  } = useQuery(
    `media-${selectedAccount ? selectedAccount.account_name : ''}`,
    () =>
      AssetsAPI.filterAssets({
        title: selectedAccount?.id,
      }),
    {
      enabled: !!selectedAccount,
      staleTime: 5 * 60 * 1000,
    },
  );

  const { items: currentItems } = allMedia || { items: [] };
  const { selected, setSelected, onCheckItem } = useSelectItems(currentItems);

  const { data: infiniteScrollData, ref: footerRef } = useInfiniteScrollData(currentItems);
  const dataByPage = infiniteScrollData as AssetProps[];

  const onClickUploadAsset = () => {
    onOpen();
  };

  const onActionSuccess = () => {
    refetchAssets();
    setSelected([]);
  };

  return (
    <Flex
      maxWidth="100%"
      flex="1"
      flexDirection="column"
      padding={{ base: '4', sm: 0 }}
      paddingBottom="120px"
    >
      <Flex alignItems="center" justifyContent="space-between" width="100%" marginBottom="4">
        <Heading>Media</Heading>
        <Button onClick={onClickUploadAsset} variant="primary" isLoading={false}>
          Upload Media
        </Button>
      </Flex>
      {isLoading ? (
        <Flex justifyContent="center" alignItems="center" flex="1">
          <LoadingIndicator />
        </Flex>
      ) : (
        <>
          {selected.length > 0 && (
            <AssetsActionRow
              onActionSuccess={onActionSuccess}
              data={currentItems}
              onCheckItem={onCheckItem}
              selected={selected}
            />
          )}
          {!currentItems || currentItems.length === 0 ? (
            <Flex
              marginTop="8"
              flex="1"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <ChakraEmptyListSVG height={['275px', '400px']} width={['275px', '400px']} />
              <Heading size="lg" marginTop="8">
                Nothing here yet!
              </Heading>
            </Flex>
          ) : (
            <>
              <SimpleGrid
                marginTop="10"
                spacingY="10"
                minChildWidth="300px"
                spacingX="8"
                width="100%"
              >
                {dataByPage.map((mediaItem) => {
                  const isChecked = !!selected.find((i) => i === mediaItem.sys.id);
                  return (
                    <MediaItem
                      key={mediaItem.sys.id}
                      mediaFile={mediaItem}
                      isChecked={isChecked}
                      onCheck={onCheckItem}
                    />
                  );
                })}
              </SimpleGrid>
              <Flex height={10} width={10} marginTop="8" ref={footerRef} />
            </>
          )}
        </>
      )}
      {isOpen && <UploadAssetModal isOpen={isOpen} onClose={onClose} />}
    </Flex>
  );
};

type MediaItemProps = {
  mediaFile: AssetProps;
  isChecked: boolean;
  onCheck: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const MediaItem: React.FC<MediaItemProps> = ({ mediaFile, isChecked, onCheck }) => {
  const { state: accountsState } = useContext(AccountsStateContext);
  const { selectedAccount } = accountsState;

  const { fields, sys } = mediaFile;
  const { title, description, file } = fields;

  return (
    <Flex
      flexDirection="column"
      maxWidth={['100%', '400px']}
      backgroundColor="gray.50"
      borderRadius="12px"
      boxShadow="lg"
      position="relative"
    >
      <Flex
        position="absolute"
        left="8px"
        top="8px"
        padding="1"
        borderRadius="md"
        background={isChecked ? 'gray.300' : 'gray.50'}
        border="1px solid transparent"
      >
        <Checkbox id={sys.id} size="lg" isChecked={isChecked} onChange={onCheck} />
      </Flex>
      <Image
        borderTopRadius="12px"
        src={file[defaultLocale].url}
        width={['100%', '400px']}
        height={['250px', '325px']}
        objectFit="cover"
      />
      <Flex>
        <Flex flexDirection="column" padding="4" flex="1" justifyContent="flex-start">
          <Text fontWeight="semibold">
            {title[defaultLocale].replace(`${selectedAccount?.id}_`, '')}
          </Text>
          <Text color="gray.500">
            {description && description[defaultLocale] ? description[defaultLocale] : '-'}
          </Text>
          <Text color="gray.500">{file[defaultLocale].contentType}</Text>
        </Flex>
        <Flex flexDirection="column" padding="4" justifyContent="space-between">
          <StatusLabel sys={sys} />
          <Text fontWeight="thin" textAlign="right">
            IMAGE
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
