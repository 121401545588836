import React from 'react';
import { Text, Box } from '@chakra-ui/react';

type Props = {
  title: string;
  isSelected: boolean;
  onClick: () => void;
};

export const Tag: React.FC<Props> = ({ title, onClick, isSelected }) => {
  return (
    <Box
      onClick={onClick}
      background={isSelected ? 'white' : 'none'}
      boxShadow={isSelected ? 'md' : 'none'}
      _hover={{ background: 'white', cursor: 'pointer' }}
      borderRadius={4}
      paddingX={4}
      paddingY={2}
      alignSelf={'stretch'}
      whiteSpace="nowrap"
    >
      <Text fontWeight={'semibold'}>{title}</Text>
    </Box>
  );
};
