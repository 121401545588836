import { User } from 'context/Auth/types';
import { get, patch, post } from './helpers';
import { CreateUserOptions, UpdateUserOptions, UserResponse } from './types';

export const transformUserResponseToUser = (user: UserResponse): User => {
  return {
    id: user.id,
    email: user.email,
    firstName: user.first_name,
    lastName: user.last_name,
    profilePicture: user.profile_picture,
  };
};

export const createUser = async (data: CreateUserOptions): Promise<User> => {
  const response = await post<UserResponse>(`/users`, data);
  const user = transformUserResponseToUser(response);
  return user;
};

export const getUser = async (): Promise<User> => {
  const response = await get<UserResponse>(`/users`);
  console.log('response', response);
  const user = transformUserResponseToUser(response);
  return user;
};

export const updateUser = async (data: UpdateUserOptions): Promise<User> => {
  const response = await patch<UserResponse>(`/users`, data);
  const user = transformUserResponseToUser(response);
  return user;
};
