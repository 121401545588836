import React from 'react';
import { Button } from '@chakra-ui/react';
import { FcGoogle } from 'react-icons/fc';

type Props = {
  onClick?: () => void;
  isLoading?: boolean;
};

export const ContinueWithGoogleBtn: React.FC<Props> = ({ onClick, isLoading }) => {
  return (
    <Button
      leftIcon={<FcGoogle width="30px" height="30px" />}
      backgroundColor="#FFFFFF"
      width="100%"
      color="gray.800"
      variant="outline"
      fontFamily="Roboto, sans-serif"
      onClick={onClick}
      isLoading={isLoading}
      isDisabled={isLoading}
    >
      Continue with Google
    </Button>
  );
};
