import { useEffect, useState } from 'react';

const stateObjectHash = {
  objectHash: (obj: Record<string, unknown>) => JSON.stringify(obj),
  arrays: {
    detectMove: true,
  },
  textDiff: {
    // default 60, minimum string length (left and right sides) to use text diff algorythm: google-diff-match-patch
    minLength: 5000,
  },
};
// eslint-disable-next-line @typescript-eslint/no-var-requires
const jsonDiff = require('jsondiffpatch');

type Response = {
  hasChanges: boolean;
};

export const useHasChanges = (
  state: Record<string, unknown>,
  editState: Record<string, unknown>,
): Response => {
  const [hasChanges, setHasChanges] = useState(false);
  useEffect(() => {
    // Compare state & editState
    // console.log(`state, editState`, state, editState);
    const stateDiff = jsonDiff.create(stateObjectHash).diff(state, editState);
    // Not include errors
    if (stateDiff && stateDiff.errors) {
      delete stateDiff.errors;
    }
    if (stateDiff && Object.keys(stateDiff).length > 0) {
      console.log(`🚀 => useEffect => stateDiff`, stateDiff);

      if (!hasChanges) {
        setHasChanges(true);
      }
    } else {
      if (hasChanges) {
        setHasChanges(false);
      }
    }
  }, [state, editState, hasChanges]);

  return { hasChanges };
};
