import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { AuthLayout, DefaultLayout } from 'containers';
import { AuthedRoute } from './AuthedRoute';
import { authRoutes } from './routes';

export const Router: React.FC = () => {
  return (
    <Switch>
      {authRoutes.map(({ path, name, exact }) => (
        <Route path={path} key={name} component={AuthLayout} exact={exact} />
      ))}

      <AuthedRoute path="/" name="Default" component={DefaultLayout} />
    </Switch>
  );
};
