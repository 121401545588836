import React from 'react';
import { AccountsStateProvider } from './Accounts/AccountsContext';
import { AuthStateProvider } from './Auth/AuthStateContext';

export const ContextProvider: React.FC = ({ children }) => {
  return (
    <AuthStateProvider>
      <AccountsStateProvider>{children}</AccountsStateProvider>
    </AuthStateProvider>
  );
};

export default ContextProvider;
