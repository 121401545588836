import React, { useEffect, useReducer } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Flex, Heading, Text, Button, Center } from '@chakra-ui/react';
import {
  AuthBackground,
  FormInput,
  Logo,
  LoadingIndicator,
  PasswordValidations,
} from 'shared/components';
import { Validations } from 'shared/functions';
import { FirebaseAuth, firebase } from 'services/firebase';

type FormState = {
  email: string;
  password: string;
  confirmPassword: string;
  invalidMessage: string;
  loading: boolean;
  showSuccess: boolean;
  errors: FormValidationError;
};

type FormValidationError = {
  email?: string;
  password?: string;
  confirmPassword?: string;
  general?: string;
};

type FORM_ACTIONS = { type: 'update'; payload: Partial<FormState> };

const initialState: FormState = {
  email: '',
  password: '',
  confirmPassword: '',
  invalidMessage: '',
  loading: false,
  showSuccess: false,
  errors: {},
};

const reducer = (state: FormState, action: FORM_ACTIONS) => {
  switch (action.type) {
    case 'update': {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};

export const ResetPassword: React.FC = () => {
  const location = useLocation();
  const [state, dispatch] = useReducer(reducer, initialState);
  const parsedLocation = queryString.parse(location.search);
  const { oobCode, continueUrl } = parsedLocation;

  const { email, password, confirmPassword, invalidMessage, loading, errors, showSuccess } = state;

  useEffect(() => {
    const verifyCode = async (code: string) => {
      try {
        console.log('oobCode :>> ', code);
        // returns user's email address if valid
        const result = await FirebaseAuth.verifyPasswordResetCode(code);
        dispatch({
          type: 'update',
          payload: {
            email: result,
          },
        });
      } catch (error: unknown) {
        const authError = error as firebase.auth.Error;
        if (authError.message) {
          dispatch({
            type: 'update',
            payload: {
              invalidMessage: authError.message,
            },
          });
        }
      }
    };
    if (oobCode) {
      verifyCode(oobCode as string);
    } else {
      dispatch({
        type: 'update',
        payload: {
          invalidMessage:
            'The action code is invalid. This can happen if the code is malformed, expired, or has already been used.',
        },
      });
    }
  }, [oobCode]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    dispatch({
      type: 'update',
      payload: {
        [name]: value,
        errors: {
          [name]: undefined,
        },
      },
    });
  };

  const onClickReset = async () => {
    console.log('state :>> ', state);

    dispatch({
      type: 'update',
      payload: {
        loading: true,
      },
    });

    if (confirmPassword !== password) {
      dispatch({
        type: 'update',
        payload: {
          loading: false,
          errors: {
            confirmPassword: 'Passwords do not match',
          },
        },
      });
      return;
    }

    const required = { password, confirmPassword };
    const newErrors = Validations.validateForm(required);
    if (Object.keys(newErrors).length > 0) {
      dispatch({
        type: 'update',
        payload: {
          loading: false,
          errors: newErrors,
        },
      });

      return;
    }

    try {
      await FirebaseAuth.confirmPasswordReset(oobCode as string, password);
      dispatch({
        type: 'update',
        payload: {
          loading: false,
          showSuccess: true,
        },
      });
    } catch (error) {
      const authError = error as firebase.auth.Error;
      dispatch({
        type: 'update',
        payload: {
          loading: false,
          errors: {
            general: authError.message,
          },
        },
      });
    }
  };

  /**
   * Navigate to continue url or default to welcome screen
   * Deep links should pick up link on mobile device and ask to open in mobile app if installed
   */
  const onClickContinue = () => {
    if (continueUrl) {
      window.location.replace(continueUrl as string);
    } else {
      window.location.replace('/login');
    }
  };

  const isDisabled = password.length === 0 || confirmPassword.length === 0;

  return (
    <AuthBackground>
      <Flex
        maxWidth="500px"
        flexDirection="column"
        flex="1"
        justifyContent="flex-start"
        borderRadius="10px"
        padding={['6', '8']}
        overflowY="auto"
        backgroundColor="white"
        margin={[0, '4']}
        position="relative"
      >
        {loading && (
          <Center
            position="absolute"
            height="100%"
            width="100%"
            top="0"
            left="0"
            backgroundColor="blackAlpha.400"
            zIndex="overlay"
            borderRadius="10px"
          >
            <LoadingIndicator />
          </Center>
        )}

        <Flex marginBottom="8">
          <Logo />
        </Flex>
        <Heading fontSize="3xl" fontWeight="black" mb="4">
          PASSWORD RESET
        </Heading>
        {invalidMessage ? (
          <>
            <Text marginTop="4" fontWeight="semibold" fontSize="xl">
              {invalidMessage}
            </Text>
          </>
        ) : showSuccess ? (
          <>
            <Text marginTop="4" fontWeight="semibold" fontSize="xl">
              Password successfully reset.
            </Text>
            <Button onClick={onClickContinue} variant="primary" marginTop="8" width="100%">
              Continue to login
            </Button>
          </>
        ) : (
          <>
            <FormInput
              label="Email Address"
              name="email"
              value={email}
              onChange={onChange}
              error={errors.email}
              placeholder="Enter your email address"
              type="email"
              disabled
            />

            <FormInput
              label="Password"
              name="password"
              value={password}
              onChange={onChange}
              error={errors.password}
              placeholder="Enter Password"
              type="password"
            />
            <PasswordValidations password={password} />
            <FormInput
              label="Confirm Password"
              name="confirmPassword"
              value={confirmPassword}
              onChange={onChange}
              error={errors.confirmPassword}
              placeholder="Confirm Password"
              type="password"
            />

            {errors.general && (
              <Text textAlign="center" color="red.500" fontWeight="semibold">
                {errors.general}
              </Text>
            )}

            <Button
              onClick={onClickReset}
              isDisabled={loading || isDisabled}
              marginTop="8"
              width="100%"
              variant="primary"
            >
              Reset Password
            </Button>
          </>
        )}
      </Flex>
    </AuthBackground>
  );
};
