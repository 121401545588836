import React, { useContext } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Flex } from '@chakra-ui/react';
import { MenuItemMgmtEntry, RequestErrorType } from 'services/api/types';
import { EntriesAPI } from 'services/api';
import { MenuItemForm } from './components/MenuItemForm';
import { BackButton } from 'shared/components';
import { AccountsStateContext } from 'context/Accounts/AccountsContext';

type Params = {
  itemId: string;
};

type LocationState = {
  menuItem?: MenuItemMgmtEntry;
};

export const MenuItem: React.FC = () => {
  const history = useHistory();
  const { state: accountsState } = useContext(AccountsStateContext);
  const { selectedAccount } = accountsState;
  const { state: locationState = {} } = useLocation<LocationState>();
  const { itemId = '' } = useParams<Params>();

  const { data: menuItem, isLoading } = useQuery(
    ['entryById', itemId],
    () => EntriesAPI.getSingleEntry(itemId),
    {
      initialData: locationState.menuItem,
      enabled: !!selectedAccount,
      retry: 0,
      onError: (error: RequestErrorType) => {
        if (error.status && error.status === 404) {
          history.push(`/store/content/${selectedAccount?.id}/menuItem`);
        }
      },
    },
  );

  return (
    <Flex flex="1" flexDirection="column" maxWidth="100%" paddingX={{ base: '4', sm: 0 }}>
      <BackButton />
      {!isLoading && <MenuItemForm menuItem={menuItem as MenuItemMgmtEntry} />}
    </Flex>
  );
};
