import queryString from 'query-string';
import { get } from './helpers';
import { QueryAnalyticsOptions, QueryAnalyticsResponse } from './types';

export const queryAnalytics = async (
  options: QueryAnalyticsOptions,
): Promise<QueryAnalyticsResponse> => {
  const response = await get<QueryAnalyticsResponse>(
    `/analytics?${queryString.stringify(options)}`,
  );
  return response;
};
