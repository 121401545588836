import { useToast } from '@chakra-ui/react';
import { UseMutateAsyncFunction, useMutation } from 'react-query';
import { EntriesAPI } from 'services/api';
import { RequestErrorType } from 'services/api/types';
import { ContentType, CreateEntryOptions, EntryResponse, UpdateOptions } from 'services/api/types';

type ContentQueries = {
  mutateCreate: UseMutateAsyncFunction<EntryResponse, unknown, CreateEntryOptions, unknown>;
  createLoading: boolean;
  mutateSave: UseMutateAsyncFunction<EntryResponse, unknown, UpdateOptions, unknown>;
  saveLoading: boolean;
};

export const useContentQueries = (
  key: ContentType,
  onSuccess: (res: EntryResponse) => void,
): ContentQueries => {
  const toast = useToast({ duration: 2000, position: 'bottom' });
  const { mutateAsync: mutateCreate, isLoading: createLoading } = useMutation(
    (createOptions: CreateEntryOptions) => EntriesAPI.createEntry(key, createOptions),
    {
      onSuccess: (data) => {
        console.log(`🚀 => onSuccess:`, data);
        onSuccess(data);
      },
      onError: (error: RequestErrorType) => {
        toast({
          title: error.message,
          status: 'error',
        });
      },
    },
  );

  const { mutateAsync: mutateSave, isLoading: saveLoading } = useMutation(
    (updateOptions: UpdateOptions) =>
      EntriesAPI.updateEntry(updateOptions.entryId, updateOptions.options),
    {
      onSuccess: (data) => {
        console.log(`🚀 => onSuccess:`, data);
        onSuccess(data);
      },
      onError: (error: RequestErrorType) => {
        toast({
          title: error.message,
          status: 'error',
        });
      },
    },
  );

  return { mutateCreate, createLoading, mutateSave, saveLoading };
};
