import React, { useCallback, useContext } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { NavLink, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { sideNavRoutes, RouteType } from 'router/routes';
import { Logo } from 'shared/components';
import { AccountsStateContext } from 'context/Accounts/AccountsContext';
import customTheme from 'styles/theme';

type SideNavigationProps = {
  toggleSidebar: (forceHide: boolean) => void;
  isDesktop: boolean | null;
};

export const SideNavigation: React.FC<SideNavigationProps> = ({ isDesktop, toggleSidebar }) => {
  const history = useHistory();
  const { state: accountsState } = useContext(AccountsStateContext);
  const { selectedAccount } = accountsState;

  const onClickLink = useCallback(
    (path) => {
      history.push(path);
      if (!isDesktop) {
        // hideSidebar onClick since it takes up full width
        toggleSidebar(true);
      }
    },
    [isDesktop, toggleSidebar],
  );

  const onClickSubRouteContentType = (typeId: string) => {
    if (selectedAccount) {
      onClickLink(`/store/content/${selectedAccount.id}/${typeId}`);
    }
  };

  return (
    <Flex
      flexDirection="column"
      minWidth="60"
      width="60"
      height={{ base: '100%', md: 'auto' }}
      maxHeight="100vh"
      backgroundColor="gray.50"
      borderRadius={{ base: 0, md: '20px' }}
      paddingY="6"
      margin={{ base: 0, md: '4' }}
      boxShadow={`0px 2px 4px 0px ${customTheme.colors.gray[300]}`}
      overflowY="auto"
      marginTop={{ base: 0, md: '4' }}
      zIndex="overlay"
    >
      {isDesktop && (
        <Flex justifyContent="center" alignItems="center">
          <NavLink to="/">
            <Logo width={120} height={50} />
          </NavLink>
        </Flex>
      )}

      <Flex
        flexDirection="column"
        alignItems="flex-start"
        fontSize="lg"
        paddingY="2"
        paddingLeft="8"
        paddingRight="4"
        marginTop={{ base: '2', md: '8' }}
        color="gray.700"
        border="2px solid transparent"
        backgroundColor="gray.100"
      >
        <Text fontSize="xs" fontWeight="medium">
          ACCOUNT
        </Text>
        <Text fontWeight="bold" wordBreak="break-word">
          {selectedAccount ? selectedAccount.account_name : ''}
        </Text>
      </Flex>

      <Flex flexDirection="column" marginTop="8" paddingX="4">
        {sideNavRoutes.map((route) => {
          return (
            <SideNavLink
              key={route.path}
              route={route}
              onClick={onClickLink}
              onClickSubRouteContentType={onClickSubRouteContentType}
            />
          );
        })}
      </Flex>
    </Flex>
  );
};

type SideNavLinkProps = {
  route: RouteType;
  onClick: (path: string) => void;
  onClickSubRouteContentType: (typeId: string) => void;
};

const SideNavLink: React.FC<SideNavLinkProps> = ({
  route,
  onClick,
  onClickSubRouteContentType,
}) => {
  const { icon: Icon, name, path, subRoutes } = route;
  const matches = useRouteMatch(path) && !subRoutes;
  const location = useLocation();

  return (
    <Flex
      flexDirection="column"
      borderRadius="8px"
      border={`2px solid transparent`}
      marginBottom="2"
      _hover={{
        ...(!subRoutes && {
          border: `2px solid ${customTheme.colors.gray[100]}`,
          backgroundColor: customTheme.colors.gray[100],
          cursor: 'pointer',
        }),
      }}
      sx={{
        ...(matches && {
          fontWeight: 'bold',
          color: customTheme.colors.gray[700],
          border: `2px solid ${customTheme.colors.gray[100]}`,
          backgroundColor: customTheme.colors.gray[100],
        }),
        '> svg': {
          marginRight: customTheme.sizes[2],
        },
        '> svg > path': {
          fill: matches ? customTheme.colors.gray[700] : customTheme.colors.gray[500],
        },
      }}
    >
      <Flex
        flex="1"
        alignItems="center"
        onClick={!subRoutes ? () => onClick(path) : undefined}
        fontSize="lg"
        paddingY="2"
        paddingX="4"
        color="gray.500"
        textDecoration="none"
        sx={{
          ...(matches && {
            fontWeight: 'bold',
            color: customTheme.colors.gray[700],
          }),
          '> svg': {
            marginRight: customTheme.sizes[2],
          },
          '> svg > path': {
            fill: matches ? customTheme.colors.gray[700] : customTheme.colors.gray[500],
          },
        }}
      >
        {Icon ? <Icon height={30} width={30} /> : null}
        {name}
      </Flex>
      {subRoutes &&
        subRoutes.map((subRoute) => {
          const { id, title } = subRoute;
          const { pathname } = location;
          let subRouteMatches = pathname.includes(id);
          if (pathname.includes('menuItem') && id === 'menu') {
            subRouteMatches = false;
          }
          return (
            <Flex
              key={id}
              flex="1"
              alignItems="center"
              onClick={() => onClickSubRouteContentType(id)}
              fontSize="lg"
              paddingY="2"
              paddingLeft="14"
              marginBottom="2"
              color="gray.500"
              cursor="pointer"
              border="2px solid transparent"
              borderRadius="8px"
              _hover={{
                border: `2px solid ${customTheme.colors.gray[100]}`,
                backgroundColor: customTheme.colors.gray[100],
              }}
              sx={{
                textDecoration: 'none',
                ...(subRouteMatches && {
                  fontWeight: 'bold',
                  color: customTheme.colors.gray[700],
                  border: `2px solid ${customTheme.colors.gray[100]}`,
                  backgroundColor: customTheme.colors.gray[100],
                }),
              }}
            >
              {title}
            </Flex>
          );
        })}
    </Flex>
  );
};
