import React from 'react';
import { DragHandleIcon } from '@chakra-ui/icons';
import { Flex } from '@chakra-ui/react';
import { Draggable, DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd';

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
) => ({
  // styles we need to apply on draggables
  ...draggableStyle,
  marginBottom: '24px',
});

type Props = {
  index: number;
  isDragDisabled: boolean;
  draggableId: string;
};

export const DraggableItem: React.FC<Props> = ({
  children,
  index,
  isDragDisabled,
  draggableId,
}) => {
  return (
    <Draggable draggableId={draggableId} index={index} isDragDisabled={isDragDisabled}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
          <Flex flex="1" justifyContent="stretch">
            <Flex
              borderRadius="lg"
              paddingX="1"
              border="1px solid"
              borderRightRadius="0"
              borderColor={'gray.200'}
              justifyContent="center"
              alignItems="center"
              backgroundColor="gray.100"
            >
              <DragHandleIcon />
            </Flex>
            {children}
          </Flex>
        </div>
      )}
    </Draggable>
  );
};
