import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { SubscriptionsAPI } from 'services/api';
import { CreateSessionOptions, RequestErrorType } from 'services/api/types';
import { useLocalStorage } from 'hooks/useLocalStorage';

export const useCreateCheckout = () => {
  const history = useHistory();
  const { setValue } = useLocalStorage('previousPath', '');
  return useMutation(
    (options: CreateSessionOptions) => SubscriptionsAPI.createCheckoutSession(options),
    {
      onSuccess: (res) => {
        console.log(`🚀 => res`, res);
        console.log('currentLocation', history.location);
        setValue(history.location.pathname);
        window.location.replace(res.url);
      },
      onError: (error: RequestErrorType) => {
        console.log(`🚀 => error`, error);
      },
    },
  );
};
