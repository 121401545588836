import React from 'react';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  DrawerCloseButton,
  Button,
  Flex,
  useBreakpointValue,
  SlideDirection,
  useToast,
  Text,
} from '@chakra-ui/react';
import QRCode from 'qrcode.react';
import { GeneralMgmtEntry } from 'services/api/types';
import { defaultLocale } from 'shared/functions';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  general: GeneralMgmtEntry;
};

export const ViewQRCodeModal: React.FC<ModalProps> = ({ isOpen, onClose, general }) => {
  const toast = useToast({ duration: 2000, position: 'bottom' });
  const drawerSize = useBreakpointValue({ base: 'sm', md: 'lg' });
  const drawerPlacement: SlideDirection | undefined = useBreakpointValue({
    base: 'bottom',
    md: 'right',
  });

  const onClickQRCode = () => {
    const canvas = document.getElementById('store-qr') as HTMLCanvasElement;
    if (canvas) {
      const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
      const downloadLink = document.createElement('a');
      downloadLink.href = pngUrl;
      downloadLink.download = `${general.fields.slug[defaultLocale]}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } else {
      toast({
        title: 'QR code could not be found',
        status: 'error',
      });
    }
  };

  return (
    <Drawer
      isFullHeight={true}
      placement={drawerPlacement}
      isOpen={isOpen}
      onClose={onClose}
      size={drawerSize}
    >
      <DrawerOverlay />
      <DrawerContent zIndex="overlay">
        <DrawerHeader
          fontSize="xl"
          fontWeight="bold"
          borderBottom="1px solid"
          borderColor="gray.300"
        >
          View QR Code
        </DrawerHeader>
        <DrawerCloseButton />

        <DrawerBody overflowY="auto" paddingTop="8" paddingBottom="8">
          {general && general.fields.slug && (
            <Flex flexDirection="column" justifyContent="center" alignItems="center">
              <QRCode
                id="store-qr"
                value={`https://visual.menu/${general.fields.slug[defaultLocale]}`}
                size={240}
                level={'H'}
              />
              <Text marginTop="4" wordBreak="break-all" color="gray.800" fontWeight="bold">
                {`https://visual.menu/${general.fields.slug[defaultLocale]}`}
              </Text>

              <Button marginTop="4" onClick={onClickQRCode} variant="primary" alignSelf="center">
                Save QR Code
              </Button>
            </Flex>
          )}
        </DrawerBody>

        <DrawerFooter borderTop="1px solid" borderColor="gray.300">
          <Button variant="outline" onClick={onClose}>
            Close
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
