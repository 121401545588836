import React from 'react';
import { Text, Flex, IconButton, Image } from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { AssetProps } from 'contentful-management/dist/typings/export-types';
import { AssetType, EntryNotFound, isEntryNotFound } from 'services/api/types';
import { defaultLocale } from 'shared/functions';
import { StatusLabel } from 'shared/components';

type AssetItemProps = {
  asset: AssetProps | EntryNotFound;
  onClickRemove: (entryId: string) => void;
  assetType: AssetType;
  isDisabled: boolean;
};

export const ListAssetItem: React.FC<AssetItemProps> = ({
  asset,
  assetType,
  onClickRemove,
  isDisabled,
}) => {
  const { sys } = asset;

  const renderAsset = () => {
    if (isEntryNotFound(asset)) {
      return (
        <Flex padding="4">
          <Text fontWeight="semibold">ASSET IS MISSING OR INACCESSIBLE</Text>
        </Flex>
      );
    }

    return (
      <Flex padding="4" alignItems="center">
        <Image
          borderRadius="12px"
          src={(asset as AssetProps).fields.file[defaultLocale].url}
          maxWidth={'120px'}
          height="120px"
          objectFit="cover"
        />
      </Flex>
    );
  };

  return (
    <Flex
      border="1px solid"
      borderColor={'gray.200'}
      borderRadius="lg"
      flexDirection="column"
      borderLeftRadius="0"
      borderLeftColor="transparent"
      backgroundColor={'white'}
      boxShadow={'sm'}
      flex={1}
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        borderBottom="1px solid"
        borderColor="gray.200"
        paddingX="4"
        paddingY="2"
      >
        <Text>{assetType}</Text>
        <Flex alignItems="center">
          <StatusLabel sys={sys} />
          <IconButton
            variant="outline"
            aria-label="Remove"
            icon={<DeleteIcon />}
            marginLeft="8"
            onClick={() => onClickRemove(sys.id)}
            isDisabled={isDisabled}
          />
        </Flex>
      </Flex>
      {renderAsset()}
    </Flex>
  );
};
