import React from 'react';
import { Alert, AlertDescription, AlertIcon, AlertTitle, Flex } from '@chakra-ui/react';

type Props = {
  status?: 'info' | 'warning' | 'success' | 'error';
  variant?: 'subtle' | 'left-accent' | 'top-accent' | 'solid';
  title?: string;
  description?: string;
};

export const AlertBanner: React.FC<Props> = ({
  status = 'info',
  variant = 'solid',
  title,
  description,
}) => {
  return (
    <Alert borderRadius="md" status={status} variant={variant}>
      <AlertIcon />
      <Flex flexDirection="column">
        {title && <AlertTitle>{title}</AlertTitle>}
        {description && <AlertDescription>{description}</AlertDescription>}
      </Flex>
    </Alert>
  );
};
