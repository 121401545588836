import React, { useCallback, useContext, useEffect, useReducer } from 'react';
import {
  Flex,
  Text,
  Heading,
  Box,
  Button,
  FormLabel,
  useDisclosure,
  Switch,
  Tag,
} from '@chakra-ui/react';
import { useQuery, useQueryClient } from 'react-query';
import { DropResult, ResponderProvided } from 'react-beautiful-dnd';
import slugify from 'slugify';
import { AssetProps, Link } from 'contentful-management/dist/typings/export-types';
import {
  DraggableItem,
  DraggableList,
  FormInput,
  FormTextArea,
  LoadingIndicator,
  SelectItems,
} from 'shared/components';
import {
  DefaultLocaleBooleanField,
  DefaultLocaleField,
  DefaultLocaleLinkAssetsField,
  EntryNotFound,
  MenuItemMgmtEntry,
  UpdateEntryOptions,
} from 'services/api/types';
import { defaultLocale, ItemActions } from 'shared/functions';
import { AccountsStateContext } from 'context/Accounts/AccountsContext';
import { useContentQueries } from 'hooks/useContentQueries';
import { FormContainer } from './FormContainer';
import { AddImagesModal } from './AddImagesModal';
import { AssetsAPI } from 'services/api';
import { InfoBox } from './InfoBox';
import { ListAssetItem } from './ListAssetItem';
import { getStatusFromEntitySys } from 'shared/functions/EntityStatus';
import { ArchivedBanner } from './ArchivedBanner';
import { useHasChanges } from 'hooks/useHasChanges';
import { useAutosave } from 'hooks/useAutosave';

type FormState = {
  title?: DefaultLocaleField;
  slug?: DefaultLocaleField;
  description?: DefaultLocaleField;
  price?: DefaultLocaleField;
  image?: DefaultLocaleLinkAssetsField;
  orderedImageEntries?: Array<AssetProps | EntryNotFound>;
  additional?: DefaultLocaleField;
  unavailable?: DefaultLocaleBooleanField;
  // suggestions: MenuItem[];
  // videos: Asset[];
  errors: FormValidationError;
};

type FormValidationError = {
  title?: string;
  slug?: string;
  description?: string;
  price?: string;
  additional?: string;
  general?: string;
};

type FORM_ACTIONS = { type: 'update'; payload: Partial<FormState> };

const initialState: FormState = {
  title: {
    [defaultLocale]: '',
  },
  slug: {
    [defaultLocale]: '',
  },
  description: {
    [defaultLocale]: '',
  },
  price: {
    [defaultLocale]: '',
  },
  additional: {
    [defaultLocale]: '',
  },
  image: {
    [defaultLocale]: [],
  },
  unavailable: {
    [defaultLocale]: false,
  },
  orderedImageEntries: [],
  errors: {},
};

const reducer = (state: FormState, action: FORM_ACTIONS) => {
  switch (action.type) {
    case 'update': {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};

type FormProps = {
  menuItem: MenuItemMgmtEntry;
};

const mapMenuItemToState = (menuItem: MenuItemMgmtEntry) => {
  return {
    title: menuItem.fields.title || initialState.title,
    slug: menuItem.fields.slug || initialState.slug,
    description: menuItem.fields.description || initialState.description,
    price: menuItem.fields.price || initialState.price,
    additional: menuItem.fields.additional || initialState.additional,
    image: menuItem.fields.image || initialState.image,
    unavailable: menuItem.fields.unavailable || initialState.unavailable,
  };
};

export const MenuItemForm: React.FC<FormProps> = ({ menuItem }) => {
  const queryClient = useQueryClient();
  const { state: accountsState } = useContext(AccountsStateContext);
  const { selectedAccount } = accountsState;
  const mappedMenuItem = mapMenuItemToState(menuItem);
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    ...mappedMenuItem,
  });
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    title,
    slug,
    description,
    price,
    additional,
    image,
    unavailable,
    orderedImageEntries,
    errors,
  } = state;

  const linkedImagesIds: string[] = [];
  if (menuItem.fields.image && menuItem.fields.image[defaultLocale]) {
    linkedImagesIds.push(...menuItem.fields.image[defaultLocale].map((i) => i.sys.id));
  }
  // console.log(`🚀 => linkedImagesIds`, linkedImagesIds);

  const { isLoading: loadingImages } = useQuery(
    ['assets', linkedImagesIds],
    () =>
      AssetsAPI.filterAssets({
        title: selectedAccount?.id,
        ids: linkedImagesIds.join(','),
      }),
    {
      enabled: linkedImagesIds.length > 0,
      onSuccess: (res) => {
        // Reorder linkedEntries to state items order
        console.log('res', res);
        const assetsNotFound = linkedImagesIds.filter((imageId) => {
          return res.items.findIndex((item) => item.sys.id === imageId) < 0;
        });

        const orderedRes = ItemActions.sortAssets(
          [...res.items, ...assetsNotFound.map((id) => ({ sys: { id } }))],
          linkedImagesIds,
        );

        dispatch({
          type: 'update',
          payload: {
            orderedImageEntries: orderedRes,
          },
        });
      },
    },
  );

  const { hasChanges } = useHasChanges({ ...mappedMenuItem, orderedImageEntries }, state);

  useEffect(() => {
    if (menuItem) {
      dispatch({
        type: 'update',
        payload: {
          ...mapMenuItemToState(menuItem),
          ...((!menuItem.fields.image || menuItem.fields.image[defaultLocale].length === 0) && {
            orderedImageEntries: [],
          }),
        },
      });
    }
  }, [menuItem]);

  const { mutateSave, saveLoading } = useContentQueries('menuItem', (res) => {
    console.log(`🚀 => {mutateSave} => res`, res);
    onActionSuccess();
  });

  const onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | { target: { name: string; value: string } },
  ) => {
    const { name, value } = e.target;

    dispatch({
      type: 'update',
      payload: {
        [name]: {
          [defaultLocale]: value,
        },
        ...(name === 'title' && {
          slug: { [defaultLocale]: slugify(value.toLowerCase()) },
        }),
        errors: {
          ...state.errors,
          [name]: undefined,
        },
      },
    });
  };

  const onChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;

    dispatch({
      type: 'update',
      payload: {
        [name]: {
          [defaultLocale]: checked,
        },
        errors: {
          ...state.errors,
          [name]: undefined,
        },
      },
    });
  };

  const onClickSaveChanges = useCallback(async () => {
    const { title, slug, description, additional, price, image, unavailable } = state;

    const updateOptions: UpdateEntryOptions = {
      ...menuItem.fields,
      title,
      slug,
      description,
      additional,
      price,
      image,
      unavailable,
    };

    console.log(`🚀 => onClickSaveChanges => updateOptions`, updateOptions);
    mutateSave({ entryId: menuItem.sys.id, options: updateOptions });
  }, [state, mutateSave]);

  useAutosave({ hasChanges, handleSave: onClickSaveChanges });

  const onSuccessInsertAssets = () => {
    onActionSuccess();
    onClose();
  };

  const onClickSelectImages = () => {
    if (hasChanges) {
      onClickSaveChanges();
    }
    onOpen();
  };

  const onClickRemoveAsset = (id: string) => {
    const { title, slug, description, additional, price } = state;
    if (menuItem.fields.image) {
      const newItems = menuItem.fields.image[defaultLocale].slice();
      const foundIndex = newItems.findIndex((i) => i.sys.id === id);
      if (foundIndex > -1) {
        newItems.splice(foundIndex, 1);

        const updateOptions: UpdateEntryOptions = {
          ...menuItem.fields,
          // getting latest updates from state
          ...(hasChanges && {
            title,
            slug,
            description,
            additional,
            price,
          }),
          image: {
            [defaultLocale]: newItems,
          },
        };
        console.log(`🚀 => onClickRemoveLink => updateOptions`, updateOptions);
        mutateSave({ entryId: menuItem.sys.id, options: updateOptions });
      }
    }
  };

  const onActionSuccess = () => {
    if (menuItem) {
      queryClient.refetchQueries(['entryById', menuItem.sys.id]);
      queryClient.refetchQueries([
        `menuItem`,
        `${selectedAccount ? selectedAccount.account_name : ''}`,
      ]);
    }
  };

  const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    console.log(`🚀 => onDragEnd => result`, result, provided);
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }

    if (image && orderedImageEntries) {
      const reorderedEntries = ItemActions.reorderItems<AssetProps | EntryNotFound>(
        orderedImageEntries,
        source.index,
        destination.index,
      );

      const reorderedImages = ItemActions.reorderItems<Link<'Asset'>>(
        image[defaultLocale],
        source.index,
        destination.index,
      );
      console.log(`🚀 => onDragEnd => items`, reorderedEntries);

      dispatch({
        type: 'update',
        payload: {
          orderedImageEntries: reorderedEntries,
          image: {
            [defaultLocale]: reorderedImages,
          },
        },
      });
    }
  };

  const itemStatus = getStatusFromEntitySys(menuItem.sys);
  const formIsDisabled = itemStatus === 'ARCHIVED';

  return (
    <Flex flex="1" flexDirection="column" marginTop="4" alignItems="flex-start">
      <Flex alignItems="center" justifyContent="space-between" width="100%" marginBottom="4">
        <Box>
          <Text>MENU ITEM</Text>

          <Heading wordBreak="break-all" fontSize={{ base: 'xl', sm: '2xl' }}>
            {title && title[defaultLocale].length > 0 ? title[defaultLocale] : 'Untitled'}
          </Heading>
        </Box>

        <Button
          marginLeft="4"
          onClick={onClickSaveChanges}
          variant="primary"
          isLoading={saveLoading}
          loadingText="Saving"
          isDisabled={formIsDisabled}
        >
          Save Changes
        </Button>
      </Flex>

      <Flex
        width="100%"
        alignItems="flex-start"
        flexWrap="wrap"
        flexDirection={{ base: 'column', md: 'row' }}
      >
        <InfoBox hasChanges={hasChanges} item={menuItem} onActionSuccess={onActionSuccess} />
        <FormContainer>
          {formIsDisabled && <ArchivedBanner />}
          <Flex
            borderLeft="3px solid black"
            borderColor="blue.100"
            paddingLeft="4"
            marginBottom="4"
            flexDirection="column"
            flex="0"
          >
            <FormInput
              label="Menu Item Title (required)"
              name="title"
              value={title ? title[defaultLocale] : ''}
              onChange={onChange}
              error={errors.title}
              placeholder="Enter Item title"
              marginRight="4"
              disabled={formIsDisabled}
            />
            {slug && <Text wordBreak="break-all" color="gray.400">{`${slug[defaultLocale]}`}</Text>}
          </Flex>
          <Flex
            flexDirection="column"
            borderLeft="3px solid black"
            borderColor="blue.100"
            paddingLeft="4"
            marginBottom="4"
          >
            <FormInput
              label="Item Description (required)"
              name="description"
              value={description ? description[defaultLocale] : ''}
              onChange={onChange}
              error={errors.description}
              placeholder="Description of item"
              disabled={formIsDisabled}
            />
          </Flex>
          <Flex
            flexDirection="column"
            borderLeft="3px solid black"
            borderColor="blue.100"
            paddingLeft="4"
            marginBottom="4"
          >
            <FormInput
              label="Item Price (required)"
              name="price"
              value={price ? price[defaultLocale] : ''}
              onChange={onChange}
              error={errors.price}
              placeholder="Price of item"
              disabled={formIsDisabled}
            />
          </Flex>
          <Flex
            flexDirection="column"
            borderLeft="3px solid black"
            borderColor="blue.100"
            paddingLeft="4"
            marginBottom="4"
          >
            <FormTextArea
              label="Additional Item information"
              name="additional"
              value={additional ? additional[defaultLocale] : ''}
              onChange={onChange}
              error={errors.additional}
              placeholder="Enter any additional item information"
              disabled={formIsDisabled}
            />
          </Flex>

          <Flex
            flexDirection="column"
            borderLeft="3px solid black"
            borderColor="blue.100"
            paddingLeft="4"
          >
            <FormLabel>Images</FormLabel>
            <DraggableList isDropDisabled={false} droppableId="image" onDragEnd={onDragEnd}>
              {loadingImages ? (
                <Flex marginBottom="6" justifyContent="center">
                  <LoadingIndicator />
                </Flex>
              ) : (
                <>
                  {orderedImageEntries &&
                    orderedImageEntries.map((image, index) => {
                      const { sys } = image;
                      return (
                        <DraggableItem
                          key={`${sys.id}-${index}`}
                          draggableId={sys.id}
                          index={index}
                          isDragDisabled={formIsDisabled}
                        >
                          <ListAssetItem
                            key={`${sys.id}-${index}`}
                            asset={image}
                            onClickRemove={onClickRemoveAsset}
                            isDisabled={saveLoading}
                            assetType="Image"
                          />
                        </DraggableItem>
                      );
                    })}
                </>
              )}
            </DraggableList>
            <SelectItems
              disabled={formIsDisabled}
              title="Select Images"
              onClick={onClickSelectImages}
            />
          </Flex>
          <Flex
            flexDirection={'column'}
            borderLeft="3px solid black"
            borderColor="blue.100"
            paddingLeft="4"
            marginTop="4"
          >
            <Flex alignItems={'center'}>
              <FormLabel htmlFor="sold-out-switch" margin={0}>
                <Tag background={'white'} border={'1px solid red'} color={'red'}>
                  Sold Out
                </Tag>
              </FormLabel>
              <Switch
                size={'lg'}
                name="unavailable"
                id="sold-out-switch"
                isChecked={unavailable ? unavailable[defaultLocale] : false}
                onChange={onChangeCheckbox}
                marginLeft={'4'}
              />
            </Flex>
            <Text>*Will display a sold out tag under the item title.</Text>
          </Flex>
        </FormContainer>
      </Flex>

      {isOpen && menuItem && (
        <AddImagesModal
          entry={menuItem}
          isOpen={isOpen}
          onClose={onClose}
          entryTypeId="menuItem"
          onSuccess={onSuccessInsertAssets}
          filterOutList={orderedImageEntries}
        />
      )}
    </Flex>
  );
};
