import React from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';

export const FormContainer: React.FC<FlexProps> = ({ children, ...rest }) => {
  return (
    <Flex
      flexDirection="column"
      marginTop="8"
      backgroundColor="white"
      paddingX={{ base: '2', sm: '8' }}
      paddingY={{ base: '4', sm: '8' }}
      borderRadius="6"
      maxWidth={{ base: '100%', lg: '4xl' }}
      minWidth={{ base: '100%', md: '500px' }}
      flex={1}
      {...rest}
    >
      {children}
    </Flex>
  );
};
