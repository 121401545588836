import React from 'react';
import { AddIcon } from '@chakra-ui/icons';
import { Button, Center } from '@chakra-ui/react';

type Props = {
  title: string;
  type?: string;
  onClick: (type?: string) => void;
  disabled?: boolean;
};

export const SelectItems: React.FC<Props> = ({ title, onClick, type, disabled }) => {
  return (
    <Center
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="125px"
      border="1px solid"
      borderColor="gray.200"
      borderRadius="lg"
    >
      <Button
        onClick={() => onClick(type)}
        alignSelf="center"
        variant="outline"
        leftIcon={<AddIcon />}
        disabled={disabled}
      >
        {title}
      </Button>
    </Center>
  );
};
