import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { GeneralMgmtEntry } from 'services/api/types';
import { dayjs, defaultLocale } from 'shared/functions';
import { Card } from './Card';
import { StatusLabel } from 'shared/components';

type GeneralCardProps = {
  item: GeneralMgmtEntry;
  onClick: (item: GeneralMgmtEntry) => void;
  isChecked: boolean;
  onCheck: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const GeneralCard: React.FC<GeneralCardProps> = ({ item, onClick, onCheck, isChecked }) => {
  const { fields, sys } = item;
  const { name, slug, website } = fields;
  const { updatedAt } = sys;
  return (
    <Card id={sys.id} onClick={() => onClick(item)} isChecked={isChecked} onCheck={onCheck}>
      <Flex flexDirection="column" flex={1}>
        <Text fontWeight="bold" fontSize="2xl" lineHeight="100%" wordBreak="break-word">
          {name ? name[defaultLocale] : 'Untitled'}
        </Text>
        {slug && (
          <Text
            display={{ base: 'none', sm: 'flex' }}
            fontSize="sm"
            fontWeight="semibold"
            color="gray.600"
          >
            {`https://visual.menu/${slug[defaultLocale]}`}
          </Text>
        )}
        <Text display={{ base: 'none', sm: 'flex' }} fontWeight="medium" color="gray.600">
          {website ? website[defaultLocale] : ''}
        </Text>
      </Flex>
      <Flex
        flexDirection="column"
        textAlign="right"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <StatusLabel sys={sys} />
        <Flex flexDirection="column" justifyContent="flex-end" marginTop="2">
          <Text fontSize="xs" fontWeight="medium" color="gray.600">
            Updated
          </Text>
          <Text fontSize="xs" color="gray.600">
            {dayjs(updatedAt).format('ll')}
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
};
