import React from 'react';
import { DragDropContext, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import customTheme from 'styles/theme';

const getListStyle = (isDraggingOver: boolean) => ({
  backgroundColor: isDraggingOver ? customTheme.colors.gray[50] : customTheme.colors.white,
  borderRadius: '0.5rem',
});

type Props = {
  droppableId: string;
  isDropDisabled: boolean;
  onDragEnd: (result: DropResult, provided: ResponderProvided) => void;
};

export const DraggableList: React.FC<Props> = ({
  children,
  droppableId,
  isDropDisabled,
  onDragEnd,
}) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={droppableId} isDropDisabled={isDropDisabled}>
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
