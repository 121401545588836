import React from 'react';
import { useColorMode } from '@chakra-ui/color-mode';
import { ReactComponent as LogoDark } from 'assets/logo-dark.svg';
import { ReactComponent as LogoLight } from 'assets/logo-light.svg';

type LogoProps = {
  height?: number;
  width?: number;
};
export const Logo: React.FC<LogoProps> = ({ height = 30, width = 75 }) => {
  const { colorMode } = useColorMode();

  if (colorMode === 'light') {
    return <LogoDark height={height} width={width} />;
  }

  return <LogoLight height={height} width={width} />;
};
