import { useState } from 'react';
import { EntriesResponse } from '../services/api/types';
import { AssetProps } from 'contentful-management/dist/typings/export-types';

type Response = {
  selected: string[];
  onCheckItem: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
};

export const useSelectItems = (data?: EntriesResponse | AssetProps[]): Response => {
  const [selected, setSelected] = useState<string[]>([]);

  const onCheckItem = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = event.target;

    setSelected((prev) => {
      if (id === 'all') {
        if (data) {
          if (prev.length === data.length) {
            return [];
          } else {
            return data.map((i) => i.sys.id);
          }
        }
        return prev;
      } else {
        const prevCopy = prev.slice();
        const foundIndex = prevCopy.findIndex((i) => i === id);
        if (foundIndex > -1) {
          prevCopy.splice(foundIndex, 1);
        } else {
          // INFO: Handling one item selected at a time for now
          // Entry Status handling on selected is complicated
          return [id];
          prevCopy.push(id);
        }
        return prevCopy;
      }
    });
  };

  return { selected, setSelected, onCheckItem };
};
