import React, { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { Router } from 'router';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ContextProvider } from 'context';

// Hide console logs in production. Does not strip logs from code.
if (process.env.NODE_ENV !== 'development') {
  console.log = () => {
    //
  };
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App: React.FC = () => {
  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT !== 'dev') {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [new Integrations.BrowserTracing()],
        environment: process.env.REACT_APP_ENVIRONMENT,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.33,
      });
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ContextProvider>
        <BrowserRouter>
          <Router />
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </BrowserRouter>
      </ContextProvider>
    </QueryClientProvider>
  );
};

export default App;
