import { useQuery } from 'react-query';
import { SubscriptionsAPI } from 'services/api';

export const usePrices = () => {
  const { data: pricesData, isFetching: fetchingPrices } = useQuery(
    `prices`,
    () => SubscriptionsAPI.getPrices(),
    {
      staleTime: 5 * 60 * 1000,
    },
  );

  let monthlyPrice;
  let annualPrice;
  if (pricesData) {
    monthlyPrice = pricesData.data.find((price) => price.recurring.interval === 'month');
    annualPrice = pricesData.data.find((price) => price.recurring.interval === 'year');
  }

  return {
    prices: pricesData,
    monthlyPrice,
    annualPrice,
    isFetching: fetchingPrices,
  };
};
