// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useContext, useState } from 'react';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  DrawerCloseButton,
  Button,
  Image,
  Flex,
  Text,
  useBreakpointValue,
  SlideDirection,
  InputGroup,
  InputLeftElement,
  Input,
} from '@chakra-ui/react';
import { useMutation, useQuery } from 'react-query';
import { SearchIcon } from '@chakra-ui/icons';
import { AssetProps } from 'contentful-management/dist/typings/export-types';
import { Link } from 'contentful-management/dist/typings/common-types';
import { AccountsStateContext } from 'context/Accounts/AccountsContext';
import {
  AssetResponse,
  CategoryMgmtEntry,
  ContentType,
  EntryNotFound,
  GeneralMgmtEntry,
  MenuItemMgmtEntry,
  MenuMgmtEntry,
  RequestErrorType,
  UpdateEntryOptions,
} from 'services/api/types';
import { AssetsAPI, EntriesAPI } from 'services/api';
import { defaultLocale } from 'shared/functions';
import { StatusLabel } from 'shared/components';
import { useInfiniteScrollData } from 'hooks/useInfiniteScrollData';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  entryTypeId: ContentType;
  entry: CategoryMgmtEntry | MenuItemMgmtEntry | MenuMgmtEntry | GeneralMgmtEntry;
  onSuccess: () => void;
  filterOutList?: Array<AssetProps | EntryNotFound>;
};

export const AddImagesModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  entryTypeId,
  entry,
  onSuccess,
  filterOutList = [],
}) => {
  const { state: accountsState } = useContext(AccountsStateContext);
  const { selectedAccount } = accountsState;
  const [selected, setSelected] = useState<AssetProps[]>([]);
  const [search, setSearch] = useState('');
  const drawerSize = useBreakpointValue({ base: 'sm', md: 'lg' });
  const drawerPlacement: SlideDirection | undefined = useBreakpointValue({
    base: 'bottom',
    md: 'right',
  });

  const { data } = useQuery(
    `media-${selectedAccount ? selectedAccount.account_name : ''}`,
    () =>
      AssetsAPI.filterAssets({
        title: selectedAccount?.id || '',
      }),
    {
      enabled: !!selectedAccount,
      staleTime: 1 * 60 * 1000,
      select: (data) => {
        if (filterOutList.length > 0) {
          const filteredItems = data.items.filter(
            (asset) => !filterOutList.find((f) => f.sys.id === asset.sys.id),
          );
          const filteredRes = {
            ...data,
            items: filteredItems,
          };
          return filteredRes;
        }
        return data;
      },
    },
  );

  const { mutate: mutateUpdateEntry, isLoading: isUpdating } = useMutation(
    (options: UpdateEntryOptions) => EntriesAPI.updateEntry(entry.sys.id, options),
    {
      onSuccess: (res) => {
        console.log(`🚀 => mutateInsertEntries:res`, res);
        onSuccess();
      },
      onError: (error: RequestErrorType) => {
        console.log(`🚀 => error`, error);
      },
    },
  );

  const { items } = data || { items: [] };
  const filteredDataBySearch = (items as AssetProps[]).filter((item) => {
    const lowerCaseSearch = search.toLowerCase();
    const itemName = item.fields.title[defaultLocale].replace(`${selectedAccount?.id}_`, '');
    return itemName.toLowerCase().includes(lowerCaseSearch);
  });
  const { ref: footerRef, data: dataByPage } = useInfiniteScrollData(filteredDataBySearch);

  const onClickAsset = (asset: AssetResponse) => {
    setSelected((prev) => {
      const prevCopy = prev.slice();
      const foundIndex = prevCopy.findIndex((i) => i.sys.id === asset.sys.id);
      if (foundIndex > -1) {
        prevCopy.splice(foundIndex, 1);
      } else {
        prevCopy.push(asset);
      }
      return prevCopy;
    });
  };

  const onClickInsertAssets = () => {
    let updatedEntry:
      | GeneralMgmtEntry
      | CategoryMgmtEntry
      | MenuMgmtEntry
      | MenuItemMgmtEntry
      | null = null;
    let updateKey: 'items' | 'categories' | 'image' | 'menus' = 'items';
    switch (entryTypeId) {
      case 'category':
        updatedEntry = { ...(entry as CategoryMgmtEntry) };
        updateKey = 'items';
        break;
      case 'menu':
        updatedEntry = { ...(entry as MenuMgmtEntry) };
        updateKey = 'categories';
        break;
      case 'general':
        updatedEntry = { ...(entry as GeneralMgmtEntry) };
        updateKey = 'menus';
        break;
      case 'menuItem':
        updatedEntry = { ...(entry as MenuItemMgmtEntry) };
        updateKey = 'image';
        break;
      default:
        break;
    }

    const selectedAssetsAsLinks: Link<'Asset'>[] = selected.map((e) => {
      return {
        sys: {
          type: 'Link',
          linkType: 'Asset',
          id: e.sys.id,
        },
      };
    });

    console.log(
      `🚀 => const selectedAssetsAsLinks:Link<'Asset'>=selected.map => selectedAssetsAsLinks`,
      selectedAssetsAsLinks,
    );

    if (updatedEntry) {
      if (updatedEntry.fields[updateKey] && updatedEntry.fields[updateKey][defaultLocale]) {
        const itemsCopy = updatedEntry.fields[updateKey][defaultLocale].slice();
        // Combine fields with previous
        itemsCopy.push(...selectedAssetsAsLinks);
        updatedEntry.fields[updateKey][defaultLocale] = itemsCopy;
      } else {
        // add as new
        updatedEntry.fields[updateKey] = {
          [defaultLocale]: selectedAssetsAsLinks,
        };
      }
      console.log(`🚀 => onClickInsertAssets => updatedEntry`, updatedEntry);
      mutateUpdateEntry(updatedEntry.fields);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearch(value);
  };

  return (
    <Drawer
      isFullHeight={true}
      placement={drawerPlacement}
      isOpen={isOpen}
      onClose={onClose}
      size={drawerSize}
    >
      <DrawerOverlay />
      <DrawerContent zIndex="overlay">
        <DrawerHeader
          fontSize="xl"
          fontWeight="bold"
          borderBottom="1px solid"
          borderColor="gray.300"
        >
          Add Images
        </DrawerHeader>
        <DrawerCloseButton />

        <Flex paddingY={8} paddingX={8}>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <SearchIcon color="gray.300" />
            </InputLeftElement>
            <Input
              backgroundColor="gray.50"
              type="text"
              placeholder="Search items"
              onChange={onChange}
              value={search}
            />
          </InputGroup>
        </Flex>

        <DrawerBody overflowY="auto" paddingTop="8" paddingBottom="8">
          <Flex flex={1} justifyContent="flex-start" flexWrap="wrap">
            {(dataByPage as AssetProps[]).map((asset) => {
              const isSelected = selected.find((e) => e.sys.id === asset.sys.id);
              return (
                <Flex
                  onClick={() => onClickAsset(asset)}
                  key={asset.sys.id}
                  flexDirection="column"
                  marginBottom="8"
                  marginRight={{ base: 0, sm: '8' }}
                  border="3px solid"
                  _hover={{
                    cursor: 'pointer',
                    borderColor: 'blue.200',
                  }}
                  borderColor={isSelected ? 'blue.200' : 'transparent'}
                  borderRadius="md"
                  backgroundColor={isSelected ? 'gray.50' : 'white'}
                  boxShadow="lg"
                  width={{ base: '100%', sm: '240px' }}
                >
                  <Image
                    src={asset.fields.file[defaultLocale].url}
                    borderRadius="sm"
                    width={{ base: '100%', sm: '240px' }}
                    height="100%"
                    objectFit="cover"
                  />
                  <Flex
                    background="white"
                    padding="4"
                    borderRadius="md"
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Text
                      marginRight="4"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                      overflow="hidden"
                      flex={1}
                    >
                      {asset.fields.title[defaultLocale].replace(`${selectedAccount?.id}_`, '')}
                    </Text>
                    <StatusLabel sys={asset.sys} />
                  </Flex>
                </Flex>
              );
            })}
            <Flex height={10} width={10} marginTop="8" ref={footerRef} />
          </Flex>
        </DrawerBody>

        <DrawerFooter borderTop="1px solid" borderColor="gray.300">
          <Button variant="ghost" marginRight="4" onClick={onClose}>
            Close
          </Button>
          <Button
            isLoading={isUpdating}
            onClick={onClickInsertAssets}
            variant="add"
            isDisabled={items.length === 0 || selected.length === 0}
          >
            {selected.length === 0 ? 'Select Images' : `Insert ${selected.length} Images`}
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
