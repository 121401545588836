import {
  Asset,
  AssetProps,
  CollectionProp,
  CreateAssetProps,
  EntryProps,
  EntityMetaSysProps,
  Link,
} from 'contentful-management/dist/typings/export-types';

export interface RequestErrorType extends Error {
  status?: number;
  message: string;
}

export type ApiPostOptions =
  | CreateUserOptions
  | CreateTeamOptions
  | CreateEntryOptions
  | CreateAssetOptions
  | CreateUploadOptions
  | CreateAccountOptions
  | CreateSessionOptions
  | undefined;

export type ApiGetOptions = undefined;

export type ApiPatchOptions =
  | undefined
  | UpdateUserOptions
  | UpdateAccountOptions
  | UpdateTeamOptions
  | UpdateEntryOptions;

//
// USER
//

export type CreateUserOptions = {
  email: string;
};

export type UpdateUserOptions = {
  email?: string;
  first_name?: string;
  last_name?: string;
  profile_picture?: string;
};

export type UserResponse = {
  id: string;
  email: string;
  first_name?: string;
  last_name?: string;
  profile_picture?: string;
  created_at: string;
  updated_at: string;
};

//
// ACCOUNTS
//

export type CreateAccountOptions = {
  account_name: string;
};

export type UpdateAccountOptions = {
  account_name: string;
};

export type Account = {
  id: string;
  user_id: string;
  account_name: string;
  created_at: string;
  updated_at: string;
  subscriptions?: Subscription;
};
export type AccountResponse = Account;
export type AccountsResponse = Account[];

//
// TEAMS
//

export type CreateTeamOptions = {
  account_id: string;
};

export type UpdateTeamOptions = {
  role: string;
};

export type Team = {
  user_id: string;
  account_id: string;
  role: string;
  created_at: string;
  updated_at: string;
};
export type TeamResponse = Team;
export type TeamsResponse = Team[];

//
// Subscriptions
//

export type CreateCustomerOptions = {
  account_id: string;
  name: string;
  email: string;
  metadata: Record<string, string>;
};

export type SubscriptionStatus =
  | 'active'
  | 'past_due'
  | 'unpaid'
  | 'canceled'
  | 'incomplete'
  | 'incomplete_expired'
  | 'trialing';

export type Subscription = {
  account_id: string;
  stripe_customer_id: string;
  status: SubscriptionStatus | null;
  current_period_end: Date | null;
  stripe_subscription_id: string | null;
  stripe_price_id: string | null;
  created_at: Date | null;
  updated_at: Date | null;
};

export type CreateCustomerResponse = Subscription;
export type GetSubscriptionResponse = Subscription;

export type CreateSessionResponse = {
  url: string;
  [key: string]: string;
};
export type CreateSessionOptions = {
  stripe_customer_id: string;
  price_id: string;
};

export type StripePrice = {
  id: string;
  active: boolean;
  currency: string;
  metadata: Record<string, string>;
  product: string;
  recurring: {
    interval: 'year' | 'month';
    interval_count: number;
  };
  unit_amount: number;
  unit_amount_decimal: string;
};

export type GetPricesResponse = {
  object: string;
  data: StripePrice[];
  has_more: boolean;
  url: 'string';
};

export type GetSessionDetailsResponse = {
  url: string;
  [key: string]: string;
};
/**
  CONTENTFUL MANAGEMENT TYPES
*/

export type CreateEntryOptions = {
  accountId: DefaultLocaleField;
  [key: string]: DefaultLocaleField | DefaultLocaleAssetField;
};

export type UpdateEntryOptions = Partial<IGeneral | IMenu | IMenuItem | ICategory>;

export type UpdateOptions = {
  entryId: string;
  options: UpdateEntryOptions;
};

export const isEntryNotFound = (
  entry:
    | GeneralMgmtEntry
    | MenuMgmtEntry
    | CategoryMgmtEntry
    | MenuItemMgmtEntry
    | EntryNotFound
    | AssetProps,
): entry is EntryNotFound => {
  return (
    (entry as GeneralMgmtEntry | MenuMgmtEntry | CategoryMgmtEntry | MenuItemMgmtEntry | AssetProps)
      .fields === undefined
  );
};
export const isEntityMetaSysProps = (
  sys: EntryNotFoundSys | EntityMetaSysProps,
): sys is EntityMetaSysProps => {
  return (sys as EntityMetaSysProps).version !== undefined;
};

export type EntriesResponse = (
  | GeneralMgmtEntry
  | MenuMgmtEntry
  | CategoryMgmtEntry
  | MenuItemMgmtEntry
)[];

export type EntryNotFoundSys = {
  id: string;
};

export type EntryNotFound = {
  sys: EntryNotFoundSys;
};

export type AssetsResponse = CollectionProp<AssetProps>;
export type AssetResponse = AssetProps;
export type CreateAssetOptions = CreateAssetProps;
export type CreateUploadOptions = {
  file: File;
};

export type EntryResponse =
  | GeneralMgmtEntry
  | MenuMgmtEntry
  | CategoryMgmtEntry
  | MenuItemMgmtEntry;

export type Locale = 'en-US';

export type DefaultLocaleField = Record<Locale, string>;
export type DefaultLocaleBooleanField = Record<Locale, boolean>;
export type DefaultLocaleLinkEntriesField = Record<Locale, Link<'Entry'>[]>;
export type DefaultLocaleLinkAssetsField = Record<Locale, Link<'Asset'>[]>;
export type DefaultLocaleAssetField = Record<Locale, Asset>;

export type ContentType = 'general' | 'menu' | 'category' | 'menuItem';
export type ContentTypeLabel = 'Info' | 'Menu' | 'Category' | 'Menu Item' | '';
export type AssetType = 'Image' | 'Video';

export type EntryFilters = {
  accountId?: string;
  ids?: string;
  archivedAt?: boolean;
};

export type AssetFilters = {
  title?: string;
  ids?: string;
};

export type GeneralMgmtEntry = EntryProps<IGeneral>;
export type MenuMgmtEntry = EntryProps<IMenu>;
export type CategoryMgmtEntry = EntryProps<ICategory>;
export type MenuItemMgmtEntry = EntryProps<IMenuItem>;
export interface IGeneral {
  name: DefaultLocaleField;
  logo: DefaultLocaleAssetField;
  headerImage: DefaultLocaleAssetField;
  website: DefaultLocaleField;
  menus: DefaultLocaleLinkEntriesField;
  slug: DefaultLocaleField;
  accountId: DefaultLocaleField;
}

export interface IMenu {
  accountId: DefaultLocaleField;
  description?: DefaultLocaleField;
  title?: DefaultLocaleField;
  categories?: DefaultLocaleLinkEntriesField;
}

export interface ICategory {
  accountId: DefaultLocaleField;
  title?: DefaultLocaleField;
  items?: DefaultLocaleLinkEntriesField;
  textOnly?: DefaultLocaleBooleanField;
}

export interface IMenuItem {
  accountId: DefaultLocaleField;
  title?: DefaultLocaleField;
  slug?: DefaultLocaleField;
  description?: DefaultLocaleField;
  price?: DefaultLocaleField;
  image?: DefaultLocaleLinkAssetsField;
  additional?: DefaultLocaleField;
  suggestions?: DefaultLocaleLinkEntriesField;
  videos?: DefaultLocaleLinkAssetsField;
  unavailable?: DefaultLocaleBooleanField;
}

// Analytics
export type QueryAnalyticsOptions = {
  startDate: string;
  endDate: string;
  name: string;
  type: 'menu' | 'menu/item';
};

export type QueryAnalyticsData = {
  item: string;
  count: number;
};

export type QueryAnalyticsResponse = {
  metadata: {
    query: Record<string, string>;
    total: number;
  };
  data: QueryAnalyticsData[];
};
