import React from 'react';
import { Flex, chakra } from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/color-mode';
import Spinner from 'react-spinkit';
import customTheme from 'styles/theme';

const ChakraSpinner = chakra(Spinner);

type Props = {
  color?: string;
  size?: string;
};

export const LoadingIndicator: React.FC<Props> = ({
  size = '1',
  color = useColorModeValue(customTheme.colors.gray[600], customTheme.colors.gray[50]),
}) => {
  return (
    <Flex>
      <ChakraSpinner
        css={{
          transform: `scale(${size})`,
        }}
        fadeIn="none"
        className="loadingIndicator"
        name="wave"
        color={color}
      />
    </Flex>
  );
};
