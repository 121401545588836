import { get, post } from './helpers';
import {
  GetSubscriptionResponse,
  CreateSessionResponse,
  GetSessionDetailsResponse,
  GetPricesResponse,
  CreateSessionOptions,
} from './types';

export const getSubscription = async (accountId: string): Promise<GetSubscriptionResponse> => {
  const response = await get<GetSubscriptionResponse>(`/subscriptions/account/${accountId}`);
  return response;
};

export const getPrices = async (): Promise<GetPricesResponse> => {
  const response = await get<GetPricesResponse>(`/subscriptions/prices/`);
  return response;
};

export const createCheckoutSession = async (
  options: CreateSessionOptions,
): Promise<CreateSessionResponse> => {
  const response = await post<CreateSessionResponse>(`/subscriptions/checkout/session`, options);
  return response;
};

export const getCheckoutSession = async (sessionId: string): Promise<CreateSessionResponse> => {
  const response = await get<GetSessionDetailsResponse>(
    `/subscriptions/checkout/session/${sessionId}`,
  );
  return response;
};

export const createBillingPortalSession = async (): Promise<CreateSessionResponse> => {
  const response = await post<CreateSessionResponse>(`/subscriptions/billing/session`, undefined);
  return response;
};
