import React from 'react';
import {
  CreateAccount,
  Dashboard,
  Settings,
  Login,
  UserDetails,
  Media,
  Category,
  Menu,
  MenuItem,
  General,
  ForgotPassword,
  ResetPassword,
  Store,
} from 'features';
import { ReactComponent as DashboardIcon } from 'assets/icons/dashboard.svg';
import { ReactComponent as ContentIcon } from 'assets/icons/content.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg';
import { ReactComponent as MediaIcon } from 'assets/icons/media.svg';
import { ContentType } from 'services/api/types';
import { SubscriptionStatus } from 'features/Pricing/SubscriptionStatus';

export type SubRoute = {
  id: ContentType;
  title: string;
};
export interface RouteType {
  path: string;
  component: React.FC;
  name: string;
  exact?: boolean;
  icon?: React.FC<React.SVGProps<SVGSVGElement>> | null;
  subRoutes?: SubRoute[];
}

export const storeSubRoutes: SubRoute[] = [
  {
    id: 'general',
    title: 'Info',
  },
  {
    id: 'menu',
    title: 'Menus',
  },
  {
    id: 'category',
    title: 'Categories',
  },
  {
    id: 'menuItem',
    title: 'Menu Items',
  },
];

export const authRoutes: Array<RouteType> = [
  {
    path: '/login',
    component: Login,
    name: 'Login',
    exact: true,
  },
  {
    path: '/create-account',
    component: CreateAccount,
    name: 'CreateAccount',
    exact: true,
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    name: 'ForgotPassword',
    exact: true,
  },
  {
    path: '/auth/action',
    component: ResetPassword,
    name: 'ResetPassword',
    exact: true,
  },
  {
    path: '/account-details',
    component: UserDetails,
    name: 'UserDetails',
    exact: true,
  },
  // {
  //   path: '/subscription',
  //   component: Pricing,
  //   name: 'Pricing',
  //   exact: true,
  // },
  {
    path: '/subscription',
    component: SubscriptionStatus,
    name: 'Subscription Status',
    exact: true,
  },
];

const dashboardRoute: RouteType = {
  path: '/dashboard',
  component: Dashboard,
  name: 'Dashboard',
  exact: true,
  icon: DashboardIcon,
};

const settingsRoute: RouteType = {
  path: '/settings',
  component: Settings,
  name: 'Settings',
  exact: true,
  icon: SettingsIcon,
};
const mediaRoute: RouteType = {
  path: '/media',
  component: Media,
  name: 'Media',
  exact: true,
  icon: MediaIcon,
};

const storeRoute: RouteType = {
  path: '/store',
  component: Store,
  name: 'Store',
  exact: true,
  icon: ContentIcon,
  subRoutes: storeSubRoutes,
};

const listContentRoute: RouteType = {
  path: '/store/content/:accountId/:contentTypeId',
  component: Store,
  name: 'Store',
};

const menuRoute: RouteType = {
  path: '/store/content/menu/:menuId',
  component: Menu,
  name: 'Menu',
};
const categoryRoute: RouteType = {
  path: '/store/content/category/:categoryId',
  component: Category,
  name: 'Category',
};
const menuItemRoute: RouteType = {
  path: '/store/content/menuItem/:itemId',
  component: MenuItem,
  name: 'MenuItem',
};
const generalRoute: RouteType = {
  path: '/store/content/general/:generalId',
  component: General,
  name: 'General',
};

export const authenticatedRoutes: Array<RouteType> = [
  dashboardRoute,
  storeRoute,
  settingsRoute,
  mediaRoute,
  menuRoute,
  categoryRoute,
  menuItemRoute,
  generalRoute,
  listContentRoute,
];

export const sideNavRoutes: Array<RouteType> = [
  dashboardRoute,
  storeRoute,
  mediaRoute,
  settingsRoute,
];
