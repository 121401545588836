import { useEffect, useRef } from 'react';

type Props = {
  hasChanges: boolean;
  handleSave: () => void;
};

type Result = null;

export const useAutosave = ({ hasChanges, handleSave }: Props): Result => {
  const timerRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    console.log('hasChanges', hasChanges);

    if (hasChanges) {
      if (timerRef.current) {
        clearTimeout(timerRef.current as NodeJS.Timeout);
      }

      timerRef.current = setTimeout(() => {
        console.log('Has Changes after 2 seconds - Running Save');
        handleSave();
      }, 2000);
    } else {
      if (timerRef.current) {
        clearTimeout(timerRef.current as NodeJS.Timeout);
      }
    }
  }, [hasChanges, handleSave]);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current as NodeJS.Timeout);
      }
    };
  }, []);

  return null;
};
