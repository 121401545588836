import React from 'react';
import { chakra, FormControl, FormErrorMessage, FormLabel, Textarea } from '@chakra-ui/react';

type FormInputProps = {
  label: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  error?: string;
  type?: string;
  disabled?: boolean;
  required?: boolean;
};

export const DefaultFormTextarea: React.FC<FormInputProps> = ({
  label,
  name,
  value,
  onChange,
  placeholder,
  error,
  type = 'text',
  disabled = false,
  required = false,
  ...rest
}) => {
  return (
    <FormControl
      display="flex"
      isInvalid={!!error}
      flexDirection="column"
      marginBottom="3"
      isRequired={required}
      {...rest}
    >
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Textarea
        onChange={onChange}
        value={value}
        name={name}
        id={name}
        placeholder={placeholder}
        type={type}
        disabled={disabled}
      />
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};
export const FormTextArea = chakra(DefaultFormTextarea);
