import React, { useState } from 'react';
import {
  chakra,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Button,
} from '@chakra-ui/react';
import { FiEye, FiEyeOff } from 'react-icons/fi';

type FormInputProps = {
  label: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  placeholder?: string;
  error?: string;
  type?: string;
  disabled?: boolean;
  required?: boolean;
};

export const DefaultFormInput: React.FC<FormInputProps> = ({
  label,
  name,
  value,
  onChange,
  onKeyDown,
  placeholder,
  error,
  type = 'text',
  disabled = false,
  required = true,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const onClickTogglePassword = () => {
    setShowPassword((prev) => !prev);
  };
  return (
    <FormControl
      display="flex"
      isInvalid={!!error}
      isRequired={required}
      flexDirection="column"
      marginBottom="3"
      {...rest}
    >
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <InputGroup>
        <Input
          onChange={onChange}
          value={value}
          name={name}
          id={name}
          placeholder={placeholder}
          type={type === 'password' ? (showPassword ? 'text' : type) : type}
          disabled={disabled}
          onKeyDown={onKeyDown}
        />
        {type === 'password' && (
          <InputRightElement paddingRight="2px" paddingY="2px">
            <Button
              minHeight="initial"
              height="100%"
              variant="ghost"
              size="sm"
              onClick={onClickTogglePassword}
            >
              {showPassword ? <FiEyeOff /> : <FiEye />}
            </Button>
          </InputRightElement>
        )}
      </InputGroup>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};
export const FormInput = chakra(DefaultFormInput);
