import React, { useState } from 'react';
import { SearchIcon } from '@chakra-ui/icons';
import { Flex, Input, InputGroup, InputLeftElement, Select } from '@chakra-ui/react';
import { EntityStatusUtils } from 'shared/functions';

type FilterRowProps = {
  onChangeSearch: (searchText: string) => void;
  onChangeStatusFilter: (event: React.ChangeEvent<HTMLSelectElement>) => void;
};

export const FilterRow: React.FC<FilterRowProps> = ({ onChangeSearch, onChangeStatusFilter }) => {
  const [searchText, setSearchText] = useState('');

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchText(value);
    onChangeSearch(value);
  };

  return (
    <Flex marginBottom="4" flexDirection={{ base: 'column', sm: 'row' }}>
      <InputGroup marginRight={{ base: 0, sm: '4' }} marginBottom={{ base: '2', sm: 0 }}>
        <InputLeftElement pointerEvents="none">
          <SearchIcon color="gray.300" />
        </InputLeftElement>
        <Input
          backgroundColor="gray.50"
          type="text"
          placeholder="Search entries"
          onChange={onChange}
          value={searchText}
        />
      </InputGroup>
      <Select
        onChange={onChangeStatusFilter}
        backgroundColor="gray.50"
        placeholder="Status: All"
        maxWidth="160px"
      >
        {Object.keys(EntityStatusUtils.ENTITY_STATUS).map((key) => {
          const status = EntityStatusUtils.ENTITY_STATUS[key];
          return (
            <option value={status} key={key}>
              {status}
            </option>
          );
        })}
      </Select>
    </Flex>
  );
};
