import React, { useContext, useEffect, useState } from 'react';
import { Button, Flex, Heading, chakra } from '@chakra-ui/react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { storeSubRoutes } from 'router/routes';
import { AccountsStateContext } from 'context/Accounts/AccountsContext';
import { EntriesAPI } from 'services/api';
import {
  CategoryMgmtEntry,
  ContentType,
  CreateEntryOptions,
  EntriesResponse,
  GeneralMgmtEntry,
  MenuItemMgmtEntry,
  MenuMgmtEntry,
} from 'services/api/types';
import { MenuCard } from './components/MenuCard';
import { GeneralCard } from './components/GeneralCard';
import { MenuItemCard } from './components/MenuItemCard';
import { CategoryCard } from './components/CategoryCard';
import { defaultLocale } from 'shared/functions';
import { LoadingIndicator } from 'shared/components';
import { ActionRow } from './components/ActionRow';
import { useSelectItems } from 'hooks/useSelectItems';
import { FilterRow } from './components/FilterRow';
import { getStatusFromEntitySys, Status } from 'shared/functions/EntityStatus';
import { ReactComponent as EmptyListSVG } from 'assets/empty-list.svg';
import { useInfiniteScrollData } from 'hooks/useInfiniteScrollData';

const ChakraEmptyListSVG = chakra(EmptyListSVG);

type ListContentParams = {
  contentTypeId: ContentType;
};

export const ListContent: React.FC = () => {
  const history = useHistory();
  const { contentTypeId } = useParams<ListContentParams>();
  const { state: accountsState } = useContext(AccountsStateContext);
  const { selectedAccount } = accountsState;
  const [filteredData, setFilteredData] = useState<EntriesResponse>();
  const selectedRoute = storeSubRoutes.find((i) => i.id === contentTypeId);

  const {
    data,
    refetch: refetchEntries,
    isFetching,
  } = useQuery(
    [contentTypeId, `${selectedAccount ? selectedAccount.account_name : ''}`],
    () =>
      EntriesAPI.filterEntries(contentTypeId, {
        accountId: selectedAccount?.id || '',
      }),
    {
      enabled: !!(selectedAccount && contentTypeId),
      onSettled: (data) => {
        setFilteredData(data);
      },
    },
  );

  const { selected, setSelected, onCheckItem } = useSelectItems(data);

  useEffect(() => {
    setSelected([]);
  }, [contentTypeId]);

  const { mutateAsync: mutateCreate, isLoading: createLoading } = useMutation(
    (createOptions: CreateEntryOptions) => EntriesAPI.createEntry(contentTypeId, createOptions),
    {
      onSuccess: (data) => {
        console.log(`🚀 => onSuccess:`, data);
        refetchEntries();
        history.push(`/store/content/${contentTypeId}/${data.sys.id}`, {
          [contentTypeId]: data,
        });
      },
    },
  );

  const onClickCard = (
    item: GeneralMgmtEntry | MenuMgmtEntry | MenuItemMgmtEntry | CategoryMgmtEntry,
  ) => {
    console.log(`🚀 => onClickCard => generalItem`, item);
    setSelected([]);
    if (contentTypeId) {
      history.push(`/store/content/${contentTypeId}/${item.sys.id}`, {
        [contentTypeId]: item,
      });
    }
  };

  const onClickCreateContent = () => {
    mutateCreate({
      accountId: {
        [defaultLocale]: selectedAccount?.id || '',
      },
    });
  };

  const onActionSuccess = () => {
    refetchEntries();
    setSelected([]);
  };

  const onChangeSearch = (searchText: string) => {
    setFilteredData((prev) => {
      if (searchText.length === 0) {
        return data;
      }
      if (data) {
        switch (contentTypeId) {
          case 'menuItem':
            return (data as MenuItemMgmtEntry[]).filter((item) => {
              if (item.fields.title && item.fields.title[defaultLocale]) {
                return item.fields.title[defaultLocale]
                  .toLowerCase()
                  .includes(searchText.toLowerCase());
              }
            });
          case 'menu':
            return (data as MenuMgmtEntry[]).filter((item) => {
              if (item.fields.title && item.fields.title[defaultLocale]) {
                return item.fields.title[defaultLocale]
                  .toLowerCase()
                  .includes(searchText.toLowerCase());
              }
            });
          case 'category':
            return (data as CategoryMgmtEntry[]).filter((item) => {
              if (item.fields.title && item.fields.title[defaultLocale]) {
                return item.fields.title[defaultLocale]
                  .toLowerCase()
                  .includes(searchText.toLowerCase());
              }
            });
          case 'general':
            return (data as GeneralMgmtEntry[]).filter((item) => {
              if (item.fields.name && item.fields.name[defaultLocale]) {
                return item.fields.name[defaultLocale]
                  .toLowerCase()
                  .includes(searchText.toLowerCase());
              }
            });

          default:
            break;
        }
      }

      return prev;
    });
  };

  const onChangeStatusFilter = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    const statusSelected: Status = value as Status;
    setFilteredData((prev) => {
      if (value.length === 0) {
        return data;
      }
      if (data) {
        return data.filter((item) => getStatusFromEntitySys(item.sys) === statusSelected);
      }
      return prev;
    });
  };

  return (
    <Flex
      maxWidth="100%"
      flexDirection="column"
      flex={1}
      padding={{ base: '4', sm: 0 }}
      paddingBottom="120px"
    >
      <Flex justifyContent="space-between" alignItems="center" marginBottom="4">
        <Heading>{selectedRoute?.title}</Heading>

        <Button
          onClick={onClickCreateContent}
          variant="primary"
          alignSelf="flex-end"
          isLoading={createLoading}
        >
          Add New
        </Button>
      </Flex>
      {isFetching ? (
        <Flex justifyContent="center" alignItems="center" flex="1">
          <LoadingIndicator />
        </Flex>
      ) : (
        <>
          {selected.length > 0 && (
            <ActionRow
              onActionSuccess={onActionSuccess}
              data={filteredData}
              onCheckItem={onCheckItem}
              selected={selected}
              isStatic={true}
            />
          )}
          {data && data.length > 0 && (
            <FilterRow
              onChangeSearch={onChangeSearch}
              onChangeStatusFilter={onChangeStatusFilter}
            />
          )}
          <ListView
            data={filteredData}
            contentType={contentTypeId}
            onClick={onClickCard}
            onCheck={onCheckItem}
            selected={selected}
          />
        </>
      )}
    </Flex>
  );
};

type ListViewProps = {
  data?: EntriesResponse;
  contentType: ContentType;
  onCheck: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick: (item: GeneralMgmtEntry | MenuMgmtEntry | MenuItemMgmtEntry | CategoryMgmtEntry) => void;
  selected: string[];
};

const ListView: React.FC<ListViewProps> = ({ data, contentType, onClick, onCheck, selected }) => {
  const { ref: footerRef, data: dataByPage } = useInfiniteScrollData(data);

  // TODO: adjust to show all data when filter is being applied.
  if (!data || data.length === 0)
    return (
      <Flex
        marginTop="8"
        flex="1"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <ChakraEmptyListSVG height={['275px', '400px']} width={['275px', '400px']} />
        <Heading size="lg" marginTop="8">
          Nothing here yet!
        </Heading>
      </Flex>
    );

  const renderItems = () => {
    switch (contentType) {
      case 'menu':
        return (
          <>
            {(dataByPage as MenuMgmtEntry[]).map((item) => {
              const isChecked = !!selected.find((i) => i === item.sys.id);
              return (
                <MenuCard
                  key={item.sys.id}
                  item={item}
                  onClick={onClick}
                  onCheck={onCheck}
                  isChecked={isChecked}
                />
              );
            })}
          </>
        );
      case 'general':
        return (
          <>
            {(dataByPage as GeneralMgmtEntry[]).map((item) => {
              const isChecked = !!selected.find((i) => i === item.sys.id);
              return (
                <GeneralCard
                  key={item.sys.id}
                  item={item}
                  onClick={onClick}
                  isChecked={isChecked}
                  onCheck={onCheck}
                />
              );
            })}
          </>
        );
      case 'menuItem':
        return (
          <>
            {(dataByPage as MenuItemMgmtEntry[]).map((item) => {
              const isChecked = !!selected.find((i) => i === item.sys.id);
              return (
                <MenuItemCard
                  key={item.sys.id}
                  item={item}
                  onClick={onClick}
                  isChecked={isChecked}
                  onCheck={onCheck}
                />
              );
            })}
          </>
        );
      case 'category':
        return (
          <>
            {(dataByPage as CategoryMgmtEntry[]).map((item) => {
              const isChecked = !!selected.find((i) => i === item.sys.id);
              return (
                <CategoryCard
                  key={item.sys.id}
                  item={item}
                  onClick={onClick}
                  isChecked={isChecked}
                  onCheck={onCheck}
                />
              );
            })}
          </>
        );

      default:
        return null;
    }
  };

  return (
    <Flex flexDirection={'column'}>
      {renderItems()}
      <Flex height={10} width={10} marginTop={8} ref={footerRef} />
    </Flex>
  );
};
