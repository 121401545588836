import React, { createContext, useReducer, useEffect, useRef, useContext } from 'react';
import { useMutation, useQuery } from 'react-query';
import { firebase } from 'services/firebase';
import {
  fetchingUserFailAction,
  fetchingUserSuccessAction,
  resetUserStateAction,
} from './AuthActions';
import { UserAPI } from 'services/api';
import { LocalStorage } from 'shared/functions';
import { CreateUserOptions, RequestErrorType } from 'services/api/types';
import { AuthActionTypes, AuthState, User } from './types';
import { RequestError } from 'services/api/helpers';

const { auth } = firebase;

const initialState: AuthState = {
  user: undefined,
  isFetching: true,
  error: undefined,
};

const reducer = (state: AuthState = initialState, action: AuthActionTypes) => {
  console.log('Auth: ', action);

  switch (action.type) {
    case 'FETCHING_USER':
      return {
        ...state,
        user: undefined,
        isFetching: true,
        error: undefined,
      };

    case 'FETCHING_USER_SUCCESS':
      return {
        ...state,
        user: action.user,
        isFetching: false,
        error: undefined,
      };

    case 'FETCHING_USER_FAIL':
      return {
        ...state,
        user: undefined,
        isFetching: false,
        error: action.error,
      };

    case 'RESET_STATE':
      return { ...initialState, isFetching: false };

    default:
      return state;
  }
};

const defaultDispatch: React.Dispatch<AuthActionTypes> = () => initialState;

export const AuthStateContext = createContext({
  state: initialState,
  dispatch: defaultDispatch,
});

export const AuthStateProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const unsubscriber = useRef<firebase.Unsubscribe | null>(null);
  const fetchingUser = useRef(false);
  console.log(`🚀 => AuthState`, state);

  const { refetch: refetchUser } = useQuery<User>('user', UserAPI.getUser, {
    enabled: false,
    retry: false,

    onSuccess: (res) => {
      console.log('onSuccess :>> ', res);
      dispatch(fetchingUserSuccessAction(res));
      fetchingUser.current = false;
    },
  });

  const { mutate: mutateCreateUser } = useMutation(
    (createOptions: CreateUserOptions) => UserAPI.createUser(createOptions),
    {
      onSuccess: (res) => {
        LocalStorage.removeItem('createAccount');
        dispatch(fetchingUserSuccessAction(res));
      },
      onError: (error: RequestErrorType) => {
        dispatch(fetchingUserFailAction(error));
      },
    },
  );

  const fetchUser = async (user: firebase.User) => {
    try {
      await refetchUser({ throwOnError: true });
    } catch (error) {
      console.log('fetchUser:error :>> ', error);
      if (error instanceof RequestError) {
        if (error.status && error.status === 404) {
          mutateCreateUser({
            email: user.email || '',
          });
        } else {
          dispatch(fetchingUserFailAction(error));
        }
      }
      fetchingUser.current = false;
    }
  };

  useEffect(() => {
    // Start Auth Listener
    console.log('Starting Auth Listener');
    if (!unsubscriber.current) {
      unsubscriber.current = auth().onAuthStateChanged(
        (user) => {
          if (user) {
            console.log('user :>> ', user);

            if (!fetchingUser.current) {
              fetchUser(user);
            }
          } else {
            dispatch(resetUserStateAction());
          }
        },
        (error) => {
          console.log('AuthStateChanged Error :>> ', error);
          dispatch(resetUserStateAction());
        },
      );
    }
  }, []);

  useEffect(() => {
    return () => {
      // Stop Auth Listener
      console.log('Stopping Auth Listener');
      if (unsubscriber.current) {
        unsubscriber.current();
      }
    };
  }, []);

  return (
    <AuthStateContext.Provider value={{ state, dispatch }}>{children}</AuthStateContext.Provider>
  );
};

export const useAuthStateContext = (): {
  state: AuthState;
  dispatch: React.Dispatch<AuthActionTypes>;
} => {
  return useContext(AuthStateContext);
};

export const AuthStateConsumer = AuthStateContext.Consumer;
