import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { CategoryMgmtEntry } from 'services/api/types';
import { dayjs, defaultLocale } from 'shared/functions';
import { Card } from './Card';
import { StatusLabel } from 'shared/components';

type CategoryCardProps = {
  item: CategoryMgmtEntry;
  onClick: (item: CategoryMgmtEntry) => void;
  isChecked: boolean;
  onCheck: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const CategoryCard: React.FC<CategoryCardProps> = ({
  item,
  onClick,
  onCheck,
  isChecked,
}) => {
  const { fields, sys } = item;
  const { title, items } = fields;
  const { updatedAt } = sys;
  return (
    <Card id={sys.id} onClick={() => onClick(item)} isChecked={isChecked} onCheck={onCheck}>
      <Flex flexDirection="column" flex={1}>
        <Text fontWeight="bold" fontSize="2xl" lineHeight="100%" wordBreak="break-word">
          {title ? title[defaultLocale] : 'Untitled'}
        </Text>
        <Text fontWeight="medium" color="gray.600">{`Total Menu Items: ${
          items ? items[defaultLocale].length : 0
        }`}</Text>
      </Flex>
      <Flex
        flexDirection="column"
        textAlign="right"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <StatusLabel sys={sys} />
        <Flex flexDirection="column" justifyContent="flex-end" marginTop="2">
          <Text fontSize="xs" fontWeight="medium" color="gray.600">
            Updated
          </Text>
          <Text fontSize="xs" color="gray.600">
            {dayjs(updatedAt).format('ll')}
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
};
