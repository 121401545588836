import { useQuery, useQueryClient } from 'react-query';
import { useAccountsStateContext } from 'context/Accounts/AccountsContext';
import { AnalyticsAPI } from 'services/api';
import {
  EntriesResponse,
  GeneralMgmtEntry,
  QueryAnalyticsData,
  QueryAnalyticsResponse,
} from 'services/api/types';
import { defaultLocale } from 'shared/functions';

type FetchAnalyticsReturnType = {
  storeName?: string;
  menuAnalytics?: QueryAnalyticsData[];
  menuItemAnalytics?: QueryAnalyticsData[];
  isLoading: boolean;
};

const analyticsQueryKeys = {
  menu: (name: string, startDate: string, endDate: string) => [
    'analytics',
    'menu',
    name,
    startDate,
    endDate,
  ],
  menuItem: (name: string, startDate: string, endDate: string) => [
    'analytics',
    'menu/item',
    name,
    startDate,
    endDate,
  ],
};

type QueryAnalyticsOptions = {
  startDate: string;
  endDate: string;
};
export const useFetchAnalytics = ({
  startDate,
  endDate,
}: QueryAnalyticsOptions): FetchAnalyticsReturnType => {
  const { state: accountsState } = useAccountsStateContext();
  const { selectedAccount } = accountsState;

  const queryClient = useQueryClient();

  const generalData = queryClient.getQueryData<EntriesResponse>([
    'general',
    `${selectedAccount ? selectedAccount.account_name : ''}`,
  ]);

  const storeName = `${
    generalData ? (generalData[0] as GeneralMgmtEntry).fields.slug[defaultLocale] : ''
  }`;

  const { data: menuAnalytics, isLoading: menuAnalyticsIsLoading } =
    useQuery<QueryAnalyticsResponse>(
      analyticsQueryKeys.menu(storeName, startDate, endDate),
      () =>
        AnalyticsAPI.queryAnalytics({
          startDate,
          endDate,
          type: 'menu',
          name: storeName,
        }),
      {
        enabled: !!(selectedAccount && generalData),
        staleTime: 60 * 60 * 1000,
      },
    );

  const { data: menuItemAnalytics, isLoading: menuItemAnalyticsIsLoading } =
    useQuery<QueryAnalyticsResponse>(
      analyticsQueryKeys.menuItem(storeName, startDate, endDate),
      () =>
        AnalyticsAPI.queryAnalytics({
          startDate,
          endDate,
          type: 'menu/item',
          name: storeName,
        }),
      {
        enabled: !!(selectedAccount && generalData),
        staleTime: 60 * 60 * 1000,
      },
    );

  return {
    storeName,
    menuAnalytics: menuAnalytics ? menuAnalytics.data : [],
    menuItemAnalytics: menuItemAnalytics ? menuItemAnalytics.data : [],
    isLoading: menuAnalyticsIsLoading || menuItemAnalyticsIsLoading,
  };
};
