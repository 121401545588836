import React, { useContext } from 'react';
import { Flex, Heading, Button, Text } from '@chakra-ui/react';
import { useMutation, useQuery } from 'react-query';
import { AccountsStateContext } from 'context/Accounts/AccountsContext';
import { FormContainer } from 'features/Content/components/FormContainer';
import {
  RequestErrorType,
  StripePrice,
  Subscription as SubscriptionType,
} from 'services/api/types';
import { SubscriptionsAPI } from 'services/api';
import { getFriendlyNameFromInterval } from 'shared/functions/Subscriptions';
import { useIsSubscribed } from 'hooks/useIsSubscribed';
import { useHistory } from 'react-router-dom';
import { StarIcon } from '@chakra-ui/icons';

export const Subscription: React.FC = () => {
  const { state: accountsState } = useContext(AccountsStateContext);
  const isSubscribed = useIsSubscribed();
  const history = useHistory();
  const { selectedAccount } = accountsState;
  let accountSubscription: SubscriptionType | null = null;
  if (selectedAccount) {
    const { subscriptions } = selectedAccount;
    if (subscriptions) {
      accountSubscription = subscriptions;
    }
  }

  const { data: prices } = useQuery(`prices`, () => SubscriptionsAPI.getPrices());
  const subscriptionQuery = useQuery(`subscription`, () =>
    SubscriptionsAPI.getSubscription(accountsState.selectedAccount?.id || ''),
  );
  console.log(`🚀 => subscriptionQuery`, subscriptionQuery);

  const { mutate: mutateCreateCheckoutSession, isLoading: isCreatingSession } = useMutation(
    () => SubscriptionsAPI.createBillingPortalSession(),
    {
      onSuccess: (res) => {
        console.log(`🚀 => createBillingPortalSession:res`, res);
        window.location.replace(res.url);
      },
      onError: (error: RequestErrorType) => {
        console.log(`🚀 => error`, error);
      },
    },
  );

  const onClickOpenSession = () => {
    mutateCreateCheckoutSession();
  };

  const onClickUpgrade = () => {
    history.replace({ search: new URLSearchParams({ upgrade: 'true' }).toString() });
  };

  let selectedPrice: StripePrice | null = null;
  if (prices && accountSubscription) {
    const foundPrice = prices.data.find(
      (price) => price.id === accountSubscription?.stripe_price_id,
    );
    if (foundPrice) {
      selectedPrice = foundPrice;
    }
  }

  return (
    <Flex flexDir="column">
      <FormContainer>
        <Heading size="lg" marginBottom="4">
          Subscription
        </Heading>
        {isSubscribed ? (
          <>
            {accountSubscription && selectedPrice && (
              <Flex
                maxWidth="240px"
                flexDirection="column"
                alignItems="center"
                background="gray.50"
                borderRadius="lg"
                padding="8"
                justifyContent="center"
                marginBottom="4"
              >
                <Text fontSize="3xl" fontWeight="black">
                  {getFriendlyNameFromInterval(selectedPrice.recurring.interval)}
                </Text>
                <Text
                  marginTop="2"
                  fontSize="medium"
                  fontWeight="bold"
                  backgroundColor="green.300"
                  padding="2"
                  paddingX="4"
                  borderRadius="md"
                  color="white"
                >
                  {accountSubscription.status?.toUpperCase()}
                </Text>
              </Flex>
            )}
            <Button
              marginTop="4"
              variant="primary"
              onClick={onClickOpenSession}
              disabled={isCreatingSession}
              isLoading={isCreatingSession}
            >
              Manage Billing
            </Button>
          </>
        ) : (
          <Button leftIcon={<StarIcon />} marginTop="2" variant="primary" onClick={onClickUpgrade}>
            Upgrade to Premium
          </Button>
        )}
      </FormContainer>
    </Flex>
  );
};
