import React, { useContext } from 'react';
import { Flex, Heading, Button } from '@chakra-ui/react';
import { FirebaseAuth } from 'services/firebase';
import { useQueryClient } from 'react-query';
import { AuthStateContext } from 'context';
import { AccountsStateContext } from 'context/Accounts/AccountsContext';
import { AccountDetails } from './AccountDetails';
import { Subscription } from './Subscription';

export const Settings: React.FC = () => {
  const { dispatch: authDispatch } = useContext(AuthStateContext);
  const { dispatch: accountsDispatch } = useContext(AccountsStateContext);

  const queryClient = useQueryClient();
  return (
    <Flex flexDir="column" flex={1} padding={{ base: '4', sm: 0 }}>
      <Heading>Settings</Heading>

      <AccountDetails />

      <Subscription />
      <Button
        marginTop="12"
        variant="outline"
        bg="white"
        onClick={async () => {
          await FirebaseAuth.signOut(authDispatch);
          accountsDispatch({ type: 'RESET_STATE' });
          queryClient.clear();
        }}
      >
        Logout
      </Button>
    </Flex>
  );
};
