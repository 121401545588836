import theme from '@chakra-ui/theme';

const colors = {
  ...theme.colors,
  transparent: 'transparent',
  black: '#000',
  white: '#fff',
  gray: {
    50: '#f8f9faff',
    100: '#e9ecefff',
    200: '#dee2e6ff',
    300: '#ced4daff',
    400: '#adb5bdff',
    500: '#6c757dff',
    600: '#495057ff',
    700: '#343a40ff',
    800: '#212529ff',
    900: '#171923ff',
  },
  blue: {
    50: '#a9d6e5ff',
    100: '#89c2d9ff',
    200: '#61a5c2ff',
    300: '#468fafff',
    400: '#2c7da0ff',
    500: '#2a6f97ff',
    600: '#014f86ff',
    700: '#01497cff',
    800: '#013a63ff',
    900: '#012a4aff',
  },
};

export default colors;
