import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Box, Flex, Slide } from '@chakra-ui/react';
import { SideNavigation } from 'containers/SideNavigation/SideNavigation';
import { authenticatedRoutes } from 'router';
import useMediaQuery from 'hooks/useMediaQuery';
import customTheme from 'styles/theme';
import { TopNavigation } from 'containers/TopNavigation/TopNavigation';
import { UpgradeModal } from 'features/Pricing/UpgradeModal';

export const DefaultLayout: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const parsedLocation = queryString.parse(location.search);
  const [showSidebar, setShowSidebar] = useState(true);
  const isDesktop = useMediaQuery(`(min-width: ${customTheme.breakpoints.md})`);

  const showUpgrade = Boolean(parsedLocation.upgrade);

  useEffect(() => {
    // Show/Hide Sidebar based on MediaQuery Screen width
    if (isDesktop) {
      setShowSidebar(true);
    } else {
      setShowSidebar(false);
    }
  }, [isDesktop]);

  const toggleSidebar = useCallback((forceHide: boolean) => {
    if (forceHide) {
      setShowSidebar(false);
      return;
    }

    setShowSidebar((prev) => !prev);
  }, []);

  return (
    <Flex flexDirection="column" flex="1" maxWidth="100%">
      <UpgradeModal
        isOpen={showUpgrade}
        onClose={() => {
          history.replace({ search: new URLSearchParams({}).toString() });
        }}
      />
      <TopNavigation
        isDesktop={isDesktop}
        toggleSidebar={toggleSidebar}
        showSidebar={showSidebar}
      />
      <Flex
        marginTop={{ base: '80px', md: 0 }}
        flex="1"
        flexDirection="row"
        backgroundColor="gray.100"
        overflow="hidden"
      >
        {isDesktop ? (
          <SideNavigation isDesktop={isDesktop} toggleSidebar={toggleSidebar} />
        ) : (
          <Slide
            in={showSidebar}
            direction="left"
            style={{ top: '80px', zIndex: customTheme.zIndices.overlay, maxWidth: 'max-content' }}
          >
            <SideNavigation isDesktop={isDesktop} toggleSidebar={toggleSidebar} />
          </Slide>
        )}
        {showSidebar && !isDesktop && (
          <Box
            position="fixed"
            width="100%"
            height="100%"
            background="rgba(0,0,0,.1)"
            onClick={() => toggleSidebar(true)}
            zIndex="docked"
          />
        )}
        <Flex
          flex="1"
          paddingY="8"
          paddingX={{ sm: '4', md: '8' }}
          justifyContent={{ sm: 'center', md: 'flex-start' }}
          alignItems="flex-start"
          overflowY="auto"
          maxWidth="7xl"
        >
          <Switch>
            {authenticatedRoutes.map(({ path, name, component, exact }) => (
              <Route path={path} key={name} component={component} exact={exact} />
            ))}
            <Redirect to="/dashboard" />
          </Switch>
        </Flex>
      </Flex>
    </Flex>
  );
};
