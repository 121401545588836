import { get, patch, post } from './helpers';
import { AccountResponse, CreateAccountOptions, UpdateAccountOptions } from './types';

export const createAccount = async (data: CreateAccountOptions): Promise<AccountResponse> => {
  const response = await post<AccountResponse>(`/accounts`, data);
  return response;
};

export const getAccount = async (accountId: string): Promise<AccountResponse> => {
  const response = await get<AccountResponse>(`/accounts/${accountId}`);
  return response;
};

export const updateAccounts = async (
  accountId: string,
  data: UpdateAccountOptions,
): Promise<AccountResponse> => {
  const response = await patch<AccountResponse>(`/accounts/${accountId}`, data);
  return response;
};
