import React from 'react';
import { Box, Flex, Heading, Text, chakra, Link } from '@chakra-ui/react';
import { useHistory } from 'react-router';
import customTheme from 'styles/theme';
import { ReactComponent as CircleCheckOutline } from 'assets/circle_check_outline.svg';
import { ReactComponent as CircleCheckFilled } from 'assets/circle_check.svg';
import { useAccountsStateContext } from 'context/Accounts/AccountsContext';
import { useFetchAllData } from './useFetchAllData';
import { LoadingIndicator } from 'shared/components';
import { EntriesResponse, GeneralMgmtEntry } from 'services/api/types';
import { defaultLocale } from 'shared/functions';
import { Analytics } from './Analytics';

const StyledCircleCheckOutline = chakra(CircleCheckOutline);
const StyledCircleCheckFilled = chakra(CircleCheckFilled);

const getPlatformSteps = (
  accountId: string,
  data: {
    generalData?: EntriesResponse;
    menuData?: EntriesResponse;
    categoryData?: EntriesResponse;
    menuItemData?: EntriesResponse;
  },
): {
  title: string;
  description: string;
  url: string;
  isFilled: boolean;
}[] => {
  const { generalData, menuData, categoryData, menuItemData } = data;

  let hasLinkedGeneral = false;
  if (generalData) {
    hasLinkedGeneral = generalData.some((entry) => {
      const generalEntry = entry as GeneralMgmtEntry;
      if (generalEntry.fields.menus && generalEntry.fields.menus[defaultLocale].length > 0) {
        return true;
      }
      return false;
    });
  }

  return [
    {
      title: 'Create your first Store',
      description: `Get started by adding some of your store details. The name is most important!`,
      url: `/store/content/${accountId}/general`,
      isFilled: !!(generalData && generalData.length > 0),
    },
    {
      title: 'Create your first Menu Item',
      description: `Add a new menu item, add as much information to the item as you like. You can always go back and make changes.`,
      url: `/store/content/${accountId}/menuItem`,
      isFilled: !!(menuItemData && menuItemData.length > 0),
    },
    {
      title: 'Create your first Menu Category',
      description: `Add a new menu category. Be sure to link your previously created Menu Item if you haven't already.`,
      url: `/store/content/${accountId}/category`,
      isFilled: !!(categoryData && categoryData.length > 0),
    },
    {
      title: 'Create your first Menu',
      description: `Add one of your menu's title and description. Be sure to link your previously created Category to your Menu if you haven't already.`,
      url: `/store/content/${accountId}/menu`,
      isFilled: !!(menuData && menuData.length > 0),
    },
    {
      title: 'Link your Menu',
      description: `Link your newly created Menu with your Store Info. Be sure the rest of your content is linked and published!`,
      url: `/store/content/${accountId}/general`,
      isFilled: hasLinkedGeneral,
    },
  ];
};

export const Dashboard: React.FC = () => {
  const { state: accountsState } = useAccountsStateContext();
  const { selectedAccount } = accountsState;
  const history = useHistory();

  const { isLoading, ...allData } = useFetchAllData();
  console.log('useFetchAllData: allData', allData);
  console.log('useFetchAllData: isLoading', isLoading);
  const platformSteps = getPlatformSteps(selectedAccount?.id || '', allData);
  const allStepsFilled = platformSteps.every((step) => step.isFilled);

  const onClickStep = (index: number) => {
    const stepSelected = platformSteps[index];
    console.log(`🚀 => onClickStep => stepSelected`, stepSelected);

    if (stepSelected.url) {
      history.push(stepSelected.url);
    }
  };

  let storeSlug = '';
  if (allData.generalData) {
    const generalEntry = allData.generalData[0] as GeneralMgmtEntry;
    if (generalEntry) {
      storeSlug = generalEntry.fields.slug?.[defaultLocale];
    }
  }

  return (
    <Flex
      flex="1"
      flexDir="column"
      padding={{ base: '4', sm: 0 }}
      paddingBottom="8"
      maxWidth={'100%'}
    >
      {isLoading ? (
        <Flex justifyContent="center" alignItems="center" flex="1">
          <LoadingIndicator size="1.5" />
        </Flex>
      ) : allStepsFilled ? (
        <Analytics />
      ) : (
        <>
          <Heading marginTop="6" size="md">
            Getting Started:
          </Heading>
          <Flex marginTop="4" flexDirection="column" alignSelf="center">
            {platformSteps.map((item, index) => {
              return (
                <GetStartedSteps
                  key={index}
                  index={index}
                  title={item.title}
                  description={item.description}
                  onClick={onClickStep}
                  isFilled={item.isFilled}
                />
              );
            })}
          </Flex>
          {storeSlug && (
            <Link
              href={`https://visual.menu/${storeSlug}`}
              target="_blank"
              rel="noopener noreferrer"
              paddingY="6"
              paddingX="8"
              borderRadius="10px"
              backgroundColor={'gray.50'}
              border="1px solid transparent"
              _hover={{
                border: `1px solid ${customTheme.colors.gray[200]}`,
                cursor: 'pointer',
                boxShadow: 'lg',
              }}
            >
              <Flex flexDirection="column" flex="1">
                <Heading fontSize="xl">View your content live!</Heading>
                <Text>
                  Visit Visual Menu to see your content live. Keep in mind, changes may take up to 5
                  minutes to appear after publishing!
                </Text>
              </Flex>
            </Link>
          )}
        </>
      )}
    </Flex>
  );
};

type GetStartedStepsProps = {
  index: number;
  title: string;
  description: string;
  onClick: (index: number) => void;
  isFilled: boolean;
};

const GetStartedSteps: React.FC<GetStartedStepsProps> = ({
  index,
  title,
  description,
  onClick,
  isFilled,
}) => {
  return (
    <Flex
      paddingY="6"
      paddingX="8"
      borderRadius="10px"
      alignItems="center"
      backgroundColor={'gray.50'}
      onClick={() => onClick(index)}
      marginBottom="6"
      border="1px solid transparent"
      _hover={{
        border: `1px solid ${customTheme.colors.gray[200]}`,
        cursor: 'pointer',
        boxShadow: 'lg',
      }}
    >
      <Box marginRight="4">
        {isFilled ? (
          <StyledCircleCheckFilled
            height="36px"
            width="36px"
            sx={{
              ['path']: {
                fill: customTheme.colors.blue[500],
              },
            }}
          />
        ) : (
          <StyledCircleCheckOutline
            height="36px"
            width="36px"
            sx={{
              ['path']: {
                fill: customTheme.colors.gray[400],
              },
            }}
          />
        )}
      </Box>
      <Flex flexDirection="column" flex="1">
        <Heading fontSize="xl">{title}</Heading>
        <Text>{description}</Text>
      </Flex>
    </Flex>
  );
};
