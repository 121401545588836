import React, { useContext, useReducer } from 'react';
import { Flex, Heading, Button, Avatar, useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from 'react-query';
import { AuthStateContext } from 'context';
import { AccountsStateContext } from 'context/Accounts/AccountsContext';
import { FormContainer } from 'features/Content/components/FormContainer';
import { FormInput } from 'shared/components';
import { User } from 'context/Auth/types';
import {
  Account,
  RequestErrorType,
  UpdateAccountOptions,
  UpdateUserOptions,
} from 'services/api/types';
import { AccountsAPI, UserAPI } from 'services/api';
import { Validations } from 'shared/functions';
import { setSelectedAccountAction } from 'context/Accounts/AccountActions';

type FormState = {
  user: User;
  account?: Account;
  errors: FormValidationError;
};

type FormValidationError = {
  firstName?: string;
  lastName?: string;
  account_name?: string;
  general?: string;
};

type FORM_ACTIONS = { type: 'update'; payload: Partial<FormState> };

const initialState: FormState = {
  user: {
    id: '',
  },
  errors: {},
};

const reducer = (state: FormState, action: FORM_ACTIONS) => {
  switch (action.type) {
    case 'update': {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};

export const AccountDetails: React.FC = () => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const { state: authState } = useContext(AuthStateContext);
  const { state: accountsState, dispatch: accountsDispatch } = useContext(AccountsStateContext);
  const [editState, dispatch] = useReducer(reducer, {
    ...initialState,
    ...(authState.user && { user: authState.user }),
    ...(accountsState.selectedAccount && { account: accountsState.selectedAccount }),
  });

  const { mutate: mutateUpdateUser, isLoading: isUpdatingUser } = useMutation(
    (options: UpdateUserOptions) => UserAPI.updateUser(options),
    {
      onSuccess: (res) => {
        console.log(`🚀 => res`, res);
        queryClient.refetchQueries('user');
        toast({
          title: 'Profile updated!',
          status: 'success',
          duration: 1000,
        });
      },
      onError: (error: RequestErrorType) => {
        console.log(`🚀 => error`, error);
        toast({
          title: 'Error:',
          description: error.message,
          status: 'error',
          duration: 3000,
        });
      },
    },
  );

  const { mutate: mutateUpdateAccount, isLoading: isUpdatingAccount } = useMutation(
    (options: UpdateAccountOptions) =>
      AccountsAPI.updateAccounts(accountsState.selectedAccount?.id || '', options),
    {
      onSuccess: (res) => {
        console.log(`🚀 => res`, res);
        queryClient.refetchQueries('accounts');
        accountsDispatch(setSelectedAccountAction(res));
        toast({
          title: 'Account updated!',
          status: 'success',
          duration: 1000,
        });
      },
      onError: (error: RequestErrorType) => {
        console.log(`🚀 => error`, error);
        toast({
          title: 'Error:',
          description: error.message,
          status: 'error',
          duration: 3000,
        });
      },
    },
  );

  const {
    user: { firstName, lastName, email },
    account,
    errors,
  } = editState;

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: 'user' | 'account',
  ) => {
    const { name, value } = e.target;

    dispatch({
      type: `update`,
      payload: {
        ...(type === 'user' && {
          user: { ...editState.user, [name]: value },
        }),
        ...(type === 'account' &&
          editState.account && {
            account: { ...editState.account, [name]: value },
          }),

        errors: {
          ...editState.errors,
          [name]: undefined,
        },
      },
    });
  };

  const onClickSaveProfile = () => {
    const required = { firstName, lastName };
    const newErrors = Validations.validateForm(required);
    if (Object.keys(newErrors).length > 0) {
      dispatch({
        type: 'update',
        payload: {
          errors: newErrors,
        },
      });

      return;
    }

    mutateUpdateUser({
      first_name: firstName,
      last_name: lastName,
    });
  };

  const onClickSaveAccount = () => {
    if (account) {
      const required = { account_name: account.account_name };
      const newErrors = Validations.validateForm(required);
      if (Object.keys(newErrors).length > 0) {
        dispatch({
          type: 'update',
          payload: {
            errors: newErrors,
          },
        });

        return;
      }

      mutateUpdateAccount({
        account_name: account.account_name,
      });
    }
  };

  const profileSaveDisabled =
    authState.user?.firstName === firstName && authState.user?.lastName === lastName;
  const accountSaveDisabled = accountsState.selectedAccount?.account_name === account?.account_name;

  return (
    <Flex flexDir="column">
      <FormContainer>
        <Heading size="lg" marginBottom="4">
          Profile
        </Heading>

        <Flex flexDirection={{ base: 'column', sm: 'row' }} flex={1} justifyContent="space-between">
          <Avatar
            size="lg"
            backgroundColor="cyan.500"
            fontWeight="bold"
            marginRight="12"
            color="gray.800"
            marginBottom={{ base: '4', sm: 0 }}
            name={`${firstName} ${lastName}`}
            getInitials={() => `${firstName ? firstName[0] : ''}${lastName ? lastName[0] : ''}`}
          />
          <Flex flexDirection="column" flex={1}>
            <FormInput
              label="First Name"
              name="firstName"
              id="firstName"
              value={firstName || ''}
              onChange={(e) => onChange(e, 'user')}
              error={errors.firstName}
              placeholder="Enter first name"
              required={false}
            />
            <FormInput
              label="Last Name"
              name="lastName"
              id="lastName"
              value={lastName || ''}
              onChange={(e) => onChange(e, 'user')}
              error={errors.lastName}
              placeholder="Enter last name"
              required={false}
            />
            <FormInput
              label="Email address"
              name="email"
              id="email"
              value={email || ''}
              onChange={(e) => onChange(e, 'user')}
              placeholder="Enter email"
              disabled={true}
              required={false}
            />

            <Button
              marginTop="2"
              alignSelf="flex-end"
              variant="primary"
              onClick={onClickSaveProfile}
              disabled={profileSaveDisabled || isUpdatingUser}
              isLoading={isUpdatingUser}
            >
              Save
            </Button>
          </Flex>
        </Flex>
      </FormContainer>

      <FormContainer>
        <Heading size="lg" marginBottom="4">
          Account
        </Heading>
        <FormInput
          label="Account Name"
          name="account_name"
          id="account_name"
          error={errors.account_name}
          value={account ? account.account_name : ''}
          onChange={(e) => onChange(e, 'account')}
          placeholder="Enter account name"
          disabled={false}
          required={false}
        />
        <Button
          marginTop="2"
          alignSelf="flex-end"
          variant="primary"
          onClick={onClickSaveAccount}
          disabled={accountSaveDisabled || isUpdatingAccount}
          isLoading={isUpdatingAccount}
        >
          Save
        </Button>
      </FormContainer>
    </Flex>
  );
};
