import React from 'react';
import {
  Flex,
  Text,
  Heading,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerContent,
  DrawerBody,
  useBreakpointValue,
  SlideDirection,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import { LoadingIndicator, Logo } from 'shared/components';
import { usePrices } from './usePrices';
import { useCreateCheckout } from './useCreateCheckout';
import { useUpgradeActions } from './useUpgradeActions';

type UpgradeModalProps = {
  isOpen: boolean;
  onClose: () => void;
};
export const UpgradeModal = ({ isOpen, onClose }: UpgradeModalProps) => {
  const { isFetching: fetchingPrices, monthlyPrice, annualPrice } = usePrices();
  const { isLoading: isCreatingSession } = useCreateCheckout();
  const { onClickPlan } = useUpgradeActions();
  const drawerSize = useBreakpointValue({ base: 'sm', md: 'lg' });
  const drawerPlacement: SlideDirection | undefined = useBreakpointValue({
    base: 'bottom',
    md: 'right',
  });

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement={drawerPlacement} size={drawerSize}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton marginRight={'6'} marginTop={'4'} />
        <DrawerBody
          flexDirection="column"
          flex="1"
          justifyContent="flex-start"
          padding={['6', '8']}
          overflowY="auto"
          backgroundColor="white"
          margin={[0, '4']}
        >
          <Flex marginBottom="8">
            <Logo />
          </Flex>
          <Heading fontSize="3xl" fontWeight="black" mb="4">
            Premium Subscription
          </Heading>

          <Heading fontSize="xl" fontWeight="black">
            Features:
          </Heading>

          <UnorderedList mb="4" mt="2">
            <ListItem>Unlimited changes and publishing.</ListItem>
            <ListItem>View and share your menu live.</ListItem>
            <ListItem>Analytics: See which items are your most and least popular.</ListItem>
            <ListItem>Cancel anytime.</ListItem>
          </UnorderedList>

          {fetchingPrices ? (
            <LoadingIndicator />
          ) : (
            <Flex flexDirection={['column', 'row']} justifyContent="space-around" width="100%">
              {monthlyPrice && (
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  background="gray.50"
                  borderRadius="lg"
                  padding="8"
                  justifyContent="center"
                  marginBottom="4"
                >
                  <Text fontSize="lg" fontWeight="black">
                    Monthly
                  </Text>
                  <Text fontSize="4xl" fontWeight="black">
                    {`$${monthlyPrice.unit_amount / 100}`}
                  </Text>
                  <Text fontSize="md" fontStyle="italic" fontWeight="bold">
                    per month
                  </Text>
                  <Text fontSize="sm" fontStyle="italic">
                    Billed monthly
                  </Text>
                  <Button
                    onClick={() => onClickPlan('month')}
                    marginTop="4"
                    alignSelf="center"
                    variant="primary"
                    loading={isCreatingSession}
                  >
                    SELECT
                  </Button>
                </Flex>
              )}
              {annualPrice && (
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  background="gray.50"
                  borderRadius="lg"
                  padding="8"
                  justifyContent="center"
                  marginBottom="4"
                  position="relative"
                >
                  <Flex
                    position="absolute"
                    background="green.500"
                    top="-10px"
                    right="-10px"
                    borderRadius="50%"
                    height="60px"
                    width="60px"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Text textAlign="center" fontSize="xs" color="white" fontWeight="bold">
                      SAVE
                      <br />
                      $45
                    </Text>
                  </Flex>
                  <Text fontSize="lg" fontWeight="black">
                    Annual
                  </Text>
                  <Text fontSize="4xl" fontWeight="black">
                    {`$${Math.round(annualPrice.unit_amount / 12) / 100}`}
                  </Text>
                  <Text fontSize="md" fontStyle="italic" fontWeight="bold">
                    per month
                  </Text>
                  <Text fontSize="sm" fontStyle="italic">
                    {`$${annualPrice.unit_amount / 100} Billed annually`}
                  </Text>
                  <Button
                    onClick={() => onClickPlan('year')}
                    marginTop="4"
                    alignSelf="center"
                    variant="primary"
                    loading={isCreatingSession}
                  >
                    SELECT
                  </Button>
                </Flex>
              )}
            </Flex>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
