import React from 'react';
import { Text } from '@chakra-ui/react';
import { EntityStatusUtils } from 'shared/functions';
import { EntityMetaSysProps } from 'contentful-management/dist/typings/common-types';
import { EntryNotFoundSys } from 'services/api/types';

type Props = {
  sys: EntityMetaSysProps | EntryNotFoundSys;
};

export const StatusLabel: React.FC<Props> = ({ sys }) => {
  const status = EntityStatusUtils.getStatusFromEntitySys(sys);

  return (
    <Text
      fontWeight="semibold"
      textAlign="right"
      paddingY=".5"
      paddingX="2"
      borderRadius="4"
      bg={EntityStatusUtils.getColorsFromStatus(status).bg}
      color={EntityStatusUtils.getColorsFromStatus(status).color}
    >
      {status}
    </Text>
  );
};
