import React, { useRef } from 'react';
import {
  AlertDialog as ChakraAlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
} from '@chakra-ui/react';

type Props = {
  isOpen: boolean;
  title?: string;
  description?: string;
  onClose: () => void;
  onAction: () => void;
  actionTitle: string;
  actionLoading: boolean;
};

export const AlertDialog: React.FC<Props> = ({
  isOpen,
  title,
  description,
  onClose,
  onAction,
  actionTitle,
  actionLoading,
}) => {
  const cancelRef = useRef(null);
  return (
    <ChakraAlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent alignSelf="center" margin={{ base: 4, sm: 0 }}>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>{description}</AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} variant="outline" onClick={() => onClose()}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onAction} ml={3} isLoading={actionLoading}>
              {actionTitle}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </ChakraAlertDialog>
  );
};
