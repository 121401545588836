import React, { useCallback, useContext, useState } from 'react';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  DrawerCloseButton,
  Button,
  Text,
  Flex,
  SimpleGrid,
  Image,
  useBreakpointValue,
  SlideDirection,
} from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import { DeleteIcon } from '@chakra-ui/icons';
import { FormInput } from 'shared/components';
import { AccountsStateContext } from 'context/Accounts/AccountsContext';
import { useMutation, useQueryClient } from 'react-query';
import { CreateAssetOptions, CreateUploadOptions, RequestErrorType } from 'services/api/types';
import { AssetsAPI, UploadsAPI } from 'services/api';
import { defaultLocale } from 'shared/functions';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

interface FileWithPreview extends File {
  preview: string;
  title: string;
  description: string;
}

const supportedTypes = 'image/jpeg, image/png';

export const UploadAssetModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const queryClient = useQueryClient();
  const { state: accountsState } = useContext(AccountsStateContext);
  const { selectedAccount } = accountsState;
  const [files, setFiles] = useState<FileWithPreview[]>();
  const [error, setError] = useState<string | undefined>();
  const drawerSize = useBreakpointValue({ base: 'sm', md: 'lg' });
  const drawerPlacement: SlideDirection | undefined = useBreakpointValue({
    base: 'bottom',
    md: 'right',
  });

  const { mutateAsync: mutateUpload, isLoading: isUploading } = useMutation(
    (options: CreateUploadOptions) => UploadsAPI.uploadAsset(options),
    {
      onSuccess: (res) => {
        console.log(`🚀 => uploadAsset:res`, res);
      },
      onError: (error: RequestErrorType) => {
        console.log(`🚀 => error`, error);
      },
    },
  );

  const { mutateAsync: mutateProcess, isLoading: isProcessing } = useMutation(
    (assetId: string) => AssetsAPI.processAsset(assetId),
    {
      onSuccess: (res) => {
        console.log(`🚀 => processAsset:res`, res);
      },
      onError: (error: RequestErrorType) => {
        console.log(`🚀 => error`, error);
      },
    },
  );

  const { mutateAsync: mutateCreate, isLoading: isCreating } = useMutation(
    (options: CreateAssetOptions) => AssetsAPI.createAsset(options),
    {
      onSuccess: (res) => {
        console.log(`🚀 => createAsset:res`, res);
      },
      onError: (error: RequestErrorType) => {
        console.log(`🚀 => error`, error);
      },
    },
  );

  const { mutateAsync: mutatePublish, isLoading: isPublishing } = useMutation(
    (assetId: string) => AssetsAPI.publishAsset(assetId),
    {
      onSuccess: (data) => {
        console.log(`🚀 => mutatePublish:onSuccess:`, data);
      },
    },
  );

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    console.log(`🚀 => acceptedFiles`, acceptedFiles);
    console.log(`🚀 => fileRejections`, fileRejections);
    // Do something with the files
    if (acceptedFiles.length > 0) {
      setFiles(
        (acceptedFiles as FileWithPreview[]).map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            title: file.name,
            description: '',
          }),
        ),
      );
      setError(undefined);
    }
    if (fileRejections.length > 0) {
      setError('Please select only one file and the correct type.');
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept: supportedTypes,
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
  });

  const onClickRemoveFile = (file: FileWithPreview) => {
    setFiles((prev) => {
      if (prev) {
        const prevCopy = prev.slice();
        const foundFileIndex = prevCopy.findIndex((f) => f.name === file.name);

        if (foundFileIndex > -1) {
          prevCopy.splice(foundFileIndex, 1);
        }
        if (prevCopy.length === 0) {
          return undefined;
        }
        return prevCopy;
      }
      return prev;
    });
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    let indexOfFile = -1;
    let type: 'title' | 'description' = 'title';

    if (name.includes('title')) {
      indexOfFile = Number(name.replace('title-', ''));
      type = 'title';
    }
    if (name.includes('description')) {
      indexOfFile = Number(name.replace('description-', ''));
      type = 'description';
    }

    if (indexOfFile > -1 && type) {
      setFiles((prev) => {
        if (prev) {
          const prevCopy = prev.slice();
          const item = prevCopy[indexOfFile];
          item[type] = value;

          return prevCopy;
        }
        return prev;
      });
    }
  };

  const onClickUpload = async () => {
    if (files && files.length > 0) {
      const uploadPromises = files.map((file) => {
        return mutateUpload({ file: file });
      });
      const uploadResults = await Promise.all(uploadPromises);
      console.log(`🚀 => onClickUpload => uploadResults`, uploadResults);

      const createPromises = uploadResults.map((uploadResult, index) => {
        const fileProcessed = files[index];
        if (fileProcessed) {
          return mutateCreate({
            fields: {
              title: {
                [defaultLocale]: `${selectedAccount?.id}_${fileProcessed.title}`,
              },
              /** Description for this asset */
              description: {
                [defaultLocale]: fileProcessed.description,
              },
              /** File object for this asset */
              file: {
                [defaultLocale]: {
                  fileName: fileProcessed.name,
                  contentType: fileProcessed.type,
                  uploadFrom: {
                    sys: {
                      type: 'Link',
                      linkType: 'Upload',
                      id: uploadResult.sys.id,
                    },
                  },
                },
              },
            },
          });
        }
      });

      const createResults = await Promise.all(createPromises);
      console.log(`🚀 => onClickUpload => createResults`, createResults);

      const processPromises = createResults.map((createResult) => {
        if (createResult) {
          return mutateProcess(createResult.sys.id);
        }
      });

      const processResults = await Promise.all(processPromises);
      console.log(`🚀 => onClickUpload => processResults`, processResults);

      const publishPromises = processResults.map((processResult) => {
        if (processResult) {
          return mutatePublish(processResult.sys.id);
        }
      });
      const publishResults = await Promise.all(publishPromises);
      console.log(`🚀 => onClickUpload => publishResults`, publishResults);
    }
    queryClient.refetchQueries(`media-${selectedAccount ? selectedAccount.account_name : ''}`);
    onClose();
  };

  return (
    <Drawer
      isFullHeight={true}
      placement={drawerPlacement}
      isOpen={isOpen}
      onClose={onClose}
      size={drawerSize}
    >
      <DrawerOverlay />
      <DrawerContent zIndex="overlay">
        <DrawerHeader
          fontSize="2xl"
          fontWeight="bold"
          borderBottom="1px solid"
          borderColor="gray.300"
        >
          Upload Media
        </DrawerHeader>
        <DrawerCloseButton />
        <DrawerBody overflowY="auto" paddingTop="8" paddingBottom="8">
          {files ? (
            <SimpleGrid>
              {files.map((file, index) => {
                return (
                  <Flex key={file.name} flexDirection={{ base: 'column', sm: 'row' }}>
                    <Flex flexDirection="column" marginBottom="8">
                      <Image
                        src={file.preview}
                        borderRadius="md"
                        width={{ base: '100%', sm: '240px' }}
                        objectFit="cover"
                      />
                      <Flex marginTop="4">
                        <Button
                          variant="outline"
                          aria-label="Remove"
                          leftIcon={<DeleteIcon />}
                          onClick={() => onClickRemoveFile(file)}
                        >
                          Remove
                        </Button>
                      </Flex>
                    </Flex>
                    <Flex flexDirection="column" marginLeft={{ base: 0, sm: '8' }} flex="1">
                      <FormInput
                        label="Title"
                        name={`title-${index}`}
                        value={file.title}
                        onChange={onChange}
                        placeholder="File Title"
                        required={false}
                      />
                      <FormInput
                        label="Description"
                        name={`description-${index}`}
                        value={file.description}
                        onChange={onChange}
                        placeholder="File Description"
                        required={false}
                      />
                    </Flex>
                  </Flex>
                );
              })}
            </SimpleGrid>
          ) : (
            <Flex
              border="1px solid gray"
              borderRadius="md"
              borderStyle="dashed"
              height="300px"
              width="100%"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              backgroundColor={isDragActive ? 'gray.100' : 'gray.50'}
              {...getRootProps()}
            >
              <input hidden {...getInputProps()} />
              {isDragActive ? (
                <Text textAlign="center">Drop file here</Text>
              ) : (
                <>
                  <Text textAlign="center">Drag and drop a file to upload...</Text>
                  <Button alignSelf="center" variant="outline" onClick={open} marginTop="4">
                    Open File
                  </Button>
                  <Text fontSize="small" textAlign="center" marginTop="4">
                    {`Supported types: ${supportedTypes}`}
                  </Text>
                  {error && (
                    <Text textAlign="center" color="red.500" marginTop="8">
                      {error}
                    </Text>
                  )}
                </>
              )}
            </Flex>
          )}
        </DrawerBody>

        <DrawerFooter borderTop="1px solid" borderColor="gray.300">
          <Button variant="ghost" marginRight="4" onClick={onClose}>
            Close
          </Button>
          <Button
            isLoading={isUploading || isProcessing || isCreating || isPublishing}
            onClick={onClickUpload}
            variant="add"
            isDisabled={!files || files.length === 0}
          >
            Upload
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
