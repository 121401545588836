import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { AlertBanner, StatusLabel } from 'shared/components';
import { dayjs } from 'shared/functions';
import { ActionRow } from 'features/Store/components/ActionRow';
import {
  CategoryMgmtEntry,
  GeneralMgmtEntry,
  MenuItemMgmtEntry,
  MenuMgmtEntry,
} from 'services/api/types';

type InfoBoxProps = {
  item: MenuItemMgmtEntry | CategoryMgmtEntry | MenuMgmtEntry | GeneralMgmtEntry;
  onActionSuccess: () => void;
  hasChanges?: boolean;
};

export const InfoBox: React.FC<InfoBoxProps> = ({ item, onActionSuccess, hasChanges }) => {
  const { sys } = item;
  const { updatedAt } = sys;
  return (
    <Flex
      paddingX={{ base: '4', sm: '6' }}
      paddingY={{ base: '4', sm: '6' }}
      borderRadius="6"
      flexDirection="column"
      marginTop="8"
      marginRight={{ base: 0, md: '8' }}
      backgroundColor="white"
      minWidth="280px"
      width={{ base: '100%', sm: '340px' }}
      alignSelf={{ base: 'flex-end', sm: 'flex-start' }}
    >
      <Flex
        borderBottom="1px solid"
        borderColor="gray.300"
        paddingBottom="2"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="2"
      >
        <Text>Status</Text>
        <StatusLabel sys={sys} />
      </Flex>
      {hasChanges && (
        <AlertBanner variant="subtle" status="warning" title="Unsaved changes detected." />
      )}
      <ActionRow
        isStatic={false}
        data={[item]}
        selected={[sys.id]}
        onActionSuccess={onActionSuccess}
      />
      <Flex flexDirection="column" marginTop="2">
        <Text fontSize="medium" fontWeight="medium" color="gray.600">
          Last Updated
        </Text>
        <Text fontSize="medium" color="gray.600">
          {dayjs(updatedAt).fromNow()}
        </Text>
      </Flex>
    </Flex>
  );
};
