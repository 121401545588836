import theme from '@chakra-ui/theme';
import colors from '../colors';

const button = {
  ...theme.components.Button,
  // 1. We can update the base styles
  baseStyle: {
    ...theme.components.Button.baseStyle,
    minHeight: theme.sizes[12],
    fontWeight: 'bold', // Normally, it is "semibold"
    alignSelf: 'flex-start',
  },
  // 3. We can add a new visual variant
  variants: {
    ...theme.components.Button.variants,
    primary: {
      bg: colors.blue[500],
      color: colors.gray[50],
      minWidth: '32',
      _hover: {
        bg: colors.blue[400],
        _disabled: {
          bg: colors.gray[100],
        },
      },
    },
    add: {
      minWidth: '32',
      bg: colors.green[500],
      color: colors.gray[50],
      _hover: {
        bg: colors.green[400],
        _disabled: {
          bg: colors.gray[100],
        },
      },
    },
    delete: {
      minWidth: '32',
      bg: colors.red[500],
      color: colors.gray[50],
      _hover: {
        bg: colors.red[400],
        _disabled: {
          bg: colors.gray[100],
        },
      },
    },
    secondary: {
      minWidth: '32',
      bg: colors.gray[50],
      color: colors.gray[800],
      border: `1px solid ${colors.gray[300]}`,
      _hover: {
        bg: colors.gray[200],
        _disabled: {
          bg: colors.gray[100],
        },
      },
    },
  },
  defaultProps: {
    ...theme.components.Button.defaultProps,
    variant: 'primary',
  },
};

export default button;
