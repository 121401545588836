import React from 'react';
import { chakra, FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

type Props = {
  label: string;
  name: string;
  value: string;
  onChange: (e: { target: { name: string; value: string } }) => void;
  placeholder?: string;
  error?: string;
  disabled?: boolean;
  required?: boolean;
};

export const DefaultFormInput: React.FC<Props> = ({
  label,
  name,
  value,
  onChange,
  placeholder,
  error,
  disabled = false,
  ...rest
}) => {
  return (
    <FormControl
      display="flex"
      isInvalid={!!error}
      flexDirection="column"
      marginBottom="3"
      {...rest}
    >
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <ReactQuill
        theme="snow"
        value={value}
        id={name}
        onChange={(content, delta, user, editor) => {
          onChange({ target: { name, value: editor.getHTML() } });
        }}
        readOnly={disabled}
        placeholder={placeholder}
        preserveWhitespace
      />
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};
export const FormEditorInput = chakra(DefaultFormInput);
