import { createBreakpoints } from '@chakra-ui/theme-tools';

const breakpoints = createBreakpoints({
  sm: '600px',
  md: '960px',
  lg: '1200px',
  xl: '1440px',
  '2xl': '1440px',
});

export default breakpoints;
