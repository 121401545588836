import { useAccountsStateContext } from 'context/Accounts/AccountsContext';
import { useCreateCheckout } from './useCreateCheckout';
import { usePrices } from './usePrices';

export const useUpgradeActions = () => {
  const { state: accountsState } = useAccountsStateContext();
  const { prices: pricesData } = usePrices();
  const { mutate: mutateCreateCheckoutSession } = useCreateCheckout();

  const onClickPlan = (selected: 'month' | 'year') => {
    console.log(`🚀 => onClickPlan => selected`, selected);

    if (pricesData) {
      if (accountsState.selectedAccount?.subscriptions) {
        const { stripe_customer_id: stripeCustomerId } =
          accountsState.selectedAccount?.subscriptions;
        let priceId = pricesData.data[0].id;
        const foundPrice = pricesData.data.find((price) => price.recurring.interval === selected);
        if (foundPrice) {
          priceId = foundPrice.id;
        }

        mutateCreateCheckoutSession({
          stripe_customer_id: stripeCustomerId,
          price_id: priceId,
        });
      }
    }
  };

  return {
    onClickPlan,
  };
};
