import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AuthStateContext } from 'context';
import { AccountsStateContext } from 'context/Accounts/AccountsContext';
import { PageLoading } from 'features';

interface Props {
  path: string;
  component: React.ElementType;
  name: string;
}

export const AuthedRoute: React.FC<Props> = ({ component: Component, ...rest }) => {
  const { state: authState } = useContext(AuthStateContext);
  const { state: accountState } = useContext(AccountsStateContext);
  const { selectedAccount, isFetching: accountsFetching } = accountState;
  const { isFetching: userFetching, user } = authState;

  // Fetching User or Accounts
  if (userFetching || accountsFetching) {
    return <PageLoading />;
  }

  if (!user || !selectedAccount) {
    return <Redirect to="/login" />;
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};
