import React, { MouseEventHandler } from 'react';
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react';

type ModalProps = {
  isOpen: boolean;
  onClose: MouseEventHandler<HTMLButtonElement> | undefined;
  title: string;
};

export const Modal: React.FC<ModalProps> = ({ isOpen, onClose, title, children }) => {
  return (
    <ChakraModal isOpen={isOpen} onClose={onClose as () => void} isCentered scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent maxWidth={['95%', '500px']}>
        <ModalHeader fontWeight={'bold'} borderBottom={`1px solid gray`} borderColor="gray.100">
          {title}
        </ModalHeader>
        <ModalCloseButton size={'lg'} />
        <ModalBody paddingY="4" maxHeight={'500px'}>
          {children}
        </ModalBody>
        <ModalFooter borderTop={`1px solid gray`} borderColor="gray.100">
          <Button variant="outline" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
  );
};
