import React, { useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import { Flex, Heading, Link, Text, Button, Center } from '@chakra-ui/react';
import { AuthBackground, FormInput, Logo, LoadingIndicator } from 'shared/components';
import { Validations } from 'shared/functions';
import { FirebaseAuth, firebase } from 'services/firebase';

type FormState = {
  email: string;
  loading: boolean;
  showSuccess: boolean;
  errors: FormValidationError;
};

type FormValidationError = {
  email?: string;
  general?: string;
};

type FORM_ACTIONS = { type: 'update'; payload: Partial<FormState> };

const initialState: FormState = {
  email: '',
  loading: false,
  showSuccess: false,
  errors: {},
};

const reducer = (state: FormState, action: FORM_ACTIONS) => {
  switch (action.type) {
    case 'update': {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};

export const ForgotPassword: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    dispatch({
      type: 'update',
      payload: {
        [name]: value,
        errors: {
          [name]: undefined,
        },
      },
    });
  };

  const onClickReset = async () => {
    console.log('state :>> ', state);

    dispatch({
      type: 'update',
      payload: {
        loading: true,
      },
    });

    const required = { email };
    const newErrors = Validations.validateForm(required);
    if (Object.keys(newErrors).length > 0) {
      dispatch({
        type: 'update',
        payload: {
          loading: false,
          errors: newErrors,
        },
      });

      return;
    }

    try {
      await FirebaseAuth.sendPasswordResetEmail(email);
      dispatch({
        type: 'update',
        payload: {
          loading: false,
          showSuccess: true,
        },
      });
    } catch (error) {
      const authError = error as firebase.auth.Error;
      dispatch({
        type: 'update',
        payload: {
          loading: false,
          errors: {
            general: authError.message,
          },
        },
      });
    }
  };

  const onClickGoBack = () => {
    history.goBack();
  };

  const { email, loading, errors, showSuccess } = state;

  return (
    <AuthBackground>
      <Flex
        maxWidth="500px"
        flexDirection="column"
        flex="1"
        justifyContent="flex-start"
        borderRadius="10px"
        padding={['6', '8']}
        overflowY="auto"
        backgroundColor="white"
        margin={[0, '4']}
        position="relative"
      >
        {loading && (
          <Center
            position="absolute"
            height="100%"
            width="100%"
            top="0"
            left="0"
            backgroundColor="blackAlpha.400"
            zIndex="overlay"
            borderRadius="10px"
          >
            <LoadingIndicator />
          </Center>
        )}

        <Flex marginBottom="8">
          <Logo />
        </Flex>
        <Heading fontSize="3xl" fontWeight="black" mb="4">
          PASSWORD RESET
        </Heading>
        {showSuccess ? (
          <>
            <Text marginTop="4" fontWeight="semibold" fontSize="xl">
              Please check your email for a link to reset your password.
            </Text>
            <Button onClick={onClickGoBack} variant="primary" marginTop="8" width="100%">
              Back to login
            </Button>
          </>
        ) : (
          <>
            <FormInput
              label="Email Address"
              name="email"
              value={email}
              onChange={onChange}
              error={errors.email}
              placeholder="Enter your email address"
              type="email"
            />

            {errors.general && (
              <Text textAlign="center" color="red.500" fontWeight="semibold">
                {errors.general}
              </Text>
            )}

            <Button
              onClick={onClickReset}
              isDisabled={loading}
              marginTop="8"
              width="100%"
              variant="primary"
            >
              Reset Password
            </Button>

            <Link
              alignSelf="center"
              marginTop="4"
              color="gray.400"
              fontSize="sm"
              onClick={onClickGoBack}
            >
              Go Back
            </Link>
          </>
        )}
      </Flex>
    </AuthBackground>
  );
};
