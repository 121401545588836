import { Subscription } from 'services/api/types';

export const isValidSubscription = (subscription?: Subscription): boolean => {
  if (!subscription) {
    return false;
  }

  const { status } = subscription;
  if (!status || status === 'canceled' || status === 'unpaid' || status === 'incomplete_expired') {
    return false;
  }

  return true;
};

export const getFriendlyNameFromInterval = (interval: 'year' | 'month'): string => {
  return interval === 'year' ? 'Annual' : 'Monthly';
};
