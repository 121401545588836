import React from 'react';
import { Button, ButtonGroup, chakra, Flex, Portal, Text, useToast } from '@chakra-ui/react';
import { AssetProps } from 'contentful-management/dist/typings/export-types';
import { useMutation } from 'react-query';
import { AssetsAPI } from 'services/api';
import { ItemActions } from 'shared/functions';
import { RequestErrorType } from 'services/api/types';

type Props = {
  data?: AssetProps[];
  onCheckItem: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selected: string[];
  onActionSuccess: () => void;
};

// INFO: Handling one item selected at a time for now
// Actions are dependent on Entry Status - some statuses clash with one another

export const AssetsActionRow: React.FC<Props> = ({
  data,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onCheckItem,
  selected,
  onActionSuccess,
}) => {
  const toast = useToast({ duration: 2000, position: 'bottom' });
  const { mutate: mutateDelete, isLoading: deleteLoading } = useMutation(
    (assetId: string) => AssetsAPI.deleteAsset(assetId),
    {
      onSuccess: (data) => {
        console.log(`🚀 => onSuccess:`, data);
        onActionSuccess();
        toast({
          title: 'Deleted successfully',
          status: 'success',
        });
      },
      onError: (error: RequestErrorType) => {
        toast({
          title: error.message,
          status: 'error',
        });
      },
    },
  );
  const { mutate: mutatePublish, isLoading: publishLoading } = useMutation(
    (assetId: string) => AssetsAPI.publishAsset(assetId),
    {
      onSuccess: (data) => {
        console.log(`🚀 => onSuccess:`, data);
        onActionSuccess();
        toast({
          title: 'Published successfully',
          status: 'success',
        });
      },
      onError: (error: RequestErrorType) => {
        toast({
          title: error.message,
          status: 'error',
        });
      },
    },
  );
  const { mutate: mutateArchive, isLoading: archiveLoading } = useMutation(
    (assetId: string) => AssetsAPI.archiveAsset(assetId),
    {
      onSuccess: (data) => {
        console.log(`🚀 => onSuccess:`, data);
        onActionSuccess();
        toast({
          title: 'Archived successfully',
          status: 'success',
        });
      },
      onError: (error: RequestErrorType) => {
        toast({
          title: error.message,
          status: 'error',
        });
      },
    },
  );
  const { mutate: mutateUnarchive, isLoading: unarchiveLoading } = useMutation(
    (assetId: string) => AssetsAPI.unarchiveAsset(assetId),
    {
      onSuccess: (data) => {
        console.log(`🚀 => onSuccess:`, data);
        onActionSuccess();
        toast({
          title: 'Unarchived successfully',
          status: 'success',
        });
      },
      onError: (error: RequestErrorType) => {
        toast({
          title: error.message,
          status: 'error',
        });
      },
    },
  );
  const { mutate: mutateUnpublish, isLoading: unpublishLoading } = useMutation(
    (assetId: string) => AssetsAPI.unpublishAsset(assetId),
    {
      onSuccess: (data) => {
        console.log(`🚀 => onSuccess:`, data);
        onActionSuccess();
        toast({
          title: 'Unpublished successfully',
          status: 'success',
        });
      },
      onError: (error: RequestErrorType) => {
        toast({
          title: error.message,
          status: 'error',
        });
      },
    },
  );

  const onClickDelete = () => {
    mutateDelete(selected[0]);
  };
  const onClickPublish = () => {
    mutatePublish(selected[0]);
  };
  const onClickUnpublish = () => {
    mutateUnpublish(selected[0]);
  };
  const onClickArchive = () => {
    mutateArchive(selected[0]);
  };
  const onClickUnarchive = () => {
    mutateUnarchive(selected[0]);
  };

  const showOptions = ItemActions.getOptionsFromSelected(selected, data || []);

  return (
    <Portal>
      <Flex position="absolute" bottom="0" left="4" right="4" flex="1" justifyContent="center">
        <Flex
          justifyContent="center"
          alignItems="center"
          backgroundColor="gray.50"
          boxShadow="lg"
          padding="4"
          zIndex="overlay"
          borderRadius="lg"
          flexDirection={{ base: 'column', sm: 'row' }}
          marginY="4"
          minWidth="240px"
        >
          <Text
            fontWeight="bold"
            marginRight={{ base: '0', sm: '6' }}
            marginBottom={{ base: '2', sm: '0' }}
          >
            {`${selected.length}  ${selected.length === 1 ? 'item' : 'items'} selected:`}
          </Text>
          {selected.length > 0 && (
            <ButtonGroup isAttached>
              {showOptions.includes('delete') && (
                <ActionButton variant="delete" onClick={onClickDelete} isLoading={deleteLoading}>
                  Delete
                </ActionButton>
              )}
              {showOptions.includes('unpublish') && (
                <ActionButton
                  variant="secondary"
                  onClick={onClickUnpublish}
                  isLoading={unpublishLoading}
                >
                  Unpublish
                </ActionButton>
              )}
              {showOptions.includes('archive') && (
                <ActionButton
                  variant="secondary"
                  onClick={onClickArchive}
                  isLoading={archiveLoading}
                >
                  Archive
                </ActionButton>
              )}
              {showOptions.includes('unarchive') && (
                <ActionButton
                  variant="secondary"
                  onClick={onClickUnarchive}
                  isLoading={unarchiveLoading}
                >
                  Unarchive
                </ActionButton>
              )}
              {showOptions.includes('publish') && (
                <ActionButton variant="add" onClick={onClickPublish} isLoading={publishLoading}>
                  Publish
                </ActionButton>
              )}
            </ButtonGroup>
          )}
        </Flex>
      </Flex>
    </Portal>
  );
};

const ActionButton = chakra(Button, {
  baseStyle: {
    minHeight: 'auto',
    minWidth: 'auto',
  },
});
