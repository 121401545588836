import { extendTheme, Theme, theme, ThemeConfig } from '@chakra-ui/react';
import breakpoints from './breakpoints';
import colors from './colors';
import space from './space';
import styles from './styles';
import typography from './typography';
import Button from './components/button';

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const overrides: Theme = {
  ...theme,
  ...typography,
  styles,
  space,
  breakpoints,
  components: {
    ...theme.components,
    Button,
  },
  colors,
  config,
};

const customTheme = extendTheme(overrides);
export default customTheme;
